import * as types from "./constantes";
import {
  getUniteEsane,
  selectionUniteWithOperationLiee,
  searchUnite,
  getUnite
} from "redux/api";

let numLastRequete = 0;

export const searchUnites = (filtres, page, size, sort, vueEntreprise) => (
  dispatch,
  getState
) => {
  if (filtres != null) {
    let numRequete = numLastRequete;
    numLastRequete++;
    dispatch({ type: types.UNITES_LOADING });
    return searchUnite(
      filtres,
      page,
      size,
      sort,
      vueEntreprise,
      numRequete
    ).then(response => {
      dispatch({ type: types.UNITES_FOUND, payload: response, numRequete });
    });
  }
  return null;
};
export const exportUnites = (filtres, sort, entreprise) => (
  dispatch,
  getState
) => {
  if (filtres != null) {
    dispatch({ type: types.UNITES_EXPORTING });
    return searchUnite(filtres, 1, 1000, sort, entreprise).then(response => {
      dispatch({ type: types.UNITES_EXPORTED, payload: response });
    });
  }
  return null;
};

export const resetEsaneData = () => (dispatch, getState) => {
  dispatch({
    type: types.ESANE_DATA_RESET
  });
};

export const loadEsaneData = unite => (dispatch, getState) => {
  dispatch({
    type: types.ESANE_DATA_LOADING,
    unite: {
      siren: unite.siren,
      rs: unite.raisonSociale,
      ape: unite.ape,
      entreprise: unite.entrepriseProfilee
        ? unite.entrepriseProfilee.siren
        : unite.siren
    }
  });
  getUniteEsane(unite.siren, unite.anneeCampagne).then(response => {
    dispatch({
      type: types.ESANE_DATA_FOUND,
      unite: {
        siren: unite.siren,
        ca: response.ca,
        ape: response.ape || unite.ape,
        effectif: response.eff3112
      }
    });
  });
  getUniteEsane(unite.siren, unite.anneeCampagne - 1).then(response => {
    dispatch({
      type: types.ESANE_DATA_FOUND,
      unite: {
        siren: unite.siren,
        zca: response.ca,
        zape: response.ape || unite.ape,
        zeffectif: response.eff3112
      }
    });
  });
};

export const loadUnite = idUnite => (dispatch, getState) => {
  dispatch({ type: types.UNITE_LOADING });
  getUnite(idUnite).then(reponse =>
    dispatch({ type: types.UNITE_FOUND, unite: reponse })
  );
};

export const getHistorique = siren => (dispatch, getState) => {
  const vueEntreprise = getState().storeVueEntreprise.vueEntreprise;
  dispatch({ type: types.HISTORIQUE_LOADING });
  const selection = getState().storeSelection.selected;
  if (selection && selection.id === siren) {
    dispatch({
      type: types.HISTORIQUE_FOUND,
      operations: getState().storeSelection.selected.operation,
      vueEntreprise: vueEntreprise
    });
  }

  return selectionUniteWithOperationLiee(siren)
    .then(response => {
      dispatch({
        type: types.HISTORIQUE_FOUND,
        operations: response,
        vueEntreprise: vueEntreprise
      });
    })
    .catch(error => {
      dispatch({ type: types.HISTORIQUE_FOUND, operations: [] });
      console.error(error);
    });
};
