import { connect } from "react-redux";
import OperationField from "./OperationField";
import { loadSourceUser } from "redux/actions";

const mapStateToProps = state => {
  return {
    source: state.storeUser.source
  };
};

export default connect(
  mapStateToProps,
  {
    loadSourceUser
  }
)(OperationField);
