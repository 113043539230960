import { combineReducers } from "redux";

import storeAnnees from "./annees.js";
import storeTypesOp from "./typesOperation.js";
import storeUser from "./user.js";
import storeOperations from "./operations.js";
import storeSelection from "./selection.js";
import storeAriane from "./ariane.js";
import storeFiltre from "./filtre.js";
import storeSources from "./sources.js";
import storeEnveloppes from "./enveloppes.js";
import storeUnites from "./unites.js";
import storeSirens from "./sirens.js";
import storeCommentaires from "./commentaires.js";
import storeVueEntreprise from "./vueEntreprise.js";
import storeRejets from "./rejets.js";
import storeFiches from "./fiches.js";

const rootReducer = combineReducers({
  storeAnnees,
  storeTypesOp,
  storeUser,
  storeOperations,
  storeSelection,
  storeAriane,
  storeFiltre,
  storeSources,
  storeEnveloppes,
  storeUnites,
  storeSirens,
  storeCommentaires,
  storeVueEntreprise,
  storeRejets,
  storeFiches
});

export default rootReducer
