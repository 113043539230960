import React , { useState, useEffect } from "react";
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  Edit,
  Delete,
  CompareArrows,
  Done,
  Redo,
  RestoreFromTrash,
  VisibilityOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material";

import { ResumeOperation } from "components/Operation/ResumeOperation";
import { etatRejetToLibelle } from "utils/";
import { enqueueSnackbar } from 'notistack';
import "./actionOperation.scss";

const ActionOperation = (props) => {
  const {
    rejet,
    loadUpdatableFields,
    operation,
    isUpdatable,
    save,
    submitModification,
    deleteOp,
    expertise,
    modification,
    valider,
    annuler,
    modifier,
  } = props;

  const [dialogSupprime, setDialogSupprime] = useState(false);
  const [dialogInverse, setDialogInverse] = useState(false);
  const [iconeExpertise, setIconeExpertise] = useState(
    rejet ? rejet.etatRejet === 3 : null
  );

  // On charge les champs modifiables
  useEffect(() => {
    operation.idOperation && loadUpdatableFields(operation.idOperation);
  }, [operation.idOperation, loadUpdatableFields]);

  const handleInverse = () => {
    if (isUpdatable("idUniteBeneficiaire") && isUpdatable("idUniteCedante")) {
      const op = { ...operation };
      op.uniteCedante = operation.uniteBeneficiaire;
      op.uniteBeneficiaire = operation.uniteCedante;
      save(op).then(
        (response) => {
          const resultat = op.response;
          if (resultat.listChampsMajRefuses) {
            enqueueSnackbar(
              "Votre demande d'inversion est refusée car vous êtes de priorité inférieure",
              { variant: "error" }
            );

            submitModification("uniteCedante", op.uniteBeneficiaire);
            submitModification("uniteBeneficiaire", op.uniteCedante);
          } else {
              enqueueSnackbar("Votre demande d'inversion a été prise en compte", {
              variant: "success",
              });
          }
        })
        .catch((error) => {
          enqueueSnackbar(
            `Votre demande d'inversion a échoué : ${
              error.message ? error.message : "operation inexistante"
            }`,
            {
              variant: "error",
            }
          );
        });
      setDialogInverse(false);
    }
  };

  const handleClickActive = () => {
    if (isUpdatable("estActive")) {
      const op = { ...operation };
      op.active = true;
      save(op).then(
        (response) => {
          enqueueSnackbar("Votre demande de réactivation a été envoyée", {
            variant: "success",
          });
          submitModification("active", true);
        })
        .catch((error) => {
          enqueueSnackbar(
            `Votre demande de réactivation a échoué : ${
              error.message ? error.message : "operation inexistante"
            }`,
            {
              variant: "error",
            }
          );
        });
    }
  };
  const handleClickInverse = () => {
    if (isUpdatable("idUniteBeneficiaire") && isUpdatable("idUniteCedante")) {
      setDialogInverse(true);
    }
  };
  const handleCloseInverse = () => {
    setDialogInverse(false);
  };
  const handleClickSupprime = () => {
    if (isUpdatable("estActive")) {
      setDialogSupprime(true);
    }
  };
  const handleCloseSupprime = () => {
    setDialogSupprime(false);
  };

  const handleSupprime = () => {
    const op = { ...operation };
    op.estActive = false;
    deleteOp(op.idOperation).then(
      () => {
        enqueueSnackbar("Votre demande de suppression a été prise en compte", {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(
          `Votre demande de suppression a échoué : ${error.message}`,
          {
            variant: "error",
          }
        );
      });
    setDialogSupprime(false);
  };

  const handleClickExpertise = () => {
    expertise(rejet.idRejet).then(
      () => {
        const newEtatRejet = (rejet.etatRejet === 3) ? 1 : 3;
        setIconeExpertise(newEtatRejet);
        enqueueSnackbar(
          `Rejet enregistré ${etatRejetToLibelle(newEtatRejet)}`,
          {
            variant: "success",
          }
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          `Erreur lors de l'enregistrement de l'expertise : ${error.message}`,
          {
            variant: "error",
          }
        );
      });
  };

  const handleHoverExpertise = (expertiseParam) => {
    setIconeExpertise(expertiseParam);
  };
  
  if (modification) {
    return (
      <ul className="actionOperation">
        <li>
          <IconButton
            aria-label="Valider"
            style={{ color: "green" }}
            onClick={valider}
          >
            <Done fontSize="large" />
          </IconButton>
          <span>Valider</span>
        </li>
        <li>
          <IconButton
            aria-label="Annuler"
            style={{ color: "red" }}
            onClick={annuler}
          >
            <Redo fontSize="large" />
          </IconButton>
          <span>Annuler</span>
        </li>
      </ul>
    );
  }
  return (
    <ul className="actionOperation">
      <li>
        <IconButton
          aria-label="Editer valeurs"
          color="primary"
          onClick={modifier}
          disabled={rejet && rejet.etatRejet !== 1}
        >
          <Edit fontSize="large" />
        </IconButton>
        <span>Editer valeurs</span>
      </li>
      {!rejet && (
        <li>
          {operation.active ? (
            <>
              <IconButton
                aria-label="Supprimer"
                color="primary"
                onClick={handleClickSupprime}
              >
                <Delete fontSize="large" />
              </IconButton>
              <span>Supprimer</span>
              <Dialog
                open={dialogSupprime}
                onClose={handleCloseSupprime}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Etes-vous sûr de vouloir supprimer cette opération ?
                </DialogTitle>
                <DialogContent
                  className="operation"
                  id="alert-dialog-description"
                >
                  <ResumeOperation operation={operation} />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleCloseSupprime}
                    color="primary"
                    autoFocus
                  >
                    Non
                  </Button>
                  <Button onClick={handleSupprime} color="primary">
                    Oui
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : (
            <>
              <IconButton
                aria-label="Re-Activer"
                color="primary"
                onClick={handleClickActive}
              >
                <RestoreFromTrash fontSize="large" />
              </IconButton>
              <span>Ré-Activer</span>
            </>
          )}
        </li>
      )}
      {!rejet && (
        <li>
          <IconButton
            aria-label="Intervertir couple"
            color="primary"
            onClick={handleClickInverse}
            disabled={rejet && rejet.etatRejet === 2}
          >
            <CompareArrows fontSize="large" />
          </IconButton>
          <span>Intervertir couple</span>
          <Dialog
            open={dialogInverse}
            onClose={handleCloseInverse}
            aria-labelledby="alert-dialog-title-inverse"
            aria-describedby="alert-dialog-description-inverse"
          >
            <DialogTitle id="alert-dialog-title-inverse">
              Etes-vous sûr de vouloir intervertir ce couple ?
            </DialogTitle>
            <DialogContent
              className="operation"
              id="alert-dialog-description-inverse"
            >
              <ResumeOperation operation={operation} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseInverse} color="primary" autoFocus>
                Non
              </Button>
              <Button onClick={handleInverse} color="primary">
                Oui
              </Button>
            </DialogActions>
          </Dialog>
        </li>
      )}
      {rejet && (
        <li>
          <IconButton
            aria-label="Expertise"
            color="primary"
            onClick={handleClickExpertise}
            disabled={rejet.etatRejet === 2}
            onMouseEnter={() => handleHoverExpertise(rejet.etatRejet !== 3)}
            onMouseLeave={() => handleHoverExpertise(rejet.etatRejet === 3)}
          >
            {iconeExpertise ? (
              <VisibilityOutlined fontSize="large" />
            ) : (
              <VisibilityOffOutlined fontSize="large" />
            )}
          </IconButton>
          {rejet.etatRejet === 3 && (
            <span>
              {iconeExpertise
                ? etatRejetToLibelle(rejet.etatRejet)
                : "Mettre en attente"}
            </span>
          )}
          {rejet.etatRejet !== 3 && (
            <span>
              {iconeExpertise
                ? "Marquer Rejeté"
                : etatRejetToLibelle(rejet.etatRejet)}
            </span>
          )}
        </li>
      )}
    </ul>
  );
};

export default ActionOperation;
