import React, { useState, useRef } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
} from "@mui/material";
import { Add, Reply } from "@mui/icons-material";
import { enqueueSnackbar } from 'notistack';
import Commentaire from "../Commentaire";
import CreationCommentaire from "../CreationCommentaire";

import "./commentaireListe.scss";

const libelles = {
  ajout: "Ajouter un commentaire",
  complement: "Compléter ce commentaire",
};

const CommentairesListe = (props) => {
  const { attache, commentaires, addCommentaire, filtres } =
    props;

  const [activeStep, setActiveStep] = useState(0);
  const [toComplete, setToComplete] = useState(null);
  const [ajouter, setAjouter] = useState(false);

  const setComplete = (idCommentaire) => {
    setToComplete(idCommentaire);
  };
  const setActive = (index) => {
    setActiveStep(index);
  };
  const setAjouterAction = (ajouterValeur) => {
    setAjouter(ajouterValeur);
  };

  const saveAjouter = (commentaire) => {
    let attacheId = {};
    let newAttache = structuredClone(attache);
    if (newAttache.operation) {
      attacheId = { idOperation: newAttache.operation.idOperation };
      newAttache.operation.nbCommentaire = newAttache.operation.nbCommentaire + 1;
    } else if (newAttache.enveloppe) {
      attacheId = { idEnveloppe: newAttache.enveloppe.idEnveloppe };
      newAttache.enveloppe.nbCommentaire = newAttache.enveloppe.nbCommentaire + 1;
    } else if (newAttache.enveloppeEntreprise) {
      attacheId = { idEnveloppe: newAttache.enveloppeEntreprise.idEnveloppe };
      newAttache.enveloppeEntreprise.nbCommentaire =
      newAttache.enveloppeEntreprise.nbCommentaire + 1;
    } else if (newAttache.uniteCedante) {
      attacheId = { idUnite: newAttache.uniteCedante.idUnite };
      newAttache.uniteCedante.nbCommentaire =
      newAttache.uniteCedante.nbCommentaire + 1;
    } else if (newAttache.uniteBeneficiaire) {
      attacheId = { idUnite: newAttache.uniteBeneficiaire.idUnite };
      newAttache.uniteBeneficiaire.nbCommentaire =
      newAttache.uniteBeneficiaire.nbCommentaire + 1;
    } else if (newAttache.unite) {
      attacheId = { idUnite: newAttache.unite.idUnite };
      newAttache.unite.nbCommentaire = newAttache.unite.nbCommentaire + 1;
    } else if (newAttache.entreprise) {
      attacheId = { idUnite: newAttache.entreprise.idUnite };
      newAttache.entreprise.nbCommentaire = newAttache.entreprise.nbCommentaire + 1;
    }
    commentaire = { ...commentaire, ...attacheId };
    saveComplete(commentaire, attacheId, newAttache);
  };

  const saveComplete = (commentaire, attacheId, attacheParam) => {
    commentaire = { ...commentaire, ...attacheId };
    if (!commentaire.contenu) {
      enqueueSnackbar("Veuillez saisir un commentaire", {
        variant: "error",
      });
      return false;
    }
    addCommentaire(commentaire, attacheParam).then(
      () => {
        enqueueSnackbar("Votre commentaire a été ajouté", {
          variant: "success",
        });
        setComplete(null);
        setAjouterAction(false);
      },
      (error) => {
        enqueueSnackbar(`L'enregistrement a échoué : ${error.message}`, {
          variant: "error",
        });
      }
    );
    return true;
  };

  const getAnneeAttache = (attacheParam) => {
    if (attacheParam.operation) {
      return attacheParam.operation.anneeCampagne;
    }
    if (attacheParam.enveloppe) {
      return attacheParam.enveloppe.anneeCampagne;
    }
    if (attacheParam.enveloppeEntreprise) {
      return attacheParam.enveloppeEntreprise.anneeCampagne;
    }
    if (attacheParam.uniteBeneficiaire) {
      return attacheParam.uniteBeneficiaire.anneeCampagne;
    }
    if (attacheParam.uniteCedante) {
      return attacheParam.uniteCedante.anneeCampagne;
    }
    if (attacheParam.unite) {
      return attacheParam.unite.anneeCampagne;
    }
    if (attacheParam.entreprise) {
      return attacheParam.entreprise.anneeCampagne;
    }
    return null;
  };

  const getFiltreAttache = (attacheParam) => {
    if (attacheParam.operation) {
      return (commentaire) =>
        commentaire.operation &&
        commentaire.operation.idOperation === attacheParam.operation.idOperation;
    }
    if (attacheParam.enveloppe) {
      return (commentaire) =>
        commentaire.enveloppe &&
        commentaire.enveloppe.idEnveloppe === attacheParam.enveloppe.idEnveloppe;
    }
    if (attacheParam.enveloppeEntreprise) {
      return (commentaire) =>
        commentaire.enveloppe &&
        commentaire.enveloppe.idEnveloppe ===
          attacheParam.enveloppeEntreprise.idEnveloppe;
    }
    if (attacheParam.uniteBeneficiaire) {
      return (commentaire) =>
        commentaire.unite &&
        commentaire.unite.siren === attacheParam.uniteBeneficiaire.siren;
    }
    if (attacheParam.uniteCedante) {
      return (commentaire) =>
        commentaire.unite &&
        commentaire.unite.siren === attacheParam.uniteCedante.siren;
    }
    if (attacheParam.unite) {
      return (commentaire) =>
        commentaire.unite && commentaire.unite.siren === attacheParam.unite.siren;
    }
    if (attacheParam.entreprise) {
      return (commentaire) =>
        commentaire.unite &&
        commentaire.unite.siren === attacheParam.entreprise.siren;
    }
    return (commentaire) => false;
  };

  const filtre = (commentairesParam, attacheParam) => {
    const filtreAttache = getFiltreAttache(attacheParam);
    return commentairesParam.filter(filtreAttache);
  };

  const mapAnnee = (commentaires, attache) => {
    const anneesCalc = {};
    commentaires.forEach((c) => {
      let annee = null;
      if (c.enveloppe) {
        annee = c.enveloppe.anneeCampagne;
      } else if (c.operation) {
        annee = c.operation.anneeCampagne;
      } else if (c.unite) {
        annee = c.unite.anneeCampagne;
      }
      if (annee) {
        anneesCalc[annee] = anneesCalc[annee] ? [...anneesCalc[annee], c] : [c];
      }
    });
    const anneeAttache = getAnneeAttache(attache);
    if (!anneesCalc[anneeAttache]) {
      anneesCalc[anneeAttache] = [];
    }
    return anneesCalc;
  };

const typeCommentaireInvalide = (commentaire, filtresComplet) => {
  return (
    commentaire.typeCommentaire &&
    filtresComplet.type.length > 0 &&
    !filtresComplet.type.includes(commentaire.typeCommentaire.toString())
  )
}

const sourceCommentaireInvalide = (commentaire, filtresComplet) => {
  return (
    commentaire.source &&
    filtresComplet.source.length > 0 &&
    !filtresComplet.source.includes(commentaire.source.libelle))
}

const origineCommentaireInvalide = (commentaire, filtresComplet) => {
  return (
    commentaire.origineInfo &&
    filtresComplet.origine.length > 0 &&
    !filtresComplet.origine.includes(commentaire.origineInfo.toString())
  )
}

  const accepteFiltre = (commentaire) => {
    const filtresComplet = {
      type: [],
      source: [],
      origine: [],
      ...filtres,
    };
    const estInvalide = origineCommentaireInvalide(commentaire, filtresComplet) 
       || sourceCommentaireInvalide(commentaire, filtresComplet)
       || typeCommentaireInvalide(commentaire, filtresComplet);
    return !estInvalide;
  };

  const getCommentaireContent = (commentaire, index) => {
    if (accepteFiltre(commentaire)) {
      const content = [<Commentaire key={index} commentaire={commentaire} />];
      if (commentaire.complements) {
        content.push(
          commentaire.complements.commentaire
            .sort((c1, c2) => c2.idCommentaire - c1.idCommentaire)
            .map((c, i) => <Commentaire key={i} complement commentaire={c} />)
        );
      }
      let complete = (
        <Button
          key="completer"
          className="completer"
          disabled={!commentaire.idCommentaire}
          variant="contained"
          color="primary"
          onClick={() => setComplete(commentaire.idCommentaire)}
        >
          <Reply />
          {libelles.complement}
        </Button>
      );
      if (toComplete === commentaire.idCommentaire) {
        complete = (
          <Paper
            className="creationCommentaire"
            key="completer"
            style={{ marginLeft: "5%" }}
          >
            <CreationCommentaire
              onSubmit={(c) =>
                saveComplete(c, {
                  commentaireParent: commentaire.idCommentaire,
                })
              }
              cancel={() => setComplete(null)}
            />
          </Paper>
        );
      }
      content.push(complete);

      return content;
    }
    return null;
  };
  const getAjoutCommentaire = () => {
    if (ajouter) {
      return (
        <Paper className="creationCommentaire" key="completer">
          <CreationCommentaire
            onSubmit={(c) => saveAjouter(c)}
            cancel={() => setAjouterAction(false)}
          />
        </Paper>
      );
    }
    return (
      <Button
        key="ajouter"
        className="ajouter"
        variant="contained"
        color="primary"
        onClick={() => setAjouterAction(true)}
      >
        <Add />
        {libelles.ajout}
      </Button>
    );
  };

  const myRef = useRef(null);

  let height = "100%";
  if (myRef.current) {
    const top = myRef.current.getBoundingClientRect().top + 50;
    height = `calc(100vh - ${top}px)`;
  }

  const commentairesFiltre = filtre(commentaires, attache);
  const annees = mapAnnee(commentairesFiltre, attache);
  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      className="commentaireListe"
      ref={myRef}
      style={{height}}
    >
      {Object.keys(annees).map((annee, index) => {
        const nb = annees[annee].length;
        return (
          <Step key={annee}>
            <StepLabel
              className="titre"
              onClick={() => setActive(index)}
              completed={false}
              icon=" "
            >
              <h2>{`${annee} (${nb} commentaire${nb > 1 ? "s" : ""})`}</h2>
            </StepLabel>
            <StepContent className="content">
              {getAjoutCommentaire()}
              {annees[annee]
                .sort((c1, c2) => c2.idCommentaire - c1.idCommentaire)
                .map((c, index2) => getCommentaireContent(c, index2))}
            </StepContent>
          </Step>
        );
      })}
      <Step></Step>
    </Stepper>
  );
};

export default CommentairesListe;
