import React from "react";

import Operation from "components/Operation";
import ListeScroll from "../ListeScroll";

const ListeOperationsRecherche = (props) => {
  
  const { operations, action } = props;

  return (
    <ListeScroll
      getContent={() =>
        operations?.map((operation, index) => (
          <Operation
            key={operation.idOperation}
            titre={index === 0}
            operation={operation}
            action={action}
          />
        ))
      }
    />
  );
};

export default ListeOperationsRecherche;
