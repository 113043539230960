import { getConfig } from "utils";

const request = async options => {
  const headers = new Headers({
    "Content-Type": "application/json",
    Accept: "application/json"
  });

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch(
    (await getConfig()).URL_SERVICE_ESANE + options.url,
    options
  ).then(response => {
    return response.text().then(
      function(text) {
        const texte = text ? JSON.parse(text) : {};
        if (!response.ok) {
          return {};
        }
        return texte;
      },
      error => {}
    );
  });
};

export function getUniteEsane(siren, annee) {
  return request({
    url: `/extraire-infos-generales/${siren}?annee=${annee}`,
    method: "GET"
  });
}
