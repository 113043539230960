import { TableCell, TableRow } from "@mui/material";

import InfoEnveloppe from "./InfoEnveloppe";
import ModificationOperations from "components/ModificationOperations";
import {
  Ape,
  Montant,
  Evolution,
  getArianeMillesime,
  getArianeEnveloppe,
} from "components/utils/";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";


const Enveloppe = (props) => {
  const [allOperations, setAllOperations] = useState(props.allOperations);
  const {annee, idEnv } = useParams();


  const {setFiltres, setAriane, getEnveloppe, filtreOperations, enveloppe, page, size, sort } = props;
  // Une seule fois : on efface les filtres
  useEffect(() => {
    setFiltres(null);
  }, [setFiltres] );

  
  // Mise à jour du fils d'Ariane
  useEffect(() => {
    // mise à jour du fils d'Ariane
    setAriane([
      getArianeMillesime(annee, false),
      {
        libelle: getArianeEnveloppe(annee, idEnv, false).libelle,
      },
    ]);
  }, [enveloppe, annee, idEnv, setAriane]);
  
  // On appelle le WS de chargement de l'enveloppe quand "idEnv" change
  useEffect(() => {
      // On appelle le WS 
      getEnveloppe(idEnv);
  }, [idEnv, getEnveloppe]);

  // Executé surtout quand "props.enveloppe" change (quand on a chargé l'enveloppe)
  useEffect(() => {
    if (enveloppe) {
        // Les opérations sont celle de l'enveloppe
        const operations = (enveloppe.operations.operation).filter((o) => o.active);
        // Tri et filtre des opérations + dispatch
        filtreOperations(operations, page, size, sort);
        // On change dans le state allOperations utilisé dans l'onglet détail
        setAllOperations(operations);
      }
  }, [enveloppe, filtreOperations, page, size, sort]);

  // Pied de page dans l'onglet contour des unités de "ModificationOperations"
  const getFooter = (datas, divider) => {
    const sum = { zca: 0, ca: 0, zeffectif: 0, effectif: 0 };
    datas.forEach((data) => {
      Object.keys(sum).forEach((k) => {
        if (data[k]) {
          sum[k] += data[k];
        }
      });
    });
    const caAgreg = sum.zca * props.enveloppe.coeffAgregation;
    const evol =
      sum.ca && sum.zca ? ((sum.ca - sum.zca) / sum.zca) * 100 : undefined;
    const evolAgreg =
      sum.ca && sum.zca ? ((sum.ca - caAgreg) / caAgreg) * 100 : undefined;
    return (
      <TableRow key="footerEnveloppe">
        <TableCell colSpan={2}>Total au niveau de l'enveloppe</TableCell>
        {divider}
        <TableCell />
        <TableCell>{sum.zeffectif}</TableCell>
        <TableCell>
          <Montant value={sum.zca} />
        </TableCell>
        {divider}
        <TableCell>
          <Ape value={enveloppe.ape} />
        </TableCell>
        <TableCell>{sum.effectif}</TableCell>
        <TableCell>
          <Montant value={sum.ca} />
        </TableCell>
        {divider}
        <TableCell>
          <Evolution value={evol} />
        </TableCell>
      </TableRow>,
      <TableRow key="footerEnveloppeAgreg">
        <TableCell colSpan={2}>Total corrigé du coef. agrég.</TableCell>
        {divider}
        <TableCell colSpan={2} />
        <TableCell>
          <Montant value={caAgreg} />
        </TableCell>
        {divider}
        <TableCell colSpan={2} />
        <TableCell>
          <Montant value={sum.ca} />
        </TableCell>
        {divider}
        <TableCell>
          <Evolution value={evolAgreg} />
        </TableCell>
      </TableRow>
    );
  };

  if (enveloppe) {
    return (
      <ModificationOperations
        entete={<InfoEnveloppe enveloppe={enveloppe}/>}
        annee={enveloppe.anneeCampagne}
        allOperations={allOperations}
        footer={getFooter}
      />
    );
  }
  return <></>;
};

export default Enveloppe;
