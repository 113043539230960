import React from "react";
import { Link } from "react-router-dom";

import SirenField from "components/Creation/SirenField";
import Identifiant from "components/utils/Identifiant";
import Ape from "components/utils/Ape";
import Tooltip from "@mui/material/Tooltip";

const separateur = <b> / </b>;
const libelles = {
  raisonSociale: "RS",
  siren: "Siren",
  ape: "APE",
  entreprise: "Entreprise",
  groupe: "Groupe",
};
const getLink = (annee, siren, idEnveloppe, vue, nbOp) => {
  if (idEnveloppe) {
    return (
      <Link to={`/${annee}/${idEnveloppe}/${siren}?vue=${vue}`}>
        <Identifiant value={siren} />
        {nbOp&&nbOp>0&&(<sup> ({nbOp})</sup>)}
      </Link>
    );
  }
  return <Identifiant value={siren} />;
};

const Unite = (props) => {
  const { unite, vueEntreprise, idEnveloppe, modification, handleChange } =
    props;
  const vue = vueEntreprise ? "entreprise" : "ul";
  if (unite) {
    return (
      <div className="unite">
        <dl>
          <dt>{libelles.siren} :</dt>
          <dd>
            {modification ? (
              <SirenField
                siren={unite.siren}
                label={null}
                required={false}
                handleChange={handleChange}
                button="false"
                margin="none"
              />
            ) : (
              getLink(unite.anneeCampagne, unite.siren, idEnveloppe, vue, unite.nbOperationsLiees)
            )}
          </dd>
          {separateur}
          <dt>{libelles.ape} :</dt>
          <dd>
            <Ape value={unite.ape} />
          </dd>
          <br />
          <dt>{libelles.raisonSociale} :</dt>
          <dd>{unite.raisonSociale}</dd>
          <br />
          {vueEntreprise && (
            <>
              <dt>{libelles.entreprise} :</dt>
              <dd>
                {unite.entrepriseProfilee ? unite.entrepriseProfilee.siren : unite.siren}
              </dd>
            </>
          )}
          {vueEntreprise && unite.groupe && unite.groupe.groupeCode && (
            <>{separateur}</>
          )}
          {unite.groupe?.groupeCode && (
            <>
              <dt>{libelles.groupe} :</dt>
              <dd>
                <Tooltip
                  title={unite.groupe.raisonSociale}
                  PopperProps={{ className: "infoBulle" }}
                >
                  <span>{unite.groupe.groupeCode}</span>
                </Tooltip>
              </dd>
            </>
          )}
        </dl>
      </div>
    );
  }
  return <></>;
};

export default Unite;
