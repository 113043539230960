import { React, useState, useEffect } from 'react';
import { Tabs, Tab, AppBar, Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import OngletListOperations from "./OngletListOperations";
import OngletListUnites from "./OngletListUnites";

import "./modificationOperation.scss";

const onglets = [
  { libelle: "Liste opérations élémentaires", value: "operations", number:1 },
  { libelle: "Contours des unités", value: "unites", number:2 }
];

const ModificationOperations = (props) => {

  const {allOperations, annee, footer } = props;

  const [stateOnglet, setStateOnglet] = useState("operations");
  const [searchParams, setSearchParams] = useSearchParams();
  const onglet = searchParams.get("onglet");

  useEffect(() => {
    const ongletSafe = onglet || "operations";
    if (stateOnglet !== ongletSafe) {
      setStateOnglet(ongletSafe);
    }
  }, [onglet, stateOnglet]);

  function handleChangeOnglet (event, newValue) {
    setStateOnglet(newValue);
    setSearchParams((currentSearchParams) => {
      const newSearchParams = new URLSearchParams(currentSearchParams);
      newSearchParams.set("onglet", newValue);
      return newSearchParams;
    });

  };

  return (
    <Box className="modificationOperation">
      <Box flexShrink={0}>
        <AppBar position="static" color="default">
          {props.entete}
          <Tabs
            value={stateOnglet}
            onChange={handleChangeOnglet}
            indicatorColor="primary"
            textColor="primary"
            className="boutonModifOp" 
          >
            {onglets.map((o, i) => (
              <Tab key={o.number} label={o.libelle} value={o.value} />
            ))}
          </Tabs>
        </AppBar>
      </Box>
      <Box>
        {stateOnglet === "unites" ? (
          <OngletListUnites
            allOperations={allOperations}
            annee={annee}
            footer={footer}
          />
        ) : (
          <OngletListOperations />
        )}
      </Box>
    </Box>
  );
  
}
export default ModificationOperations;
