import { connect } from "react-redux";
import ListeRejetsRecherche from "./ListeRejetsRecherche";

const mapStateToProps = state => {
  return {
    rejets: state.storeRejets.rejets
  };
};

export default connect(mapStateToProps, {})(ListeRejetsRecherche);
