import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@mui/styles";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
} from "@mui/material";

import SirenField from "./SirenField";
import OperationField from "./OperationField";
import InfoUnite from "components/HistoriqueUl/InfoUnite";
import InfoOperation from "./InfoOperation";
import InfoCommentaire from "./InfoCommentaire";
import CreationCommentaire from "components/Commentaires/CreationCommentaire";
import EditionOperation from "./EditionOperation";
import HistoriqueCreations from "./HistoriqueCreations";
import { getOperation } from "redux/api";
import { getStyle } from "components/utils/Input";
import { enqueueSnackbar } from 'notistack';
import "./creation.scss";

const Creation = (props) => {
  
  const { saveNewOp, setAriane, getInfoUnite, classes } =
    props;

  const [completedState, setCompletedState] = useState(new Set());
  const [operationState, setOperationState] = useState({
    anneeCampagne: "",
    dateEffetComptable: "",
    dateRealisationJuridique: "",
  });
  const [operationEditionState, setOperationEditionState] = useState(null);
  const [commentaireState, setCommentaireState] = useState({ typeCommentaire: "", origineInfo: "" });
  const [activeStepState, setActiveStepState] = useState(0);

  const setDefaultState = () => {
    setCompletedState(new Set());
    setOperationState({
      anneeCampagne: "",
      dateEffetComptable: "",
      dateRealisationJuridique: "",
    });
    setCommentaireState({ typeCommentaire: "", origineInfo: "" });
    setActiveStepState(0);
  };

  // Chargement initial
  useEffect(() => {
    setAriane([{ libelle: "Création d'opération" }]);
  }, [setAriane]);

  const reset = () => {
    setDefaultState();
  };

  const miseAjourOperationEdition = (reponse) => {
    setOperationEditionState(reponse);
  };

  /**
   * Création de la nouvelle opération (sous fonction de createOperation)
   * @param {*} operation
   * @param {*} commentaire
   */
  const creationNouvelleOperation = (operation, commentaire) => {
    saveNewOp(
      operation,
      commentaire,
      getInfoUnite(operation.sirenCedante, operation.anneeCampagne),
      getInfoUnite(operation.sirenBeneficiaire, operation.anneeCampagne)
    ).then(
      () => {
        enqueueSnackbar("Votre demande de création a été prise en compte", {
          variant: "success",
        });
        reset();
      })
     .catch((error) => {
        enqueueSnackbar(
          `Votre demande de création a échoué : ${error.message}`,
          {
            variant: "error",
          }
        );
      }
    );
  };

  /**
   * Création d'une opération
   */
  const createOperation = () => {
    const operation = { ...operationState };
    getOperation(
      operation.anneeCampagne,
      operation.sirenCedante,
      operation.sirenBeneficiaire
    ).then(miseAjourOperationEdition)
    .catch(()=> {
        // S'il y a une erreur, c'est que la nouvelle opération n'existe pas
        if (operation.typeOp) {
          operation.codeTypeOperation = operation.typeOp.code;
        }
        operation.etatOp = "T";
        setOperationState(operation);
        creationNouvelleOperation(operation, commentaireState);
      });
  };

  const handleOperationExistante = (operation) => {
    if (
      operation.anneeCampagne &&
      operation.sirenCedante &&
      operation.sirenBeneficiaire
    ) {
      getOperation(
        operation.anneeCampagne,
        operation.sirenCedante,
        operation.sirenBeneficiaire
      ).then(miseAjourOperationEdition)
      .catch(()=> {
        // C'est normal, l'opération n'existe pas
      });
    }
  };

  const handleSirensIdentiques = (operation) => {
    if (
      operation.sirenBeneficiaire !== null &&
      operation.sirenBeneficiaire === operation.sirenCedante
    ) {
      enqueueSnackbar(
        `Les numéros Siren sont identiques, l'opération sera rejetée`,
        {
          variant: "warning",
        }
      );
    }
  };

  const handleSubmit = (key, value, index) => {
    const operation = { ...operationState };
    operation[key] = value;
    if (key.includes("siren", 0)) {
      handleSirensIdentiques(operation);
      handleOperationExistante(operation);
    }
    setCompletedState(new Set([...completedState, index]));
    setOperationState(operation);
    setActiveStepState(getNext(index));
  };

  const handleSubmitCommentaire = (commentaire, index) => {
    setCommentaireState({ ...commentaire });
    setCompletedState(new Set([...completedState, index]));
    setActiveStepState(getNext(index));
  };

  const handleSubmitOperationFields = (o, index) => {
    const operation = { ...operationState, ...o };
    handleOperationExistante(operation);
    setOperationState(operation);
    setActiveStepState(getNext(index));
    setCompletedState(new Set([...completedState, index]));
  };

  const getSteps = () => {
    const uniteCedante = getInfoUnite(
      operationState.sirenCedante,
      operationState.anneeCampagne
    );
    const uniteBeneficiaire = getInfoUnite(
      operationState.sirenBeneficiaire,
      operationState.anneeCampagne
    );
    return [
      {
        titre: [
          <h2 key="titre">Identifiant de l'unité légale cédante</h2>,
          <span key="info">
            <InfoUnite unite={uniteCedante} />
          </span>,
        ],
        content: (
          <SirenField
            id="sirenCedante"
            label="Siren cédant"
            classes={{
              asterisk: classes.asterisk,
            }}
            siren={operationState.sirenCedante}
            onSubmit={v => handleSubmit("sirenCedante", v, 0)}
          />
        ),
        etape : 1
      },
      {
        titre: [
          <h2 key="titre">Identifiant de l'unité légale bénéficiaire</h2>,
          <span key="info">
            <InfoUnite unite={uniteBeneficiaire} />
          </span>,
        ],
        content: (
          <SirenField
            id="sirenBeneficiaire"
            label="Siren bénéficiaire"
            classes={{
              asterisk: classes.asterisk,
            }}
            siren={operationState.sirenBeneficiaire}
            onSubmit={v => handleSubmit("sirenBeneficiaire", v, 1)}
          />
        ),
        etape : 2
      },
      {
        titre: [
          <h2 key="titre">Informations sur l'opération</h2>,
          <InfoOperation key="info" operation={operationState} />,
        ],
        content: (
          <OperationField
            classes={{
              asterisk: classes.asterisk,
            }}
            operation={{ ...operationState }}
            onSubmit={(v) => handleSubmitOperationFields(v, 2)}
          />
        ),
        etape : 3
      },
      {
        titre: [
          <h2 key="titre">Commentaire sur l'opération</h2>,
          <InfoCommentaire key="info" commentaire={commentaireState} />,
        ],
        content: (
          <CreationCommentaire
            onSubmit={(c) => handleSubmitCommentaire(c, 3)}
            commentaire={{ ...commentaireState }}
            submitTxt="Suivant"
          />
        ),
        etape : 4
      },
    ];
  };

  const setActive = (i) => {
    setActiveStepState(i);
  };

  const getNext = (index) => {
    let i = index + 1;
    const n = getSteps().length;
    while (i < n && completedState.has(i)) {
      i++;
    }
    return i;
  };

  const myRef = useRef(null);

  let height = "100%";
  if (myRef.current) {
    const top = myRef.current.getBoundingClientRect().top + 50;
    height = `calc(100vh - ${top}px)`;
  }

  const retour = () => {
    setOperationEditionState(null);
  };

  if (!operationEditionState) {
    return (
      <div className="creation"
        ref={myRef}
        style={{height}}
      >
        <p className="info">
          Les champs annotés <span style={getStyle().asterisk}>*</span> sont
          obligatoires
        </p>
        <Stepper activeStep={activeStepState} orientation="vertical">
          {getSteps().map((s, index) => (
            <Step key={s.etape}>
              <StepLabel
                className="titre"
                onClick={() => setActive(index)}
                completed={completedState.has(index).toString()}
                icon=" "
              >
                {s.titre}
              </StepLabel>
              <StepContent className="content">{s.content}</StepContent>
            </Step>
          ))}
        </Stepper>
        <Button
          variant="contained"
          color="primary"
          disabled={completedState.size !== 4}
          style={{ display: "block", margin: "10px" }}
          onClick={createOperation}
        >
          Valider l'opération
        </Button>
        <HistoriqueCreations />
      </div>
    );
  }
  return (
    <div className="creation">
      <EditionOperation operation={operationEditionState} />{" "}
      <Button
        variant="contained"
        color="primary"
        style={{ display: "block", margin: "10px" }}
        onClick={retour}
      >
        Retour à la création
      </Button>
    </div>
  );
};

export default withStyles(getStyle)(Creation);
