export const naf = [
{libelle :"Tous",value:null},
{libelle :"A - AGRICULTURE, SYLVICULTURE ET PÊCHE",value:"A"},
{libelle :"01 - Cult. & prod. animale, chasse & sce ann.",value:"01"},
{libelle :"011 - Cultures non permanentes",value:"011"},
{libelle :"0111Z - Cult céréale, légumineuse, graine oléag.",value:"0111Z"},
{libelle :"0112Z - Culture du riz",value:"0112Z"},
{libelle :"0113Z - Cult. légume, melon, racine & tubercule",value:"0113Z"},
{libelle :"0114Z - Culture de la canne à sucre",value:"0114Z"},
{libelle :"0115Z - Culture du tabac",value:"0115Z"},
{libelle :"0116Z - Culture de plantes à fibres",value:"0116Z"},
{libelle :"0119Z - Autres cultures non permanentes",value:"0119Z"},
{libelle :"012 - Cultures permanentes",value:"012"},
{libelle :"0121Z - Culture de la vigne",value:"0121Z"},
{libelle :"0122Z - Culture fruits tropicaux et subtropicaux",value:"0122Z"},
{libelle :"0123Z - Culture d'agrumes",value:"0123Z"},
{libelle :"0124Z - Culture de fruits à pépins et à noyau",value:"0124Z"},
{libelle :"0125Z - Cult. d'aut. fruits & de fruits à coque",value:"0125Z"},
{libelle :"0126Z - Culture de fruits oléagineux",value:"0126Z"},
{libelle :"0127Z - Culture de plantes à boissons",value:"0127Z"},
{libelle :"0128Z - Cult. plante aromatiq. médicin. pharma.",value:"0128Z"},
{libelle :"0129Z - Autres cultures permanentes",value:"0129Z"},
{libelle :"013 - Reproduction de plantes",value:"013"},
{libelle :"0130Z - Reproduction de plantes",value:"0130Z"},
{libelle :"014 - Production animale",value:"014"},
{libelle :"0141Z - Élevage de vaches laitières",value:"0141Z"},
{libelle :"0142Z - Élevage d'autres bovins et de buffles",value:"0142Z"},
{libelle :"0143Z - Élevage de chevaux et d'autres équidés",value:"0143Z"},
{libelle :"0144Z - Élevage de chameaux & d'autres camélidés",value:"0144Z"},
{libelle :"0145Z - Élevage d'ovins et de caprins",value:"0145Z"},
{libelle :"0146Z - Élevage de porcins",value:"0146Z"},
{libelle :"0147Z - Élevage de volailles",value:"0147Z"},
{libelle :"0149Z - Élevage d'autres animaux",value:"0149Z"},
{libelle :"015 - Culture et élevage associés",value:"015"},
{libelle :"0150Z - Culture et élevage associés",value:"0150Z"},
{libelle :"016 - Act. soutien agr. & trait. prim. récolt.",value:"016"},
{libelle :"0161Z - Activités de soutien aux cultures",value:"0161Z"},
{libelle :"0162Z - Activités de soutien à la prod. animale",value:"0162Z"},
{libelle :"0163Z - Traitement primaire des récoltes",value:"0163Z"},
{libelle :"0164Z - Traitement des semences",value:"0164Z"},
{libelle :"017 - Chasse, piégeage et services annexes",value:"017"},
{libelle :"0170Z - Chasse, piégeage et services annexes",value:"0170Z"},
{libelle :"02 - Sylviculture et exploitation forestière",value:"02"},
{libelle :"021 - Sylviculture & autres act. forestières",value:"021"},
{libelle :"0210Z - Sylviculture & autres act. forestières",value:"0210Z"},
{libelle :"022 - Exploitation forestière",value:"022"},
{libelle :"0220Z - Exploitation forestière",value:"0220Z"},
{libelle :"023 - Récolte prodts forestiers non ligneux",value:"023"},
{libelle :"0230Z - Récolte prodts forestiers non ligneux",value:"0230Z"},
{libelle :"024 - Services de soutien à l'expl. forestière",value:"024"},
{libelle :"0240Z - Services de soutien à l'expl. forestière",value:"0240Z"},
{libelle :"03 - Pêche et aquaculture",value:"03"},
{libelle :"031 - Pêche",value:"031"},
{libelle :"0311Z - Pêche en mer",value:"0311Z"},
{libelle :"0312Z - Pêche en eau douce",value:"0312Z"},
{libelle :"032 - Aquaculture",value:"032"},
{libelle :"0321Z - Aquaculture en mer",value:"0321Z"},
{libelle :"0322Z - Aquaculture en eau douce",value:"0322Z"},
{libelle :"BE - Toutes industries",value:"BE"},
{libelle :"B - INDUSTRIES EXTRACTIVES",value:"B"},
{libelle :"05 - Extraction de houille et de lignite",value:"05"},
{libelle :"051 - Extraction de houille",value:"051"},
{libelle :"0510Z - Extraction de houille",value:"0510Z"},
{libelle :"052 - Extraction de lignite",value:"052"},
{libelle :"0520Z - Extraction de lignite",value:"0520Z"},
{libelle :"06 - Extraction d'hydrocarbures",value:"06"},
{libelle :"061 - Extraction de pétrole brut",value:"061"},
{libelle :"0610Z - Extraction de pétrole brut",value:"0610Z"},
{libelle :"062 - Extraction de gaz naturel",value:"062"},
{libelle :"0620Z - Extraction de gaz naturel",value:"0620Z"},
{libelle :"07 - Extraction de minerais métalliques",value:"07"},
{libelle :"071 - Extraction de minerais de fer",value:"071"},
{libelle :"0710Z - Extraction de minerais de fer",value:"0710Z"},
{libelle :"072 - Extr. de minerais de métaux non ferreux",value:"072"},
{libelle :"0721Z - Extr. de minerais d'uranium & de thorium",value:"0721Z"},
{libelle :"0729Z - Extr. aut. minerai de métaux non ferreux",value:"0729Z"},
{libelle :"08 - Autres industries extractives",value:"08"},
{libelle :"081 - Extr. de pierres, de sables et d'argiles",value:"081"},
{libelle :"0811Z - Extr. pierre ornement. & construct. etc.",value:"0811Z"},
{libelle :"0812Z - Exploit. gravière & sabl., extr. argile",value:"0812Z"},
{libelle :"089 - Activités extractives n.c.a.",value:"089"},
{libelle :"0891Z - Extr. minéraux chimiq. & engrais min.",value:"0891Z"},
{libelle :"0892Z - Extraction de tourbe",value:"0892Z"},
{libelle :"0893Z - Production de sel",value:"0893Z"},
{libelle :"0899Z - Autres activités extractives n.c.a.",value:"0899Z"},
{libelle :"09 - Sces de soutien aux indust. extractives",value:"09"},
{libelle :"091 - Act. de soutien à l'extr. hydrocarbures",value:"091"},
{libelle :"0910Z - Act. de soutien à l'extr. hydrocarbures",value:"0910Z"},
{libelle :"099 - Act. de soutien aut. indus. extractives",value:"099"},
{libelle :"0990Z - Act. de soutien aut. indus. extractives",value:"0990Z"},
{libelle :"C - INDUSTRIE MANUFACTURIÈRE",value:"C"},
{libelle :"10 - Industries alimentaires",value:"10"},
{libelle :"101 - Transf. & conserv. viande & prép. viande",value:"101"},
{libelle :"1011Z - Transf. & conserv.  viande de boucherie",value:"1011Z"},
{libelle :"1012Z - Transf. & conserv. de viande de volaille",value:"1012Z"},
{libelle :"1013 - Préparation de produits à base de viande",value:"1013"},
{libelle :"1013A - Prépa. indust. produits à base de viande",value:"1013A"},
{libelle :"1013B - Charcuterie",value:"1013B"},
{libelle :"102 - Transf. & conserv. poisson, crust., etc.",value:"102"},
{libelle :"1020Z - Transf. & conserv. poisson, crust., etc.",value:"1020Z"},
{libelle :"103 - Transf. et conserv. de fruits et légumes",value:"103"},
{libelle :"1031Z - Transf. et conserv. de pommes de terre",value:"1031Z"},
{libelle :"1032Z - Préparation de jus de fruits et légumes",value:"1032Z"},
{libelle :"1039 - Aut. transf. & cons. de fruit et légume",value:"1039"},
{libelle :"1039A - Autre transf. et conserv. de légumes",value:"1039A"},
{libelle :"1039B - Transformation et conservation de fruits",value:"1039B"},
{libelle :"104 - Fab. huile et graisse végétale & animale",value:"104"},
{libelle :"1041 - Fabrication d'huiles et graisses",value:"1041"},
{libelle :"1041A - Fabrication d'huiles et graisses brutes",value:"1041A"},
{libelle :"1041B - Fab. d'huiles et graisses raffinées",value:"1041B"},
{libelle :"1042Z - Fab. de margarine & graisses similaires",value:"1042Z"},
{libelle :"105 - Fabrication de produits laitiers",value:"105"},
{libelle :"1051 - Expl. de laiteries et fabric. de fromage",value:"1051"},
{libelle :"1051A - Fab. de lait liquide & de produits frais",value:"1051A"},
{libelle :"1051B - Fabrication de beurre",value:"1051B"},
{libelle :"1051C - Fabrication de fromage",value:"1051C"},
{libelle :"1051D - Fabrication d'autres produits laitiers",value:"1051D"},
{libelle :"1052Z - Fabrication de glaces et sorbets",value:"1052Z"},
{libelle :"106 - Travail des grains ; fab. prod. amylacé",value:"106"},
{libelle :"1061 - Travail des grains",value:"1061"},
{libelle :"1061A - Meunerie",value:"1061A"},
{libelle :"1061B - Autres activités du travail des grains",value:"1061B"},
{libelle :"1062Z - Fabrication de produits amylacés",value:"1062Z"},
{libelle :"107 - Fab. prod. boulangerie-pâtis. & pâtes ",value:"107"},
{libelle :"1071 - Fab. de pain et de pâtisserie fraîche",value:"1071"},
{libelle :"1071A - Fab. indus. de pain & pâtisserie fraîche",value:"1071A"},
{libelle :"1071B - Cuisson de produits de boulangerie",value:"1071B"},
{libelle :"1071C - Boulangerie et boulangerie-pâtisserie",value:"1071C"},
{libelle :"1071D - Pâtisserie",value:"1071D"},
{libelle :"1072Z - Fab. pain, biscuit & pâtiss. de conserv.",value:"1072Z"},
{libelle :"1073Z - Fabrication de pâtes alimentaires",value:"1073Z"},
{libelle :"108 - Fabric. d'autres produits alimentaires",value:"108"},
{libelle :"1081Z - Fabrication de sucre",value:"1081Z"},
{libelle :"1082Z - Fabric. de cacao, chocolat & confiseries",value:"1082Z"},
{libelle :"1083Z - Transformation du thé et du café",value:"1083Z"},
{libelle :"1084Z - Fabric. de condiments et assaisonnements",value:"1084Z"},
{libelle :"1085Z - Fabrication de plats préparés",value:"1085Z"},
{libelle :"1086Z - Fab. d'aliment homogénéisé & diététique",value:"1086Z"},
{libelle :"1089Z - Fab. d'autres prod. alimentaires n.c.a.",value:"1089Z"},
{libelle :"109 - Fabrication d'aliments pour animaux",value:"109"},
{libelle :"1091Z - Fabric. d'aliments pour animaux de ferme",value:"1091Z"},
{libelle :"1092Z - Fab. aliments pour animaux de compagnie",value:"1092Z"},
{libelle :"11 - Fabrication de boissons",value:"11"},
{libelle :"110 - Fabrication de boissons",value:"110"},
{libelle :"1101Z - Prod. de boissons alcooliques distillées",value:"1101Z"},
{libelle :"1102 - Production de vin (de raisin)",value:"1102"},
{libelle :"1102A - Fabrication de vins effervescents",value:"1102A"},
{libelle :"1102B - Vinification",value:"1102B"},
{libelle :"1103Z - Fabrication de cidre & de vins de fruits",value:"1103Z"},
{libelle :"1104Z - Prod. aut. boisson fermentée non distil.",value:"1104Z"},
{libelle :"1105Z - Fabrication de bière",value:"1105Z"},
{libelle :"1106Z - Fabrication de malt",value:"1106Z"},
{libelle :"1107 - Ind. eaux & boissons rafraîchissantes",value:"1107"},
{libelle :"1107A - Industrie des eaux de table",value:"1107A"},
{libelle :"1107B - Production de boissons rafraîchissantes",value:"1107B"},
{libelle :"12 - Fabrication de produits à base de tabac",value:"12"},
{libelle :"120 - Fabrication de produits à base de tabac",value:"120"},
{libelle :"1200Z - Fabrication de produits à base de tabac",value:"1200Z"},
{libelle :"13 - Fabrication de textiles",value:"13"},
{libelle :"131 - Prépa. de fibres textiles et filature",value:"131"},
{libelle :"1310Z - Prépa. de fibres textiles et filature",value:"1310Z"},
{libelle :"132 - Tissage",value:"132"},
{libelle :"1320 - Tissage",value:"1320"},
{libelle :"1320Z - Tissage",value:"1320Z"},
{libelle :"133 - Ennoblissement textile",value:"133"},
{libelle :"1330Z - Ennoblissement textile",value:"1330Z"},
{libelle :"139 - Fabrication d'autres textiles",value:"139"},
{libelle :"1391Z - Fabrication d'étoffes à mailles",value:"1391Z"},
{libelle :"1392Z - Fab. d'article textile, sauf habillement",value:"1392Z"},
{libelle :"1393Z - Fabrication de tapis et moquettes",value:"1393Z"},
{libelle :"1394Z - Fabric. de ficelles, cordes et filets",value:"1394Z"},
{libelle :"1395Z - Fabric. de non-tissés, sauf habillement",value:"1395Z"},
{libelle :"1396Z - Fab. autre textile techniq. & industriel",value:"1396Z"},
{libelle :"1399Z - Fabrication d'autres textiles n.c.a.",value:"1399Z"},
{libelle :"14 - Industrie de l'habillement",value:"14"},
{libelle :"141 - Fab. de vêtements, autres qu'en fourrure",value:"141"},
{libelle :"1411Z - Fabrication de vêtements en cuir",value:"1411Z"},
{libelle :"1412Z - Fabrication de vêtements de travail",value:"1412Z"},
{libelle :"1413Z - Fabrication de vêtements de dessus",value:"1413Z"},
{libelle :"1414Z - Fabrication de vêtements de dessous",value:"1414Z"},
{libelle :"1419Z - Fabric. autres vêtements et accessoires",value:"1419Z"},
{libelle :"142 - Fabrication d'articles en fourrure",value:"142"},
{libelle :"1420Z - Fabrication d'articles en fourrure",value:"1420Z"},
{libelle :"143 - Fabrication d'articles à mailles",value:"143"},
{libelle :"1431Z - Fabric. d'articles chaussants à mailles",value:"1431Z"},
{libelle :"1439Z - Fabrication d'autres articles à mailles",value:"1439Z"},
{libelle :"15 - Industrie du cuir et de la chaussure",value:"15"},
{libelle :"151 - Indust. cuir & fourrure; maroq. & selle.",value:"151"},
{libelle :"1511Z - Prépa. cuirs; prép. & teinture fourrures",value:"1511Z"},
{libelle :"1512Z - Fab. art. voyage, maroquin., & sellerie",value:"1512Z"},
{libelle :"152 - Fabrication de chaussures",value:"152"},
{libelle :"1520Z - Fabrication de chaussures",value:"1520Z"},
{libelle :"16 - Trav. bois; fab. article bois, vannerie",value:"16"},
{libelle :"161 - Sciage et rabotage du bois",value:"161"},
{libelle :"1610 - Sciage et rabotage du bois",value:"1610"},
{libelle :"1610A - Sciage & rabotage bois, sf imprégnation",value:"1610A"},
{libelle :"1610B - Imprégnation du bois",value:"1610B"},
{libelle :"162 - Fab. article bois, liège, vannerie, etc.",value:"162"},
{libelle :"1621Z - Fabric.  placage et panneaux de bois",value:"1621Z"},
{libelle :"1622Z - Fabrication de parquets assemblés",value:"1622Z"},
{libelle :"1623Z - Fab. charpentes et autres menuiseries",value:"1623Z"},
{libelle :"1624Z - Fabrication d'emballages en bois",value:"1624Z"},
{libelle :"1629Z - Fab. objet div. bois, liège, vann., etc.",value:"1629Z"},
{libelle :"17 - Industrie du papier et du carton",value:"17"},
{libelle :"171 - Fab. de pâte à papier, papier et carton",value:"171"},
{libelle :"1711Z - Fabrication de pâte à papier",value:"1711Z"},
{libelle :"1712Z - Fabrication de papier et de carton",value:"1712Z"},
{libelle :"172 - Fab. d'articles en papier ou en carton",value:"172"},
{libelle :"1721 - Fab. papier & carton & emball. pap.-car.",value:"1721"},
{libelle :"1721A - Fabrication de carton ondulé",value:"1721A"},
{libelle :"1721B - Fabrication de cartonnages",value:"1721B"},
{libelle :"1721C - Fabrication d'emballages en papier",value:"1721C"},
{libelle :"1722Z - Fab. article papier sanit. ou domestique",value:"1722Z"},
{libelle :"1723Z - Fabrication d'articles de papeterie",value:"1723Z"},
{libelle :"1724Z - Fabrication de papiers peints",value:"1724Z"},
{libelle :"1729Z - Fab. aut. article en papier ou en carton",value:"1729Z"},
{libelle :"18 - Imprimerie & reprod. d'enregistrements",value:"18"},
{libelle :"181 - Imprimerie et services annexes",value:"181"},
{libelle :"1811Z - Imprimerie de journaux",value:"1811Z"},
{libelle :"1812Z - Autre imprimerie (labeur)",value:"1812Z"},
{libelle :"1813Z - Activités de pré-presse",value:"1813Z"},
{libelle :"1814Z - Reliure et activités connexes",value:"1814Z"},
{libelle :"182 - Reproduction d'enregistrements",value:"182"},
{libelle :"1820Z - Reproduction d'enregistrements",value:"1820Z"},
{libelle :"19 - Cokéfaction et raffinage",value:"19"},
{libelle :"191 - Cokéfaction",value:"191"},
{libelle :"1910Z - Cokéfaction",value:"1910Z"},
{libelle :"192 - Raffinage du pétrole",value:"192"},
{libelle :"1920Z - Raffinage du pétrole",value:"1920Z"},
{libelle :"20 - Industrie chimique",value:"20"},
{libelle :"201 - Fab. prod. chimi., plast. & caout. synt.",value:"201"},
{libelle :"2011Z - Fabrication de gaz industriels",value:"2011Z"},
{libelle :"2012Z - Fabrication de colorants et de pigments",value:"2012Z"},
{libelle :"2013 - Fab. aut. prod. chimique inorg. de base",value:"2013"},
{libelle :"2013A - Enrichissment & retrait. mat. nucléaire",value:"2013A"},
{libelle :"2013B - Fab. aut. prod. chim. inorg. base n.c.a.",value:"2013B"},
{libelle :"2014Z - Fab. aut. prod. chimique org. de base ",value:"2014Z"},
{libelle :"2015Z - Fabric. de produits azotés et d'engrais",value:"2015Z"},
{libelle :"2016Z - Fabric. de matières plastiques de base",value:"2016Z"},
{libelle :"2017Z - Fabrication de caoutchouc synthétique",value:"2017Z"},
{libelle :"202 - Fab. pesticide & aut. prod. agrochimique",value:"202"},
{libelle :"2020Z - Fab. pesticide & aut. prod. agrochimique",value:"2020Z"},
{libelle :"203 - Fab. de peinture, vernis, encre & mastic",value:"203"},
{libelle :"2030Z - Fab. de peinture, vernis, encre & mastic",value:"2030Z"},
{libelle :"204 - Fab. savon, prod. entretien & parfum",value:"204"},
{libelle :"2041Z - Fab. savon, détergent & prod. entretien",value:"2041Z"},
{libelle :"2042Z - Fab. parfum & produit pour la toilette",value:"2042Z"},
{libelle :"205 - Fabrication d'autres produits chimiques",value:"205"},
{libelle :"2051Z - Fabrication de produits explosifs",value:"2051Z"},
{libelle :"2052Z - Fabrication de colles",value:"2052Z"},
{libelle :"2053Z - Fabrication d'huiles essentielles",value:"2053Z"},
{libelle :"2059Z - Fabric. autres produits chimiques n.c.a.",value:"2059Z"},
{libelle :"206 - Fab.  fibre artificielle ou synthétique",value:"206"},
{libelle :"2060Z - Fab.  fibre artificielle ou synthétique",value:"2060Z"},
{libelle :"21 - Industrie pharmaceutique",value:"21"},
{libelle :"211 - Fab. de produits pharmaceutiques de base",value:"211"},
{libelle :"2110Z - Fab. de produits pharmaceutiques de base",value:"2110Z"},
{libelle :"212 - Fabric. de préparations pharmaceutiques",value:"212"},
{libelle :"2120Z - Fabric. de préparations pharmaceutiques",value:"2120Z"},
{libelle :"22 - Fab. prod. en caoutchouc & en plastique",value:"22"},
{libelle :"221 - Fabrication de produits en caoutchouc",value:"221"},
{libelle :"2211Z - Fabrication et rechapage de pneumatiques",value:"2211Z"},
{libelle :"2219Z - Fabric. d'autres articles en caoutchouc",value:"2219Z"},
{libelle :"222 - Fabrication  de produits en plastique",value:"222"},
{libelle :"2221Z - Fab. plaque, feuille, tube,  etc. plast.",value:"2221Z"},
{libelle :"2222Z - Fab. d'emballage en matière plastique",value:"2222Z"},
{libelle :"2223Z - Fab. élément mat. plastiq. pr construct.",value:"2223Z"},
{libelle :"2229 - Fab. autre article en matière plastique",value:"2229"},
{libelle :"2229A - Fab. pièce techniq. base mat. plastiq.",value:"2229A"},
{libelle :"2229B - Fab. prod. conso. courante en plastique",value:"2229B"},
{libelle :"23 - Fab. aut. prod. minéraux non métalliques",value:"23"},
{libelle :"231 - Fabric de verre et d'articles en verre",value:"231"},
{libelle :"2311Z - Fabrication de verre plat",value:"2311Z"},
{libelle :"2312Z - Façonnage & transformation du verre plat",value:"2312Z"},
{libelle :"2313Z - Fabrication de verre creux",value:"2313Z"},
{libelle :"2314Z - Fabrication de fibres de verre",value:"2314Z"},
{libelle :"2319Z - Fab. & façonnage aut. article en verre",value:"2319Z"},
{libelle :"232 - Fabrication de produits réfractaires",value:"232"},
{libelle :"2320Z - Fabrication de produits réfractaires",value:"2320Z"},
{libelle :"233 - Fab. matériaux de constr. en terre cuite",value:"233"},
{libelle :"2331Z - Fabrication de carreaux en céramique",value:"2331Z"},
{libelle :"2332Z - Fab. produit  construct. en terre cuite",value:"2332Z"},
{libelle :"234 - Fab. aut .prod. en céramiq. & porcelaine",value:"234"},
{libelle :"2341Z - Fab. art. céramiq. usage domest. & déco.",value:"2341Z"},
{libelle :"2342Z - Fab. appareil sanitaire en céramique",value:"2342Z"},
{libelle :"2343Z - Fab. isolateur & pièce isolante céramiq.",value:"2343Z"},
{libelle :"2344Z - Fab. aut. prod. céram. à usage technique",value:"2344Z"},
{libelle :"2349Z - Fabrication d'autres produits céramiques",value:"2349Z"},
{libelle :"235 - Fabrication de ciment, chaux et plâtre",value:"235"},
{libelle :"2351Z - Fabrication de ciment",value:"2351Z"},
{libelle :"2352Z - Fabrication de chaux et plâtre",value:"2352Z"},
{libelle :"236 - Fabric. ouvrage en béton, ciment, plâtre",value:"236"},
{libelle :"2361Z - Fab. élément en béton pour la construct.",value:"2361Z"},
{libelle :"2362Z - Fab. élément en plâtre pour la construc.",value:"2362Z"},
{libelle :"2363Z - Fabrication de béton prêt à l'emploi",value:"2363Z"},
{libelle :"2364Z - Fabrication de mortiers et bétons secs",value:"2364Z"},
{libelle :"2365Z - Fabrication d'ouvrages en fibre-ciment",value:"2365Z"},
{libelle :"2369Z - Fab. aut. ouvrage béton, ciment, plâtre",value:"2369Z"},
{libelle :"237 - Taille, façonnage & finissage de pierres",value:"237"},
{libelle :"2370Z - Taille, façonnage & finissage de pierres",value:"2370Z"},
{libelle :"239 - Fab. pdt abrasif & minér. non métal nca.",value:"239"},
{libelle :"2391Z - Fabrication de produits abrasifs",value:"2391Z"},
{libelle :"2399Z - Fab. aut. prod. minéraux non métal. nca.",value:"2399Z"},
{libelle :"24 - Métallurgie",value:"24"},
{libelle :"241 - Sidérurgie",value:"241"},
{libelle :"2410Z - Sidérurgie",value:"2410Z"},
{libelle :"242 - Fab. tube, profilé creux etc. en acier",value:"242"},
{libelle :"2420Z - Fab. tube, profilé creux etc. en acier",value:"2420Z"},
{libelle :"243 - Fab. aut. prod. de 1ère transform. acier",value:"243"},
{libelle :"2431Z - Étirage à froid de barres",value:"2431Z"},
{libelle :"2432Z - Laminage à froid de feuillards",value:"2432Z"},
{libelle :"2433Z - Profilage à froid par formage ou pliage",value:"2433Z"},
{libelle :"2434Z - Tréfilage à froid",value:"2434Z"},
{libelle :"244 - Prod. métaux précieux & aut. m. non fer.",value:"244"},
{libelle :"2441Z - Production de métaux précieux",value:"2441Z"},
{libelle :"2442Z - Métallurgie de l'aluminium",value:"2442Z"},
{libelle :"2443Z - Métallurgie du Pb, du Zn ou du Sn",value:"2443Z"},
{libelle :"2444Z - Métallurgie du cuivre",value:"2444Z"},
{libelle :"2445Z - Métallurgie autres métaux non ferreux",value:"2445Z"},
{libelle :"2446Z - Élaboration et transform. mat. nucléaire",value:"2446Z"},
{libelle :"245 - Fonderie",value:"245"},
{libelle :"2451Z - Fonderie de fonte",value:"2451Z"},
{libelle :"2452Z - Fonderie d'acier",value:"2452Z"},
{libelle :"2453Z - Fonderie de métaux légers",value:"2453Z"},
{libelle :"2454Z - Fonderie d'autres métaux non ferreux",value:"2454Z"},
{libelle :"25 - Fab. prod. métalliq. sf machine & équipt",value:"25"},
{libelle :"251 - Fab. élément en métal pour la construct.",value:"251"},
{libelle :"2511Z - Fab. structure métal. & partie structure",value:"2511Z"},
{libelle :"2512Z - Fabric. de portes et fenêtres en métal",value:"2512Z"},
{libelle :"252 - Fab. réservr, citerne & conteneur métal.",value:"252"},
{libelle :"2521Z - Fab. radiat. & chaudière pr chauf. ctral",value:"2521Z"},
{libelle :"2529Z - Fab. aut. réservr, citerne, etc. métal.",value:"2529Z"},
{libelle :"253 - Fab. générat. vapeur sf pr chauff. ctral",value:"253"},
{libelle :"2530Z - Fab. générat. vapeur sf pr chauff. ctral",value:"2530Z"},
{libelle :"254 - Fabrication d'armes et de munitions",value:"254"},
{libelle :"2540Z - Fabrication d'armes et de munitions",value:"2540Z"},
{libelle :"255 - Forge, etc.; métallurgie des poudres",value:"255"},
{libelle :"2550 - Forge, etc.; métallurgie des poudres",value:"2550"},
{libelle :"2550A - Forge; métallurgie des poudres",value:"2550A"},
{libelle :"2550B - Découpage, emboutissage",value:"2550B"},
{libelle :"256 - Traitmnt & revêtmnt des métaux; usinage",value:"256"},
{libelle :"2561Z - Traitement et revêtement des métaux",value:"2561Z"},
{libelle :"2562 - Usinage",value:"2562"},
{libelle :"2562A - Décolletage",value:"2562A"},
{libelle :"2562B - Mécanique industrielle",value:"2562B"},
{libelle :"257 - Fab. coutellerie, outillage & quincaill.",value:"257"},
{libelle :"2571Z - Fabrication de coutellerie",value:"2571Z"},
{libelle :"2572Z - Fabrication de serrures et de ferrures",value:"2572Z"},
{libelle :"2573 - Fabrication d'outillage",value:"2573"},
{libelle :"2573A - Fabrication de moules et modèles",value:"2573A"},
{libelle :"2573B - Fabrication d'autres outillages",value:"2573B"},
{libelle :"259 - Fabrication d'autres ouvrages en métaux",value:"259"},
{libelle :"2591Z - Fab. fût & emballage métalliq. similaire",value:"2591Z"},
{libelle :"2592Z - Fabric. d'emballages métalliques légers",value:"2592Z"},
{libelle :"2593Z - Fab. art.  fil métal., chaîne & ressort",value:"2593Z"},
{libelle :"2594Z - Fabrication de vis et de boulons",value:"2594Z"},
{libelle :"2599 - Fabric. autres prod. métalliques n.c.a.",value:"2599"},
{libelle :"2599A - Fabric. d'articles métalliques ménagers",value:"2599A"},
{libelle :"2599B - Fabric. d'autres articles métalliques",value:"2599B"},
{libelle :"26 - Fab. prod. informat., électroniq. & opt.",value:"26"},
{libelle :"261 - Fab. de composants & cartes électroniq.",value:"261"},
{libelle :"2611Z - Fabrication de composants électroniques",value:"2611Z"},
{libelle :"2612Z - Fab. de cartes électroniques assemblées",value:"2612Z"},
{libelle :"262 - Fab. ordinateur & équipement périphériq.",value:"262"},
{libelle :"2620Z - Fab. ordinateur & équipement périphériq.",value:"2620Z"},
{libelle :"263 - Fabric. d'équipements de communication",value:"263"},
{libelle :"2630Z - Fabric. d'équipements de communication",value:"2630Z"},
{libelle :"264 - Fab. produit électronique grand public",value:"264"},
{libelle :"2640Z - Fab. produit électronique grand public",value:"2640Z"},
{libelle :"265 - Fab. instr. mesure, navigat.; horlogerie",value:"265"},
{libelle :"2651 - Fab. instrum. de mesure & de navigation",value:"2651"},
{libelle :"2651A - Fab. équipement d'aide à la navigation",value:"2651A"},
{libelle :"2651B - Fab. instrumentation scientifiq. & tech.",value:"2651B"},
{libelle :"2652Z - Horlogerie",value:"2652Z"},
{libelle :"266 - Fab. éqpt irrad. médic. & électromedic.",value:"266"},
{libelle :"2660Z - Fab. éqpt irrad. médic. & électromedic.",value:"2660Z"},
{libelle :"267 - Fab. matériel optique et photographique",value:"267"},
{libelle :"2670Z - Fab. matériel optique et photographique",value:"2670Z"},
{libelle :"268 - Fab. de supports magnétiques et optiques",value:"268"},
{libelle :"2680Z - Fab. de supports magnétiques et optiques",value:"2680Z"},
{libelle :"27 - Fabrication d'équipements électriques",value:"27"},
{libelle :"271 - Fab. moteur génér. transfo. & mat. élec.",value:"271"},
{libelle :"2711Z - Fab. moteur génér. transfo. & mat. élec.",value:"2711Z"},
{libelle :"2712Z - Fab. mat. de distrib. & de cde électri.",value:"2712Z"},
{libelle :"272 - Fabric. pile & accumulateur électrique",value:"272"},
{libelle :"2720Z - Fabric. pile & accumulateur électrique",value:"2720Z"},
{libelle :"273 - Fab. fil câble & mat. install. électriq.",value:"273"},
{libelle :"2731Z - Fabrication de câbles de fibres optiques",value:"2731Z"},
{libelle :"2732Z - Fab. aut. fil & câble éltron. ou éltriq.",value:"2732Z"},
{libelle :"2733Z - Fabric. matériel installation électrique",value:"2733Z"},
{libelle :"274 - Fabric. appareils d'éclairage électrique",value:"274"},
{libelle :"2740Z - Fabric. appareils d'éclairage électrique",value:"2740Z"},
{libelle :"275 - Fabrication d'appareils ménagers",value:"275"},
{libelle :"2751Z - Fabrication d'appareils électroménagers",value:"2751Z"},
{libelle :"2752Z - Fab. appareils ménagers non électriques",value:"2752Z"},
{libelle :"279 - Fabric. d'autres matériels électriques",value:"279"},
{libelle :"2790Z - Fabric. d'autres matériels électriques",value:"2790Z"},
{libelle :"28 - Fabric. de machines & équipements n.c.a.",value:"28"},
{libelle :"281 - Fabrication de machines d'usage général",value:"281"},
{libelle :"2811Z - Fab. moteur & turb. sf pr avion & véhic.",value:"2811Z"},
{libelle :"2812Z - Fab. équipement hydraulique & pneumatiq.",value:"2812Z"},
{libelle :"2813Z - Fabric. d'autres pompes et compresseurs",value:"2813Z"},
{libelle :"2814Z - Fabric. autres articles de robinetterie",value:"2814Z"},
{libelle :"2815Z - Fab. engrenage & organe méca. transmis.",value:"2815Z"},
{libelle :"282 - Fabric. autres machines d'usage général",value:"282"},
{libelle :"2821Z - Fabrication de fours et brûleurs",value:"2821Z"},
{libelle :"2822Z - Fab. matériel de levage & de manutention",value:"2822Z"},
{libelle :"2823Z - Fab. machine équipt bureau (sf ordinat.)",value:"2823Z"},
{libelle :"2824Z - Fab. outillage portatif à moteur incorp.",value:"2824Z"},
{libelle :"2825Z - Fab. équipt aérauliq. & frigorifiq. ind.",value:"2825Z"},
{libelle :"2829 - Fab. machines diverses d'usage général",value:"2829"},
{libelle :"2829A - Fab. éqpt emballage condition. & pesage",value:"2829A"},
{libelle :"2829B - Fab. d'autres machines d'usage général",value:"2829B"},
{libelle :"283 - Fab. machines agricoles et forestières",value:"283"},
{libelle :"2830Z - Fab. machines agricoles et forestières",value:"2830Z"},
{libelle :"284 - Fab. mach. formage métaux & mach.-outil",value:"284"},
{libelle :"2841Z - Fab. de machines de formage des métaux",value:"2841Z"},
{libelle :"2849Z - Fabrication d'autres machines-outils",value:"2849Z"},
{libelle :"289 - Fabric. autre machine d'usage spécifique",value:"289"},
{libelle :"2891Z - Fabric. de machines pour la métallurgie",value:"2891Z"},
{libelle :"2892Z - Fab. machine pour extraction ou constr.",value:"2892Z"},
{libelle :"2893Z - Fab. machine pour l'indus. agro-aliment.",value:"2893Z"},
{libelle :"2894Z - Fab. machine pour industries textiles",value:"2894Z"},
{libelle :"2895Z - Fab. machine pr indus. papier & carton",value:"2895Z"},
{libelle :"2896Z - Fab. machine pr trav. du caoutch, plast.",value:"2896Z"},
{libelle :"2899 - Fab. autre machine usage spécifi. n.c.a.",value:"2899"},
{libelle :"2899A - Fabrication de machines d'imprimerie",value:"2899A"},
{libelle :"2899B - Fabric. d'autres machines spécialisées",value:"2899B"},
{libelle :"29 - Industrie automobile",value:"29"},
{libelle :"291 - Construction de véhicules automobiles",value:"291"},
{libelle :"2910Z - Construction de véhicules automobiles",value:"2910Z"},
{libelle :"292 - Fabrication de carrosseries et remorques",value:"292"},
{libelle :"2920Z - Fabrication de carrosseries et remorques",value:"2920Z"},
{libelle :"293 - Fabrication d'équipements automobiles",value:"293"},
{libelle :"2931Z - Fab. équipt électriq. & électron. auto.",value:"2931Z"},
{libelle :"2932Z - Fabric. d'autres équipements automobiles",value:"2932Z"},
{libelle :"30 - Fabric. d'autres matériels de transport",value:"30"},
{libelle :"301 - Construction navale",value:"301"},
{libelle :"3011Z - Construct. navires & structure flottante",value:"3011Z"},
{libelle :"3012Z - Construction de bateaux de plaisance",value:"3012Z"},
{libelle :"302 - Const. loco. & autre mat. ferro. roulant",value:"302"},
{libelle :"3020Z - Const. loco. & autre mat. ferro. roulant",value:"3020Z"},
{libelle :"303 - Construction aéronautique et spatiale",value:"303"},
{libelle :"3030Z - Construction aéronautique et spatiale",value:"3030Z"},
{libelle :"304 - Constr. véhicules militaires de combat",value:"304"},
{libelle :"3040Z - Constr. véhicules militaires de combat",value:"3040Z"},
{libelle :"309 - Fabric. de matériels de transport n.c.a.",value:"309"},
{libelle :"3091Z - Fabrication de motocycles",value:"3091Z"},
{libelle :"3092Z - Fab. bicyclette & véhic. pour invalides",value:"3092Z"},
{libelle :"3099Z - Fab. aut. équipement de transport n.c.a.",value:"3099Z"},
{libelle :"31 - Fabrication de meubles",value:"31"},
{libelle :"310 - Fabrication de meubles",value:"310"},
{libelle :"3101Z - Fab. de meubles de bureau et de magasin",value:"3101Z"},
{libelle :"3102Z - Fabrication de meubles de cuisine",value:"3102Z"},
{libelle :"3103Z - Fabrication de matelas",value:"3103Z"},
{libelle :"3109 - Fabrication d'autres meubles",value:"3109"},
{libelle :"3109A - Fabric. sièges d'ameublement d'intérieur",value:"3109A"},
{libelle :"3109B - Fab. aut. meub. & ind. connexe ameublmnt",value:"3109B"},
{libelle :"32 - Autres industries manufacturières",value:"32"},
{libelle :"321 - Fab. artic. joaillerie, bijout. & simil.",value:"321"},
{libelle :"3211Z - Frappe de monnaie",value:"3211Z"},
{libelle :"3212Z - Fab. article de joaillerie et bijouterie",value:"3212Z"},
{libelle :"3213Z - Fab. art. bijout. fantaisie & similaire",value:"3213Z"},
{libelle :"322 - Fabrication d'instruments de musique",value:"322"},
{libelle :"3220Z - Fabrication d'instruments de musique",value:"3220Z"},
{libelle :"323 - Fabrication d'articles de sport",value:"323"},
{libelle :"3230Z - Fabrication d'articles de sport",value:"3230Z"},
{libelle :"324 - Fabrication de jeux et jouets",value:"324"},
{libelle :"3240Z - Fabrication de jeux et jouets",value:"3240Z"},
{libelle :"325 - Fab. inst. & fournit. usage méd. & dent.",value:"325"},
{libelle :"3250 - Fab. inst. & fournit. usage méd. & dent.",value:"3250"},
{libelle :"3250A - Fab. matériel médico-chirurg. & dentaire",value:"3250A"},
{libelle :"3250B - Fabrication de lunettes",value:"3250B"},
{libelle :"329 - Activités manufacturières n.c.a.",value:"329"},
{libelle :"3291Z - Fabrication d'articles de brosserie",value:"3291Z"},
{libelle :"3299Z - Autres activités manufacturières n.c.a.",value:"3299Z"},
{libelle :"33 - Réparation & install. machine & équipt",value:"33"},
{libelle :"331 - Répar. ouvrage en métaux, mach. & équipt",value:"331"},
{libelle :"3311Z - Réparation d'ouvrages en métaux",value:"3311Z"},
{libelle :"3312Z - Répar. machine & équipement mécaniques",value:"3312Z"},
{libelle :"3313Z - Répar. matériel électronique & optique",value:"3313Z"},
{libelle :"3314Z - Réparation d'équipements électriques",value:"3314Z"},
{libelle :"3315Z - Réparation et maintenance navale",value:"3315Z"},
{libelle :"3316Z - Répar. & maint. aéronef & eng. spatiaux",value:"3316Z"},
{libelle :"3317Z - Répar. & maint. d'aut. équipt transport",value:"3317Z"},
{libelle :"3319Z - Réparation d'autres équipements",value:"3319Z"},
{libelle :"332 - Install. de machines & équipt industriel",value:"332"},
{libelle :"3320 - Install. de machines & équipt industriel",value:"3320"},
{libelle :"3320A - Instal. struct. métal., chaudr. & tuyau.",value:"3320A"},
{libelle :"3320B - Instal. machines & équipement mécanique",value:"3320B"},
{libelle :"3320C - Instal. éqpts ctrle des processus indus.",value:"3320C"},
{libelle :"3320D - Inst. éqpt élec. électro. optiq. ou aut.",value:"3320D"},
{libelle :"D - Prdn & distr. élec. gaz vap. & air cond.",value:"D"},
{libelle :"35 - Prdn & distr. élec. gaz vap. & air cond.",value:"35"},
{libelle :"351 - Prod., transport & distrib. électricité",value:"351"},
{libelle :"3511Z - Production d'électricité",value:"3511Z"},
{libelle :"3512Z - Transport d'électricité",value:"3512Z"},
{libelle :"3513Z - Distribution d'électricité",value:"3513Z"},
{libelle :"3514Z - Commerce d'électricité",value:"3514Z"},
{libelle :"352 - Prod. & distrib. de combustibles gazeux",value:"352"},
{libelle :"3521Z - Production de combustibles gazeux",value:"3521Z"},
{libelle :"3522Z - Distrib. combustible gazeux pr conduites",value:"3522Z"},
{libelle :"3523Z - Commerce combustible gazeux par conduite",value:"3523Z"},
{libelle :"353 - Prod. & distrib. vapeur et air condit.",value:"353"},
{libelle :"3530Z - Prod. & distrib. vapeur et air condit.",value:"3530Z"},
{libelle :"E - Gestion eau, déchets & dépollution",value:"E"},
{libelle :"36 - Captage, traitement & distribution d'eau",value:"36"},
{libelle :"360 - Captage, traitement & distribution d'eau",value:"360"},
{libelle :"3600Z - Captage, traitement & distribution d'eau",value:"3600Z"},
{libelle :"37 - Collecte et traitement des eaux usées",value:"37"},
{libelle :"370 - Collecte et traitement des eaux usées",value:"370"},
{libelle :"3700Z - Collecte et traitement des eaux usées",value:"3700Z"},
{libelle :"38 - Collecte, gestion déchets ; récupération",value:"38"},
{libelle :"381 - Collecte des déchets",value:"381"},
{libelle :"3811Z - Collecte des déchets non dangereux",value:"3811Z"},
{libelle :"3812Z - Collecte des déchets dangereux",value:"3812Z"},
{libelle :"382 - Traitement et élimination des déchets",value:"382"},
{libelle :"3821Z - Traitmnt & élimin. déchets non dangereux",value:"3821Z"},
{libelle :"3822Z - Traitmnt & élimination déchets dangereux",value:"3822Z"},
{libelle :"383 - Récupération",value:"383"},
{libelle :"3831Z - Démantèlement d'épaves",value:"3831Z"},
{libelle :"3832Z - Récupération de déchets triés",value:"3832Z"},
{libelle :"39 - Dépollution & autre sces gestion déchets",value:"39"},
{libelle :"390 - Dépollution & autre sces gestion déchets",value:"390"},
{libelle :"3900Z - Dépollution & autre sces gestion déchets",value:"3900Z"},
{libelle :"F - CONSTRUCTION",value:"F"},
{libelle :"41 - Construction de bâtiments",value:"41"},
{libelle :"411 - Promotion immobilière",value:"411"},
{libelle :"4110 - Promotion immobilière",value:"4110"},
{libelle :"4110A - Promotion immobilière de logements",value:"4110A"},
{libelle :"4110B - Promotion immobilière de bureaux",value:"4110B"},
{libelle :"4110C - Promotion immobilière d'autres bâtiments",value:"4110C"},
{libelle :"4110D - Supports juridiques de programmes",value:"4110D"},
{libelle :"412 - Construc. bâtimnt résid. & non résident.",value:"412"},
{libelle :"4120 - Construc. bâtimnt résid. & non résident.",value:"4120"},
{libelle :"4120A - Construction de maisons individuelles",value:"4120A"},
{libelle :"4120B - Construction d'autres bâtiments",value:"4120B"},
{libelle :"42 - Génie civil",value:"42"},
{libelle :"421 - Construction de route & de voies ferrées",value:"421"},
{libelle :"4211Z - Construction de routes et autoroutes",value:"4211Z"},
{libelle :"4212Z - Const. voie ferrée surface & souterraine",value:"4212Z"},
{libelle :"4213 - Construction de ponts et tunnels",value:"4213"},
{libelle :"4213A - Construction d'ouvrages d'art",value:"4213A"},
{libelle :"4213B - Construction et entretien de tunnels",value:"4213B"},
{libelle :"422 - Construction de réseaux et de lignes",value:"422"},
{libelle :"4221Z - Construction de réseaux pour fluides",value:"4221Z"},
{libelle :"4222Z - Const. réseaux électriq. & de télécom.",value:"4222Z"},
{libelle :"429 - Construc. autres ouvrages de génie civil",value:"429"},
{libelle :"4291Z - Construc. ouvrages maritimes et fluviaux",value:"4291Z"},
{libelle :"4299Z - Constr. aut. ouvrage de génie civil nca.",value:"4299Z"},
{libelle :"43 - Travaux de construction spécialisés",value:"43"},
{libelle :"431 - Démolition et préparation des sites",value:"431"},
{libelle :"4311Z - Travaux de démolition",value:"4311Z"},
{libelle :"4312 - Travaux de préparation des sites",value:"4312"},
{libelle :"4312A - Travaux de terrassement courants",value:"4312A"},
{libelle :"4312B - Travaux de terrassement spécialisés",value:"4312B"},
{libelle :"4313Z - Forages et sondages",value:"4313Z"},
{libelle :"432 - Travaux install. élect. plomberie & aut.",value:"432"},
{libelle :"4321 - Installation électrique",value:"4321"},
{libelle :"4321A - Travaux instal. électriq. ds tous locaux",value:"4321A"},
{libelle :"4321B - Travaux instal. électriq. sr voie publi.",value:"4321B"},
{libelle :"4322 - Trav. plomberie chauffage & condit. air",value:"4322"},
{libelle :"4322A - Travaux instal. eau & gaz en tous locaux",value:"4322A"},
{libelle :"4322B - Travaux instal. équipt thermique & clim.",value:"4322B"},
{libelle :"4329 - Autres travaux d'installation",value:"4329"},
{libelle :"4329A - Travaux d'isolation",value:"4329A"},
{libelle :"4329B - Autres travaux d'installation n.c.a.",value:"4329B"},
{libelle :"433 - Travaux de finition",value:"433"},
{libelle :"4331Z - Travaux de plâtrerie",value:"4331Z"},
{libelle :"4332 - Travaux de menuiserie",value:"4332"},
{libelle :"4332A - Travaux de menuiserie bois et PVC",value:"4332A"},
{libelle :"4332B - Travaux menuiserie métal. & serrurerie",value:"4332B"},
{libelle :"4332C - Agencement de lieux de vente",value:"4332C"},
{libelle :"4333Z - Travaux revêtement des sols et des murs",value:"4333Z"},
{libelle :"4334Z - Travaux de peinture et vitrerie",value:"4334Z"},
{libelle :"4339Z - Autres travaux de finition",value:"4339Z"},
{libelle :"439 - Autres travaux construction spécialisés",value:"439"},
{libelle :"4391 - Travaux de couverture",value:"4391"},
{libelle :"4391A - Travaux de charpente",value:"4391A"},
{libelle :"4391B - Travaux de couverture par éléments",value:"4391B"},
{libelle :"4399 - Aut. travaux construc. spécialisés nca.",value:"4399"},
{libelle :"4399A - Travaux d'étanchéification",value:"4399A"},
{libelle :"4399B - Travaux montage de structure métallique",value:"4399B"},
{libelle :"4399C - Trav. maçon. gle & gros oeuvre bâtiment",value:"4399C"},
{libelle :"4399D - Aut. travaux spécialisés de construction",value:"4399D"},
{libelle :"4399E - Location avec opérateur mat. de constr.",value:"4399E"},
{libelle :"GI - Commerce, transp., hébergemnt & restau. ",value:"GI"},
{libelle :"G - COMMERCE ; RÉPAR. AUTOMOBILE & MOTOCYCLE",value:"G"},
{libelle :"45 - Commerce & répar. automobile & motocycle",value:"45"},
{libelle :"451 - Commerce de véhicules automobiles",value:"451"},
{libelle :"4511Z - Comm. de voiture & véhicule auto. léger",value:"4511Z"},
{libelle :"4519Z - Commerce d'autres véhicules automobiles",value:"4519Z"},
{libelle :"452 - Entretien & répar. de véhicule auto.",value:"452"},
{libelle :"4520 - Entretien & répar. de véhicule auto.",value:"4520"},
{libelle :"4520A - Entretien & répar. véhicule auto. léger",value:"4520A"},
{libelle :"4520B - Entretien & répar. autre véhicule auto.",value:"4520B"},
{libelle :"453 - Commerce d'équipements automobiles",value:"453"},
{libelle :"4531Z - Commerce de gros d'équipement automobile",value:"4531Z"},
{libelle :"4532Z - Commerce de détail équipement automobile",value:"4532Z"},
{libelle :"454 - Commerce et réparation de motocycles",value:"454"},
{libelle :"4540Z - Commerce et réparation de motocycles",value:"4540Z"},
{libelle :"46 - Commerce gros hors auto. & motocycle",value:"46"},
{libelle :"461 - Intermédiaires du commerce de gros",value:"461"},
{libelle :"4611Z - Interm. du comm. en produits agricoles",value:"4611Z"},
{libelle :"4612 - Int. comm. comb. mét. minér. & pd. chim.",value:"4612"},
{libelle :"4612A - Centrales d'achat de carburant",value:"4612A"},
{libelle :"4612B - Aut. ic comb. mét. minér. & prod. chim.",value:"4612B"},
{libelle :"4613Z - Interm. comm. bois & matériaux construc.",value:"4613Z"},
{libelle :"4614Z - Int. comm. équipt indus., navire & avion",value:"4614Z"},
{libelle :"4615Z - Int. comm. meuble, art. ménage & quinc.",value:"4615Z"},
{libelle :"4616Z - Int. comm. textile, habillt & assimil.",value:"4616Z"},
{libelle :"4617 - Int. comm. en denrées, boissons & tabac",value:"4617"},
{libelle :"4617A - Centrales d'achat alimentaires",value:"4617A"},
{libelle :"4617B - Autre ic en denrées, boissons et tabac",value:"4617B"},
{libelle :"4618Z - Int. spécialis. comm. aut. prod. spécif.",value:"4618Z"},
{libelle :"4619 - Interm. du commerce en produits divers",value:"4619"},
{libelle :"4619A - Centrales d'achat non alimentaires",value:"4619A"},
{libelle :"4619B - Autre interm. commerce en prodts divers",value:"4619B"},
{libelle :"462 - Com. gros prod. agric. brut & anim. viv.",value:"462"},
{libelle :"4621Z - Com gros céréal. tab. brt & alim. bétail",value:"4621Z"},
{libelle :"4622Z - Commerce de gros de fleurs et plantes",value:"4622Z"},
{libelle :"4623Z - Commerce de gros d'animaux vivants",value:"4623Z"},
{libelle :"4624Z - Commerce de gros de cuirs et peaux",value:"4624Z"},
{libelle :"463 - Comm. gros prod. alim. boisson & tabac",value:"463"},
{libelle :"4631Z - Commerce de gros de fruits et légumes",value:"4631Z"},
{libelle :"4632 - Comm. gros viande & prod. à base  viande",value:"4632"},
{libelle :"4632A - Commerce de gros de viandes de boucherie",value:"4632A"},
{libelle :"4632B - Comm. gros de produits à base de viande",value:"4632B"},
{libelle :"4632C - Commerce de gros de volailles et gibier",value:"4632C"},
{libelle :"4633Z - Com. gros prod. laitier oeuf & mat. grse",value:"4633Z"},
{libelle :"4634Z - Commerce de gros  de boissons",value:"4634Z"},
{libelle :"4635Z - Comm. gros de produits à base de tabac",value:"4635Z"},
{libelle :"4636Z - Com. gros de sucre chocolat & confiserie",value:"4636Z"},
{libelle :"4637Z - Comm. gros de café, thé, cacao et épices",value:"4637Z"},
{libelle :"4638A - Com. gros aut. alim. yc poisson crustacé",value:"4638A"},
{libelle :"4638B - Comm. gros alimentaire spécialisé divers",value:"4638B"},
{libelle :"4639 - Com. gros non spéc. denrée boisson tabac",value:"4639"},
{libelle :"4639A - Commerce de gros de produits surgelés",value:"4639A"},
{libelle :"4639B - Comm de gros alimentaire non spécialisé",value:"4639B"},
{libelle :"464 - Commerce de gros de biens domestiques",value:"464"},
{libelle :"4641Z - Commerce de gros de textiles",value:"4641Z"},
{libelle :"4642Z - Commerce gros d'habillement & chaussures",value:"4642Z"},
{libelle :"4643Z - Commerce de gros appareil électroménager",value:"4643Z"},
{libelle :"4644Z - Com. gros vaisselle verrerie prod. entr.",value:"4644Z"},
{libelle :"4645Z - Com. gros parfumerie & produit de beauté",value:"4645Z"},
{libelle :"4646Z - Comm. gros de produits pharmaceutiques",value:"4646Z"},
{libelle :"4647Z - Com. gros meuble tapis appareil éclaira.",value:"4647Z"},
{libelle :"4648Z - Com. gros artic. horlogerie & bijouterie",value:"4648Z"},
{libelle :"4649Z - Commerce gros d'autres biens domestiques",value:"4649Z"},
{libelle :"465 - Comm. gros équipt. information & com.",value:"465"},
{libelle :"4651Z - Comm. gros ordi. éqpt périph. & logiciel",value:"4651Z"},
{libelle :"4652Z - Cg éqpt & composant électron. & télécom.",value:"4652Z"},
{libelle :"466 - Commerce de gros autres équipts indust.",value:"466"},
{libelle :"4661Z - Commerce de gros de matériel agricole",value:"4661Z"},
{libelle :"4662Z - Commerce de gros de machines-outils",value:"4662Z"},
{libelle :"4663Z - Com. gros machine pr extrac., constr. GC",value:"4663Z"},
{libelle :"4664Z - Com. gros machine pr ind. text. & habil.",value:"4664Z"},
{libelle :"4665Z - Commerce de gros de mobilier de bureau",value:"4665Z"},
{libelle :"4666Z - Com. gros autre machine & équipt bureau",value:"4666Z"},
{libelle :"4669 - Commerce de gros autre machine & équipt",value:"4669"},
{libelle :"4669A - Commerce de gros de matériel électrique",value:"4669A"},
{libelle :"4669B - Com. gros fourniture & équipt ind. div.",value:"4669B"},
{libelle :"4669C - Cg fournit. & équipt div. pr com. & sces",value:"4669C"},
{libelle :"467 - Autres commerces de gros spécialisés",value:"467"},
{libelle :"4671Z - Com. gros combustible & produits annexes",value:"4671Z"},
{libelle :"4672Z - Commerce de gros de minerais et métaux",value:"4672Z"},
{libelle :"4673 - Cg bois, mat. construc. & app. sanitaire",value:"4673"},
{libelle :"4673A - Com. gros bois & matériaux construction",value:"4673A"},
{libelle :"4673B - Cg appareil sanitaire & prod. décoration",value:"4673B"},
{libelle :"4674 - Cg quincail. & fournit. plombr. & chauf.",value:"4674"},
{libelle :"4674A - Commerce de gros de quincaillerie",value:"4674A"},
{libelle :"4674B - Cg fourniture pour plomberie & chauffage",value:"4674B"},
{libelle :"4675Z - Commerce de gros de produits chimiques",value:"4675Z"},
{libelle :"4676Z - Commerce gros d'aut. prod. intermédiaire",value:"4676Z"},
{libelle :"4677Z - Commerce de gros de déchets et débris",value:"4677Z"},
{libelle :"469 - Commerce de gros non spécialisé",value:"469"},
{libelle :"4690Z - Commerce de gros non spécialisé",value:"4690Z"},
{libelle :"47 - Com. détail, sf automobiles & motocycles",value:"47"},
{libelle :"471 - Comm. détail en magasin non spécialisé",value:"471"},
{libelle :"4711 - Comm. dét. mag. non spéc. à prédo. alim.",value:"4711"},
{libelle :"4711A - Commerce de détail de produits surgelés",value:"4711A"},
{libelle :"4711B - Commerce d'alimentation générale",value:"4711B"},
{libelle :"4711C - Supérettes",value:"4711C"},
{libelle :"4711D - Supermarchés",value:"4711D"},
{libelle :"4711E - Magasins multi-commerces",value:"4711E"},
{libelle :"4711F - Hypermarchés",value:"4711F"},
{libelle :"4719 - Autre comm. détail en magasin non spéc.",value:"4719"},
{libelle :"4719A - Grands magasins",value:"4719A"},
{libelle :"4719B - Autres comm. détail en magasin non spéc.",value:"4719B"},
{libelle :"472 - Com. dét. aliment. en magasin spécialisé",value:"472"},
{libelle :"4721Z - Com. détail fruit & légume en mag. spéc.",value:"4721Z"},
{libelle :"4722Z - Com. dét. viande & prdt avec viande (ms)",value:"4722Z"},
{libelle :"4723Z - Comm. détail poisson crustacé etc. (ms)",value:"4723Z"},
{libelle :"4724Z - Comm. dét. pain pâtiss. & confiser. (ms)",value:"4724Z"},
{libelle :"4725Z - Com. détail boisson en magasin spéciali.",value:"4725Z"},
{libelle :"4726Z - Comm. dét. produit à base de tabac (ms)",value:"4726Z"},
{libelle :"4729Z - Aut. com. détail alim. en mag. spéciali.",value:"4729Z"},
{libelle :"473 - Comm. détail carburant en mag. spéciali.",value:"473"},
{libelle :"4730Z - Comm. détail carburant en mag. spéciali.",value:"4730Z"},
{libelle :"474 - Com. dét. équipt information & com. (ms)",value:"474"},
{libelle :"4741Z - Com. dét ordi. un. périph. & logicl (ms)",value:"4741Z"},
{libelle :"4742Z - Comm. dét. matériel télécom. (ms)",value:"4742Z"},
{libelle :"4743Z - Comm. dét. matériels audio/vidéo (ms)   ",value:"4743Z"},
{libelle :"475 - Com. dét. aut. éqpt du foyer (mag. spé.)",value:"475"},
{libelle :"4751Z - Com. dét. textiles en magasin spécialisé",value:"4751Z"},
{libelle :"4752 - Comm. dét. quinca. peinture & verre (ms)",value:"4752"},
{libelle :"4752A - Com. dét. quinc. pein. etc. (mag.<400m2)",value:"4752A"},
{libelle :"4752B - Com. dét. quinc. pein. etc. (mag.>400m2)",value:"4752B"},
{libelle :"4753Z - Cd tapis moquette & revêt. mur sol (ms)",value:"4753Z"},
{libelle :"4754Z - Comm. dét.  appareil électroménager (ms)",value:"4754Z"},
{libelle :"4759 - Com. dét. meub. éclair. & aut. art. mén.",value:"4759"},
{libelle :"4759A - Commerce de détail de meubles",value:"4759A"},
{libelle :"4759B - Comm. détail autres équipements du foyer",value:"4759B"},
{libelle :"476 - Comm. dét. biens culturels & loisir (ms)",value:"476"},
{libelle :"4761Z - Comm. dét. livres en magasin spécialisé",value:"4761Z"},
{libelle :"4762Z - Comm. détail journaux & papeterie (ms)",value:"4762Z"},
{libelle :"4763Z - Com. dét. enreg. musicaux & vidéo (ms)",value:"4763Z"},
{libelle :"4764Z - Com. dét. articles de sport en mag. spé.",value:"4764Z"},
{libelle :"4765Z - Com. dét. jeux & jouets en mag. spécial.",value:"4765Z"},
{libelle :"477 - Autre comm. détail en magasin spécialisé",value:"477"},
{libelle :"4771Z - Com. dét. habillement en mag. spécialisé",value:"4771Z"},
{libelle :"4772 - Comm. dét. chaussure & article cuir (ms)",value:"4772"},
{libelle :"4772A - Commerce de détail de la chaussure",value:"4772A"},
{libelle :"4772B - Com. dét. maroquinerie & article  voyage",value:"4772B"},
{libelle :"4773Z - Comm. dét. produits pharmaceutiques (ms)",value:"4773Z"},
{libelle :"4774Z - Com. dét. art. médicaux & orthopéd. (ms)",value:"4774Z"},
{libelle :"4775Z - Com. dét. parfumerie & prodt beauté (ms)",value:"4775Z"},
{libelle :"4776Z - Com. dét. fleur plante anim. cie + alim.",value:"4776Z"},
{libelle :"4777Z - Com. dét. art. horlogerie & bijout. (ms)",value:"4777Z"},
{libelle :"4778 - Aut. comm. dét. biens neufs en mag. spé.",value:"4778"},
{libelle :"4778A - Commerces de détail d'optique",value:"4778A"},
{libelle :"4778B - Comm. détail de charbons & combustibles",value:"4778B"},
{libelle :"4778C - Autre commerce détail spécialisé divers",value:"4778C"},
{libelle :"4779Z - Comm. détail biens d'occasion en magasin",value:"4779Z"},
{libelle :"478 - Commerce détail sur éventaires & marchés",value:"478"},
{libelle :"4781Z - Cd alimentaire sur éventaire & marché",value:"4781Z"},
{libelle :"4782Z - Cd textiles habillt & chauss. s/marchés",value:"4782Z"},
{libelle :"4789Z - Aut. com. dét. sur éventaires & marchés",value:"4789Z"},
{libelle :"479 - Com. dét. hors mag. éventair. ou marchés",value:"479"},
{libelle :"4791 - Vente à distance",value:"4791"},
{libelle :"4791A - Vente à distance sur catalogue général",value:"4791A"},
{libelle :"4791B - Vente à distance sur catalogue spécialis",value:"4791B"},
{libelle :"4799 - Aut. comm. dét. hors mag. évent., marché",value:"4799"},
{libelle :"4799A - Vente à domicile",value:"4799A"},
{libelle :"4799B - Vente par automate, aut. cd hors magasin",value:"4799B"},
{libelle :"H - TRANSPORTS ET ENTREPOSAGE",value:"H"},
{libelle :"49 - Transport terrest. & trans. par conduite",value:"49"},
{libelle :"491 - Transport ferrov. interurbain voyageur",value:"491"},
{libelle :"4910 - Transport ferrov. interurbain voyageur",value:"4910"},
{libelle :"4910Z - Transport ferrov. interurbain voyageur",value:"4910Z"},
{libelle :"492 - Transports ferroviaires de fret",value:"492"},
{libelle :"4920Z - Transports ferroviaires de fret",value:"4920Z"},
{libelle :"493 - Autres transports terrestres de voyageur",value:"493"},
{libelle :"4931Z - Transport urbain & suburbain de voyageur",value:"4931Z"},
{libelle :"4932Z - Transports de voyageurs par taxis",value:"4932Z"},
{libelle :"4939 - Aut. transport terrestre voyageurs nca.",value:"4939"},
{libelle :"4939A - Transport routier régulier de voyageurs",value:"4939A"},
{libelle :"4939B - Autres transports routiers de voyageurs",value:"4939B"},
{libelle :"4939C - Téléphériques et remontées mécaniques",value:"4939C"},
{libelle :"494 - Transport routier de fret & sce déménagt",value:"494"},
{libelle :"4941 - Transports routiers de fret",value:"4941"},
{libelle :"4941A - Transports routiers de fret interurbains",value:"4941A"},
{libelle :"4941B - Transports routiers de fret de proximité",value:"4941B"},
{libelle :"4941C - Location de camions avec chauffeur",value:"4941C"},
{libelle :"4942Z - Services de déménagement",value:"4942Z"},
{libelle :"495 - Transports par conduites",value:"495"},
{libelle :"4950Z - Transports par conduites",value:"4950Z"},
{libelle :"50 - Transports par eau",value:"50"},
{libelle :"501 - Transport maritime & côtier de passagers",value:"501"},
{libelle :"5010Z - Transport maritime & côtier de passagers",value:"5010Z"},
{libelle :"502 - Transports maritimes et côtiers de fret",value:"502"},
{libelle :"5020Z - Transports maritimes et côtiers de fret",value:"5020Z"},
{libelle :"503 - Transports fluviaux de passagers",value:"503"},
{libelle :"5030Z - Transports fluviaux de passagers",value:"5030Z"},
{libelle :"504 - Transports fluviaux de fret",value:"504"},
{libelle :"5040Z - Transports fluviaux de fret",value:"5040Z"},
{libelle :"51 - Transports aériens",value:"51"},
{libelle :"511 - Transports aériens de passagers",value:"511"},
{libelle :"5110Z - Transports aériens de passagers",value:"5110Z"},
{libelle :"512 - Transp. aérien de fret & trans. spatiaux",value:"512"},
{libelle :"5121Z - Transports aériens de fret",value:"5121Z"},
{libelle :"5122Z - Transports spatiaux",value:"5122Z"},
{libelle :"52 - Entreposage & sce auxiliaire des transp.",value:"52"},
{libelle :"521 - Entreposage et stockage",value:"521"},
{libelle :"5210 - Entreposage et stockage",value:"5210"},
{libelle :"5210A - Entreposage et stockage frigorifique",value:"5210A"},
{libelle :"5210B - Entreposage et stockage non frigorifique",value:"5210B"},
{libelle :"522 - Services auxiliaires des transports",value:"522"},
{libelle :"5221Z - Sces auxiliaires de transport terrestre",value:"5221Z"},
{libelle :"5222Z - Sces auxiliaires des transports par eau",value:"5222Z"},
{libelle :"5223Z - Sces auxiliaires des transports aériens",value:"5223Z"},
{libelle :"5224 - Manutention",value:"5224"},
{libelle :"5224A - Manutention portuaire",value:"5224A"},
{libelle :"5224B - Manutention non portuaire",value:"5224B"},
{libelle :"5229 - Autre service auxiliaires des transports",value:"5229"},
{libelle :"5229A - Messagerie, fret express",value:"5229A"},
{libelle :"5229B - Affrètement & organisation des transp.",value:"5229B"},
{libelle :"53 - Activités de poste et de courrier",value:"53"},
{libelle :"531 - Activ. poste (obligation sce universel)",value:"531"},
{libelle :"5310Z - Activ. poste (obligation sce universel)",value:"5310Z"},
{libelle :"532 - Autres activités de poste et de courrier",value:"532"},
{libelle :"5320Z - Autres activités de poste et de courrier",value:"5320Z"},
{libelle :"I - HÉBERGEMENT ET RESTAURATION",value:"I"},
{libelle :"55 - Hébergement",value:"55"},
{libelle :"551 - Hôtels et hébergement similaire",value:"551"},
{libelle :"5510Z - Hôtels et hébergement similaire",value:"5510Z"},
{libelle :"552 - Hébergt tourist. & aut. hbt courte durée",value:"552"},
{libelle :"5520Z - Hébergt tourist. & aut. hbt courte durée",value:"5520Z"},
{libelle :"553 - Terrain camping & parc pr caravane etc.",value:"553"},
{libelle :"5530Z - Terrain camping & parc pr caravane etc.",value:"5530Z"},
{libelle :"559 - Autres hébergements",value:"559"},
{libelle :"5590Z - Autres hébergements",value:"5590Z"},
{libelle :"56 - Restauration",value:"56"},
{libelle :"561 - Restaurant & serv. de restaurat. mobile",value:"561"},
{libelle :"5610 - Restaurant & serv. de restaurat. mobile",value:"5610"},
{libelle :"5610A - Restauration traditionnelle",value:"5610A"},
{libelle :"5610B - Cafétérias et autres libres-services",value:"5610B"},
{libelle :"5610C - Restauration de type rapide",value:"5610C"},
{libelle :"562 - Traiteur & autre service de restauration",value:"562"},
{libelle :"5621Z - Services des traiteurs",value:"5621Z"},
{libelle :"5629 - Autres services de restauration",value:"5629"},
{libelle :"5629A - Restauration collective sous contrat",value:"5629A"},
{libelle :"5629B - Autres services de restauration n.c.a.",value:"5629B"},
{libelle :"563 - Débits de boissons",value:"563"},
{libelle :"5630Z - Débits de boissons",value:"5630Z"},
{libelle :"J - INFORMATION ET COMMUNICATION",value:"J"},
{libelle :"58 - Édition",value:"58"},
{libelle :"581 - Édition livre & périodiq. & aut. édition",value:"581"},
{libelle :"5811Z - Édition de livres",value:"5811Z"},
{libelle :"5812Z - Édition répertoires & fichiers d'adresse",value:"5812Z"},
{libelle :"5813Z - Édition de journaux",value:"5813Z"},
{libelle :"5814Z - Édition de revues et périodiques",value:"5814Z"},
{libelle :"5819Z - Autres activités d'édition",value:"5819Z"},
{libelle :"582 - Édition de logiciels",value:"582"},
{libelle :"5821Z - Édition de jeux électroniques",value:"5821Z"},
{libelle :"5829 - Édition d'autres logiciels",value:"5829"},
{libelle :"5829A - Édition de logiciel système et de réseau",value:"5829A"},
{libelle :"5829B - Edit. logiciel outil dévelop. & langage",value:"5829B"},
{libelle :"5829C - Edition de logiciels applicatifs",value:"5829C"},
{libelle :"59 - Prod. films ; enr. sonore & éd. musicale",value:"59"},
{libelle :"591 - Activ. cinématogra., vidéo & télévision",value:"591"},
{libelle :"5911 - Prod. film cinémato. vidéo & pgm. télé.",value:"5911"},
{libelle :"5911A - Prod. film & progm. pour la télévision",value:"5911A"},
{libelle :"5911B - Prod. film institutionnel & publicitaire",value:"5911B"},
{libelle :"5911C - Production de films pour le cinéma",value:"5911C"},
{libelle :"5912Z - Post-production film & prog. télévision",value:"5912Z"},
{libelle :"5913 - Distribution film vidéo & pgm. télé.",value:"5913"},
{libelle :"5913A - Distribution de films cinématographiques",value:"5913A"},
{libelle :"5913B - Edition et distribution vidéo",value:"5913B"},
{libelle :"5914Z - Projection de films cinématographiques",value:"5914Z"},
{libelle :"592 - Enregistrement sonore & édition musicale",value:"592"},
{libelle :"5920Z - Enregistrement sonore & édition musicale",value:"5920Z"},
{libelle :"60 - Programmation et diffusion",value:"60"},
{libelle :"601 - Édition et diffusion de programmes radio",value:"601"},
{libelle :"6010Z - Édition et diffusion de programmes radio",value:"6010Z"},
{libelle :"602 - Programmation de télévision & télédiff.",value:"602"},
{libelle :"6020 - Programmation de télévision & télédiff.",value:"6020"},
{libelle :"6020A - Edition de chaînes généralistes",value:"6020A"},
{libelle :"6020B - Edition de chaînes thématiques",value:"6020B"},
{libelle :"61 - Télécommunications",value:"61"},
{libelle :"611 - Télécommunications filaires",value:"611"},
{libelle :"6110Z - Télécommunications filaires",value:"6110Z"},
{libelle :"612 - Télécommunications sans fil",value:"612"},
{libelle :"6120Z - Télécommunications sans fil",value:"6120Z"},
{libelle :"613 - Télécommunications par satellite",value:"613"},
{libelle :"6130Z - Télécommunications par satellite",value:"6130Z"},
{libelle :"619 - Autres activités de télécommunication",value:"619"},
{libelle :"6190Z - Autres activités de télécommunication",value:"6190Z"},
{libelle :"62 - Pgmtion conseil & aut. act. informatique",value:"62"},
{libelle :"620 - Pgmtion conseil & aut. act. informatique",value:"620"},
{libelle :"6201Z - Programmation informatique",value:"6201Z"},
{libelle :"6202 - Conseil informatique",value:"6202"},
{libelle :"6202A - Conseil en système & logiciel informati.",value:"6202A"},
{libelle :"6202B - Tierce mainten. syst. & appli. nformati.",value:"6202B"},
{libelle :"6203Z - Gestion d'installations informatiques",value:"6203Z"},
{libelle :"6209Z - Autres activités informatiques",value:"6209Z"},
{libelle :"63 - Services d'information",value:"63"},
{libelle :"631 - Trt donnée, hébrgt etc.; portail Internt",value:"631"},
{libelle :"6311Z - Traitt donnée, hébergt & activ. connexe",value:"6311Z"},
{libelle :"6312Z - Portails Internet",value:"6312Z"},
{libelle :"639 - Autres services d'information",value:"639"},
{libelle :"6391Z - Activités des agences de presse",value:"6391Z"},
{libelle :"6399Z - Autres services d'information n.c.a.",value:"6399Z"},
{libelle :"K - ACTIVITÉS FINANCIÈRES ET D'ASSURANCE",value:"K"},
{libelle :"64 - Act. financ. hs assur. & cais. retraite",value:"64"},
{libelle :"641 - Intermédiation monétaire",value:"641"},
{libelle :"6411Z - Activités de banque centrale",value:"6411Z"},
{libelle :"6419Z - Autres intermédiations monétaires",value:"6419Z"},
{libelle :"642 - Activités des sociétés holding",value:"642"},
{libelle :"6420Z - Activités des sociétés holding",value:"6420Z"},
{libelle :"643 - Fonds placement & entité financ. simil.",value:"643"},
{libelle :"6430Z - Fonds placement & entité financ. simil.",value:"6430Z"},
{libelle :"649 - Aut. act. finan. hs assur. & c. retrait.",value:"649"},
{libelle :"6491Z - Crédit-bail",value:"6491Z"},
{libelle :"6492Z - Autre distribution de crédit",value:"6492Z"},
{libelle :"6499Z - Aut. act. finan. hs as. & c. retra. nca.",value:"6499Z"},
{libelle :"65 - Assurance",value:"65"},
{libelle :"651 - Assurance",value:"651"},
{libelle :"6511Z - Assurance vie",value:"6511Z"},
{libelle :"6512Z - Autres assurances",value:"6512Z"},
{libelle :"652 - Réassurance",value:"652"},
{libelle :"6520Z - Réassurance",value:"6520Z"},
{libelle :"653 - Caisses de retraite",value:"653"},
{libelle :"6530Z - Caisses de retraite",value:"6530Z"},
{libelle :"66 - Act. auxiliaire sces financ. & d'assur.",value:"66"},
{libelle :"661 - Act. aux. sce financ. hs ass. & retraite",value:"661"},
{libelle :"6611Z - Administration de marchés financiers",value:"6611Z"},
{libelle :"6612Z - Courtage valeur mobilière & marchandise",value:"6612Z"},
{libelle :"6619 - Aut. act. aux. sce fin., hs ass. & retr.",value:"6619"},
{libelle :"6619A - Support juridiq. gest. patrimoine mobil.",value:"6619A"},
{libelle :"6619B - Aut. aux. sce financ. hs ass. retr. nca.",value:"6619B"},
{libelle :"662 - Act. auxil. assurance & caisse retraite",value:"662"},
{libelle :"6621Z - Évaluation des risques et dommages",value:"6621Z"},
{libelle :"6622Z - Act. des agents & courtiers d'assurances",value:"6622Z"},
{libelle :"6629Z - Aut. act. aux. assur. & caisse retraite",value:"6629Z"},
{libelle :"663 - Gestion de fonds",value:"663"},
{libelle :"6630Z - Gestion de fonds",value:"6630Z"},
{libelle :"L - ACTIVITÉS IMMOBILIÈRES",value:"L"},
{libelle :"68 - Activités immobilières",value:"68"},
{libelle :"681 - Activité marchands de biens immobiliers",value:"681"},
{libelle :"6810Z - Activité marchands de biens immobiliers",value:"6810Z"},
{libelle :"682 - Loc. & exploi. bien immo. propre ou loué",value:"682"},
{libelle :"6820 - Loc. & exploi. bien immo. propre ou loué",value:"6820"},
{libelle :"6820A - Location de logements",value:"6820A"},
{libelle :"6820B - Location terrain & autre bien immobilier",value:"6820B"},
{libelle :"683 - Activité immobilière pour cpte de tiers",value:"683"},
{libelle :"6831Z - Agences immobilières",value:"6831Z"},
{libelle :"6832 - Administration de biens immobiliers",value:"6832"},
{libelle :"6832A - Administrat. immeuble & autre bien immo.",value:"6832A"},
{libelle :"6832B - Support juridi. gestion patrimoine immo.",value:"6832B"},
{libelle :"MN - Ac., spé., sci. & tec., svces adm. & stn",value:"MN"},
{libelle :"M - ACT. SPÉCIALISÉE, SCIENTIFIQ. & TECHNIQ.",value:"M"},
{libelle :"69 - Activités juridiques et comptables",value:"69"},
{libelle :"691 - Activités juridiques",value:"691"},
{libelle :"6910Z - Activités juridiques",value:"6910Z"},
{libelle :"692 - Activités comptables",value:"692"},
{libelle :"6920Z - Activités comptables",value:"6920Z"},
{libelle :"70 - Act. sièges sociaux ; conseil de gestion",value:"70"},
{libelle :"701 - Activités des sièges sociaux",value:"701"},
{libelle :"7010Z - Activités des sièges sociaux",value:"7010Z"},
{libelle :"702 - Conseil de gestion",value:"702"},
{libelle :"7021Z - Conseil en relation publique & communic.",value:"7021Z"},
{libelle :"7022Z - Conseil pr affaire & aut. cons. gestion",value:"7022Z"},
{libelle :"71 - Architec. & ingénierie; ctrle ana. tech.",value:"71"},
{libelle :"711 - Activités d'architecture et d'ingénierie",value:"711"},
{libelle :"7111Z - Activités d'architecture",value:"7111Z"},
{libelle :"7112 - Activités d'ingénierie",value:"7112"},
{libelle :"7112A - Activité des géomètres",value:"7112A"},
{libelle :"7112B - Ingénierie, études techniques",value:"7112B"},
{libelle :"712 - Activité de contrôle & analyse technique",value:"712"},
{libelle :"7120 - Activité de contrôle & analyse technique",value:"7120"},
{libelle :"7120A - Contrôle technique automobile",value:"7120A"},
{libelle :"7120B - Analyses, essais & inspection technique",value:"7120B"},
{libelle :"72 - Recherche-développement scientifique",value:"72"},
{libelle :"721 - R&D en sciences physiques et naturelles",value:"721"},
{libelle :"7211Z - Recherche-développemnt en biotechnologie",value:"7211Z"},
{libelle :"7219Z - R&D : aut. sciences physique & naturelle",value:"7219Z"},
{libelle :"722 - R&D en sciences humaines et sociales",value:"722"},
{libelle :"7220Z - R&D en sciences humaines et sociales",value:"7220Z"},
{libelle :"73 - Publicité et études de marché",value:"73"},
{libelle :"731 - Publicité",value:"731"},
{libelle :"7311Z - Activités des agences de publicité",value:"7311Z"},
{libelle :"7312Z - Régie publicitaire de médias",value:"7312Z"},
{libelle :"732 - Études de marché et sondages",value:"732"},
{libelle :"7320Z - Études de marché et sondages",value:"7320Z"},
{libelle :"74 - Aut. act. spécial. scientifique & techn.",value:"74"},
{libelle :"741 - Activités spécialisées de design",value:"741"},
{libelle :"7410Z - Activités spécialisées de design",value:"7410Z"},
{libelle :"742 - Activités photographiques",value:"742"},
{libelle :"7420Z - Activités photographiques",value:"7420Z"},
{libelle :"743 - Traduction et interprétation",value:"743"},
{libelle :"7430Z - Traduction et interprétation",value:"7430Z"},
{libelle :"749 - Aut. act. spéc. scientif. & techn. nca.",value:"749"},
{libelle :"7490 - Aut. act. spéc. scientif. & techn. nca.",value:"7490"},
{libelle :"7490A - Activ des économistes de la construction",value:"7490A"},
{libelle :"7490B - Act. spéc. scientif. & techniq. diverses",value:"7490B"},
{libelle :"75 - Activités vétérinaires",value:"75"},
{libelle :"750 - Activités vétérinaires",value:"750"},
{libelle :"7500Z - Activités vétérinaires",value:"7500Z"},
{libelle :"N - ACTIVITÉS DE SCE ADMINISTR. & DE SOUTIEN",value:"N"},
{libelle :"77 - Activités de location et location-bail",value:"77"},
{libelle :"771 - Loc. & loc.-bail de véhicule automobile",value:"771"},
{libelle :"7711 - Loc. & loc.-bail voit. & v. auto. léger",value:"7711"},
{libelle :"7711A - Loc. courte durée voit. & v. auto. léger",value:"7711A"},
{libelle :"7711B - Loc. longue durée voit. & v. auto. léger",value:"7711B"},
{libelle :"7712Z - Location et location-bail de camions",value:"7712Z"},
{libelle :"772 - Loc. & loc.-bail bien perso. & domestiq.",value:"772"},
{libelle :"7721Z - Loc. & loc.-bail article loisir & sport",value:"7721Z"},
{libelle :"7722Z - Location de vidéocassette & disque vidéo",value:"7722Z"},
{libelle :"7729Z - Loc. & loc.-bail aut. bien perso. & dom.",value:"7729Z"},
{libelle :"773 - Loc. & loc.-bail aut. mach., éqpt & bien",value:"773"},
{libelle :"7731Z - Loc. & loc.-bail machine & éqpt agricole",value:"7731Z"},
{libelle :"7732Z - Loc. & loc.-bail mach. & éqpt pr constr.",value:"7732Z"},
{libelle :"7733Z - Loc. & loc.-bail mach. bur. & mat. info.",value:"7733Z"},
{libelle :"7734Z - Loc. & loc.-bail mat. transport par eau",value:"7734Z"},
{libelle :"7735Z - Loc. & loc.-bail mat. transport aérien",value:"7735Z"},
{libelle :"7739Z - Loc. & loc.-bail mach., éqpt & bien div.",value:"7739Z"},
{libelle :"774 - Loc-bail propr. intel., sf oeuvre avec ©",value:"774"},
{libelle :"7740Z - Loc-bail propr. intel., sf oeuvre avec ©",value:"7740Z"},
{libelle :"78 - Activités liées à l'emploi",value:"78"},
{libelle :"781 - Activ. agence placement de main-d'oeuvre",value:"781"},
{libelle :"7810Z - Activ. agence placement de main-d'oeuvre",value:"7810Z"},
{libelle :"782 - Activ. des agences de travail temporaire",value:"782"},
{libelle :"7820Z - Activ. des agences de travail temporaire",value:"7820Z"},
{libelle :"783 - Aut. mise à dispo. de ressource humaine",value:"783"},
{libelle :"7830Z - Aut. mise à dispo. de ressource humaine",value:"7830Z"},
{libelle :"79 - Act .ag. voyage voyagiste sv. résa. etc.",value:"79"},
{libelle :"791 - Activités agences de voyage & voyagistes",value:"791"},
{libelle :"7911Z - Activités des agences de voyage",value:"7911Z"},
{libelle :"7912Z - Activités des voyagistes",value:"7912Z"},
{libelle :"799 - Autre serv. réservation & activ. connexe",value:"799"},
{libelle :"7990Z - Autre serv. réservation & activ. connexe",value:"7990Z"},
{libelle :"80 - Enquêtes et sécurité",value:"80"},
{libelle :"801 - Activités de sécurité privée",value:"801"},
{libelle :"8010Z - Activités de sécurité privée",value:"8010Z"},
{libelle :"802 - Activités liées aux systèmes de sécurité",value:"802"},
{libelle :"8020Z - Activités liées aux systèmes de sécurité",value:"8020Z"},
{libelle :"803 - Activités d'enquête",value:"803"},
{libelle :"8030Z - Activités d'enquête",value:"8030Z"},
{libelle :"81 - Sces relatifs bâtimnt & aménagt paysager",value:"81"},
{libelle :"811 - Act. combinée soutien lié aux bâtiments",value:"811"},
{libelle :"8110Z - Act. combinée soutien lié aux bâtiments",value:"8110Z"},
{libelle :"812 - Activités de nettoyage",value:"812"},
{libelle :"8121Z - Nettoyage courant des bâtiments",value:"8121Z"},
{libelle :"8122Z - Aut. act. nettoyage bâtim. & nett. ind.",value:"8122Z"},
{libelle :"8129 - Autres activités de nettoyage",value:"8129"},
{libelle :"8129A - Désinfection désinsectisatn dératisation",value:"8129A"},
{libelle :"8129B - Autres activités de nettoyage n.c.a.",value:"8129B"},
{libelle :"813 - Services d'aménagement paysager",value:"813"},
{libelle :"8130Z - Services d'aménagement paysager",value:"8130Z"},
{libelle :"82 - Act. admin. & aut. act. soutien aux ent.",value:"82"},
{libelle :"821 - Activités administratives",value:"821"},
{libelle :"8211Z - Services admin. combinés de bureau",value:"8211Z"},
{libelle :"8219Z - Photocopie & aut. act. spé. sout. bureau",value:"8219Z"},
{libelle :"822 - Activités de centres d'appels",value:"822"},
{libelle :"8220Z - Activités de centres d'appels",value:"8220Z"},
{libelle :"823 - Organisation salon profession. & congrès",value:"823"},
{libelle :"8230Z - Organisation salon profession. & congrès",value:"8230Z"},
{libelle :"829 - Activités soutien aux entreprises nca.",value:"829"},
{libelle :"8291Z - Act. recouv. fac. & info. fin. s/client.",value:"8291Z"},
{libelle :"8292Z - Activités de conditionnement",value:"8292Z"},
{libelle :"8299Z - Autre activité de soutien aux entr. nca.",value:"8299Z"},
{libelle :"OQ - Admin. pub., enseign., santé & act. soc.",value:"OQ"},
{libelle :"O - ADMINISTRATION PUBLIQUE",value:"O"},
{libelle :"84 - Admin. publi. & défense; séc. soc. obli.",value:"84"},
{libelle :"841 - Admin. générale, économique & sociale",value:"841"},
{libelle :"8411Z - Administration publique générale",value:"8411Z"},
{libelle :"8412Z - A. p. santé form. cult. & soc. (sf sécu)",value:"8412Z"},
{libelle :"8413Z - Adm. publique des activités économiques",value:"8413Z"},
{libelle :"842 - Services de prérogative publique",value:"842"},
{libelle :"8421Z - Affaires étrangères",value:"8421Z"},
{libelle :"8422Z - Défense",value:"8422Z"},
{libelle :"8423Z - Justice",value:"8423Z"},
{libelle :"8424Z - Activités d'ordre public et de sécurité",value:"8424Z"},
{libelle :"8425Z - Services du feu et de secours",value:"8425Z"},
{libelle :"843 - Sécurité sociale obligatoire",value:"843"},
{libelle :"8430 - Sécurité sociale obligatoire",value:"8430"},
{libelle :"8430A - Activités générales de sécurité sociale",value:"8430A"},
{libelle :"8430B - Gestion des retraites complémentaires",value:"8430B"},
{libelle :"8430C - Distribution sociale de revenus",value:"8430C"},
{libelle :"P - ENSEIGNEMENT",value:"P"},
{libelle :"85 - Enseignement",value:"85"},
{libelle :"851 - Enseignement pré-primaire",value:"851"},
{libelle :"8510Z - Enseignement pré-primaire",value:"8510Z"},
{libelle :"852 - Enseignement primaire",value:"852"},
{libelle :"8520Z - Enseignement primaire",value:"8520Z"},
{libelle :"853 - Enseignement secondaire",value:"853"},
{libelle :"8531Z - Enseignement secondaire général",value:"8531Z"},
{libelle :"8532Z - Enseignemt secondaire techn. ou profess.",value:"8532Z"},
{libelle :"854 - Enseigmnt sup. & post-second. non sup.",value:"854"},
{libelle :"8541Z - Enseignement post-secondaire non sup.",value:"8541Z"},
{libelle :"8542Z - Enseignement supérieur",value:"8542Z"},
{libelle :"855 - Autres activités d'enseignement",value:"855"},
{libelle :"8551Z - Enseigmnt discipl. sport. & act. loisir.",value:"8551Z"},
{libelle :"8552Z - Enseignement culturel",value:"8552Z"},
{libelle :"8553Z - Enseignement de la conduite",value:"8553Z"},
{libelle :"8559 - Enseignements divers",value:"8559"},
{libelle :"8559A - Formation continue d'adultes",value:"8559A"},
{libelle :"8559B - Autres enseignements",value:"8559B"},
{libelle :"856 - Activités de soutien à l'enseignement",value:"856"},
{libelle :"8560Z - Activités de soutien à l'enseignement",value:"8560Z"},
{libelle :"Q - SANTÉ HUMAINE ET ACTION SOCIALE",value:"Q"},
{libelle :"86 - Activités pour la santé humaine",value:"86"},
{libelle :"861 - Activités hospitalières",value:"861"},
{libelle :"8610Z - Activités hospitalières",value:"8610Z"},
{libelle :"862 - Activité des médecins et des dentistes",value:"862"},
{libelle :"8621Z - Activité des médecins généralistes",value:"8621Z"},
{libelle :"8622 - Activité des médecins spécialistes",value:"8622"},
{libelle :"8622A - Act. radiodiagnostic et de radiothérapie",value:"8622A"},
{libelle :"8622B - Activités chirurgicales",value:"8622B"},
{libelle :"8622C - Autre activité des médecins spécialistes",value:"8622C"},
{libelle :"8623Z - Pratique dentaire",value:"8623Z"},
{libelle :"869 - Autres activités pour la santé humaine",value:"869"},
{libelle :"8690 - Autres activités pour la santé humaine",value:"8690"},
{libelle :"8690A - Ambulances",value:"8690A"},
{libelle :"8690B - Laboratoires d'analyses médicales",value:"8690B"},
{libelle :"8690C - Centres de collecte et banques d'organes",value:"8690C"},
{libelle :"8690D - Act. des infirmiers et des sages-femmes",value:"8690D"},
{libelle :"8690E - Act. rééduc. appareillag. & pédic.-podo.",value:"8690E"},
{libelle :"8690F - Activités de santé humaine nca.",value:"8690F"},
{libelle :"87 - Hébergement médico-social et social",value:"87"},
{libelle :"871 - Hébergement médicalisé",value:"871"},
{libelle :"8710 - Hébergement médicalisé",value:"8710"},
{libelle :"8710A - Hébergt médicalisé pour personnes âgées",value:"8710A"},
{libelle :"8710B - Hébergt médicalisé pr enfants handicapés",value:"8710B"},
{libelle :"8710C - Hébrgt médic. adul. hand. & aut. ht méd.",value:"8710C"},
{libelle :"872 - Hébrgt soc. hand., mal. mental & toxico.",value:"872"},
{libelle :"8720 - Hébrgt soc. hand., mal. mental & toxico.",value:"8720"},
{libelle :"8720A - Hébrgt soc. hand. mental & malade mental",value:"8720A"},
{libelle :"8720B - Hébergement social pour toxicomanes",value:"8720B"},
{libelle :"873 - Hébgt soc. perso. âgées, hand. physiques",value:"873"},
{libelle :"8730 - Hébgt soc. perso. âgées, hand. physiques",value:"8730"},
{libelle :"8730A - Hébergement social pour personnes âgées",value:"8730A"},
{libelle :"8730B - Hébergt social pour handicapés physiques",value:"8730B"},
{libelle :"879 - Autres activités d'hébergement social",value:"879"},
{libelle :"8790 - Autres activités d'hébergement social",value:"8790"},
{libelle :"8790A - Hébergt social pr enfants en difficultés",value:"8790A"},
{libelle :"8790B - Hébgt soc. adult., famille en difficulté",value:"8790B"},
{libelle :"88 - Action sociale sans hébergement",value:"88"},
{libelle :"881 - Action soc. sans hbgt, per. âgée & hand.",value:"881"},
{libelle :"8810 - Action soc. sans hbgt, per. âgée & hand.",value:"8810"},
{libelle :"8810A - Aide à domicile",value:"8810A"},
{libelle :"8810B - Accueil ss hbgt adult. hand., pers. âgée",value:"8810B"},
{libelle :"8810C - Aide par le travail",value:"8810C"},
{libelle :"889 - Autre action sociale sans hébergement",value:"889"},
{libelle :"8891 - Action soc. sans hbrgt pr jeunes enfants",value:"8891"},
{libelle :"8891A - Accueil de jeunes enfants",value:"8891A"},
{libelle :"8891B - Accueil sans hébergt d'enfant handicap",value:"8891B"},
{libelle :"8899 - Autre action sociale sans hébrgemnt nca.",value:"8899"},
{libelle :"8899A - Aut. accueil sans hébrgt enfants & ado.",value:"8899A"},
{libelle :"8899B - Action sociale sans hébergement n.c.a.",value:"8899B"},
{libelle :"RU - Autres activités de services",value:"RU"},
{libelle :"R - ARTS, SPECTACLES & ACTIVITÉS RÉCRÉATIVES",value:"R"},
{libelle :"90 - Act. créativ. artistiques & de spectacle",value:"90"},
{libelle :"900 - Act. créativ. artistiques & de spectacle",value:"900"},
{libelle :"9001Z - Arts du spectacle vivant",value:"9001Z"},
{libelle :"9002Z - Activités de soutien au spectacle vivant",value:"9002Z"},
{libelle :"9003 - Création artistique",value:"9003"},
{libelle :"9003A - Création artistique (arts plastiques)",value:"9003A"},
{libelle :"9003B - Autre création artistique",value:"9003B"},
{libelle :"9004Z - Gestion de salles de spectacles",value:"9004Z"},
{libelle :"91 - Biblioth. archive musée & aut. act. cul.",value:"91"},
{libelle :"910 - Biblioth. archive musée & aut. act. cul.",value:"910"},
{libelle :"9101Z - Gestion des bibliothèques & des archives",value:"9101Z"},
{libelle :"9102Z - Gestion des musées",value:"9102Z"},
{libelle :"9103Z - Gestion site histor. & attraction simil.",value:"9103Z"},
{libelle :"9104Z - Gest. jardin bota. & zoo. & réserv. nat.",value:"9104Z"},
{libelle :"92 - Organisation jeux de hasard & d'argent",value:"92"},
{libelle :"920 - Organisation jeux de hasard & d'argent",value:"920"},
{libelle :"9200Z - Organisation jeux de hasard & d'argent",value:"9200Z"},
{libelle :"93 - Act. sportiv., récréatives & de loisirs",value:"93"},
{libelle :"931 - Activités liées au sport",value:"931"},
{libelle :"9311Z - Gestion d'installations sportives",value:"9311Z"},
{libelle :"9312Z - Activités de clubs de sports",value:"9312Z"},
{libelle :"9313Z - Activité des centres de culture physique",value:"9313Z"},
{libelle :"9319Z - Autres activités liées au sport",value:"9319Z"},
{libelle :"932 - Activités récréatives et de loisirs",value:"932"},
{libelle :"9321Z - Act. parcs attractions & parcs à thèmes",value:"9321Z"},
{libelle :"9329Z - Autres activités récréative & de loisirs",value:"9329Z"},
{libelle :"S - AUTRES ACTIVITÉS DE SERVICES",value:"S"},
{libelle :"94 - Activités des organisations associatives",value:"94"},
{libelle :"941 - Act. organisations écon. patron. & prof.",value:"941"},
{libelle :"9411Z - Act. organisations patronale & consul.",value:"9411Z"},
{libelle :"9412Z - Act. des organisations professionnelles",value:"9412Z"},
{libelle :"942 - Activités des syndicats de salariés",value:"942"},
{libelle :"9420Z - Activités des syndicats de salariés",value:"9420Z"},
{libelle :"949 - Act. autres organisations associatives",value:"949"},
{libelle :"9491Z - Activités des organisations religieuses",value:"9491Z"},
{libelle :"9492Z - Activités des organisations politiques",value:"9492Z"},
{libelle :"9499Z - Aut. org. fonctionnant par adhé. volont.",value:"9499Z"},
{libelle :"95 - Répar. ordi. & bien perso. & domestique",value:"95"},
{libelle :"951 - Répar. ordinateur & équipt communication",value:"951"},
{libelle :"9511Z - Répar. ordinateur & équipt périphérique",value:"9511Z"},
{libelle :"9512Z - Réparation équipements de communication",value:"9512Z"},
{libelle :"952 - Réparation  biens personnel & domestique",value:"952"},
{libelle :"9521Z - Réparation prdts électroniq. grd public",value:"9521Z"},
{libelle :"9522Z - Répar. électromén. & éqpt maison & jard.",value:"9522Z"},
{libelle :"9523Z - Réparation chaussures & articles en cuir",value:"9523Z"},
{libelle :"9524Z - Réparation meubles & d'équipt du foyer",value:"9524Z"},
{libelle :"9525Z - Répar.articles horlogerie & bijouterie",value:"9525Z"},
{libelle :"9529Z - Répar. aut. biens personnel & domestique",value:"9529Z"},
{libelle :"96 - Autres services personnels",value:"96"},
{libelle :"960 - Autres services personnels",value:"960"},
{libelle :"9601 - Blanchisserie-teinturerie",value:"9601"},
{libelle :"9601A - Blanchisserie-teinturerie de gros",value:"9601A"},
{libelle :"9601B - Blanchisserie-teinturerie de détail",value:"9601B"},
{libelle :"9602 - Coiffure et soins de beauté",value:"9602"},
{libelle :"9602A - Coiffure",value:"9602A"},
{libelle :"9602B - Soins de beauté",value:"9602B"},
{libelle :"9603Z - Services funéraires",value:"9603Z"},
{libelle :"9604Z - Entretien corporel",value:"9604Z"},
{libelle :"9609Z - Autres services personnels n.c.a.",value:"9609Z"},
{libelle :"T - Act. ménages: empl., prod. pr us. propre",value:"T"},
{libelle :"97 - Act. ménage: empl. de person. domestique",value:"97"},
{libelle :"970 - Act. ménage: empl. de person. domestique",value:"970"},
{libelle :"9700Z - Act. ménage: empl. de person. domestique",value:"9700Z"},
{libelle :"98 - Act. ménage : prod. b.&s. (usage propre)",value:"98"},
{libelle :"981 - Act. ménage : prod. biens (usage propre)",value:"981"},
{libelle :"9810Z - Act. ménage : prod. biens (usage propre)",value:"9810Z"},
{libelle :"982 - Act. ménage : prod. serv. (usage propre)",value:"982"},
{libelle :"9820Z - Act. ménage : prod. serv. (usage propre)",value:"9820Z"},
{libelle :"U - ACTIVITÉS EXTRA-TERRITORIALES",value:"U"},
{libelle :"99 - Act. organisations extraterritoriales",value:"99"},
{libelle :"990 - Act. organisations extraterritoriales",value:"990"},
{libelle :"9900Z - Act. organisations extraterritoriales",value:"9900Z"}]
