import React from "react";
import { NumericFormat } from 'react-number-format';
import { TrendingDown, TrendingUp } from "@mui/icons-material";

const Evolution = (props) => {
  const val = props.value;
  if (isNaN(val)) {
    return null;
  }
  const prefix = val < 0 ? <TrendingDown /> : <TrendingUp />;
  const color = val < 0 ? "FIREBRICK" : "FORESTGREEN";
  return (
    <span className="evolution" style={{ color }}>
      {val !== 0 && prefix}
      <NumericFormat
        value={val}
        displayType={"text"}
        decimalScale={2}
        suffix="%"
        style={{ marginLeft: "5px", verticalAlign: "super" }}
      />
    </span>
  );
};

export default Evolution;
