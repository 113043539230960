import React, { useState } from "react";
import { Grid, TextField, Tooltip } from "@mui/material";

import Unite from "components/Unite";
import { InputTypeOp, InputMontant } from "components/utils";
import { getHistoFromMetadata } from "utils/";

export const colonnes = {
  cedante: { libelle: "Cédante", taille: 5 },
  operation: { libelle: "Opération", taille: 2 },
  beneficiaire: { libelle: "Bénéficiaire", taille: 5 },
};

const ResumeOperation = (props) => {
  const {
    operation,
    modification,
    vueEntreprise,
    rejet,
    submitModification,
    getInfoUnite,
    isUpdatable,
  } = props;
  const [newCodeTypeOperation, setNewCodeTypeOperation] = useState(operation.codeTypeOperation.code);

  const handleChangeSiren = (unite, siren) => {
    submitModification(unite, {
      ...getInfoUnite(siren, operation.anneeCampagne),
      siren,
    });
  };
  const handleChangeTypeOp = (type) => {
    if (isUpdatable("codeTypeOperation")) {
      submitModification("codeTypeOperation", type);
      setNewCodeTypeOperation(type.code);
    }
  };

  let libelleCourtTypeOp = operation.codeTypeOperation
    ? operation.codeTypeOperation.libelleCourt
    : "?";
  let libelleTypeOp = operation.codeTypeOperation
    ? operation.codeTypeOperation.libelle
    : "?";
  let idEnveloppe = operation.enveloppe ? operation.enveloppe.publicId : null;

  if (vueEntreprise) {
    idEnveloppe = operation.enveloppeEntreprise
      ? operation.enveloppeEntreprise.publicId
      : null;
  }
  return (
    <Grid container className="operationContenu">
      <Grid item xs={colonnes.cedante.taille}>
        <Unite
          unite={{
            ...operation.uniteCedante,
          }}
          idEnveloppe={idEnveloppe}
          modification={modification && rejet}
          handleChange={(s) => handleChangeSiren("uniteCedante", s)}
        />
      </Grid>
      <Grid className="typeOperation" item xs={colonnes.operation.taille}>
        {modification ? (
          <InputTypeOp
            value={newCodeTypeOperation}
            onChange={handleChangeTypeOp}
            className="editTypeOp"
          />
        ) : (
          <Tooltip
            title={getHistoFromMetadata(libelleTypeOp, "CODE_TYPE_OP", operation)}
            PopperProps={{ className: "infoBulle" }}
          >
            <span>{libelleCourtTypeOp}</span>
          </Tooltip>
        )}
        {modification && rejet ? (
          <TextField
            defaultValue={operation.anneeCampagne}
            type="number"
            onChange={(e) => {
              submitModification("anneeCampagne", e.target.value);
            }}
            margin="none"
            inputProps={{
              "aria-label": "Millésime",
              size: "4",
            }}
          />
        ) : (
          <b>{operation.anneeCampagne}</b>
        )}
        <dl>
          <dt>{`Montant net :`}</dt>
          <dd>
            {operation.montantNet !== null || modification ? (
                <InputMontant
                  value={operation.montantNet}
                  libelle="Montant net"
                  onSubmit={(value) => submitModification("montantNet", value)}
                  {...props}
                  isUpdatable={() => isUpdatable("montantNet")}
                  tooltip={getHistoFromMetadata(null, "APPORT_CORPOREL_NET", operation)}
                />
            ) : (
              "N/A"
            )}
          </dd>
        </dl>
      </Grid>
      <Grid item xs={colonnes.beneficiaire.taille}>
        <Unite
          unite={{
            ...operation.uniteBeneficiaire,
          }}
          idEnveloppe={idEnveloppe}
          modification={modification && rejet}
          handleChange={(s) => handleChangeSiren("uniteBeneficiaire", s)}
        />
      </Grid>
    </Grid>
  );
};

export default ResumeOperation;
