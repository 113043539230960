import { connect } from "react-redux";
import Creation from "./Creation";
import { setAriane, saveNewOp, getInfoUnite } from "redux/actions";

const mapStateToProps = state => {
  return {
    sirensValides: state.storeSirens.sirensValides
  };
};

export default connect(mapStateToProps, {
  setAriane,
  saveNewOp,
  getInfoUnite
})(Creation);
