import React, { useState, useEffect, useRef } from "react";
import { Tooltip, Divider } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useSearchParams } from "react-router-dom";
import Filtre from "./filtre.container";


import "./filtres.scss";

const title = "filtres de recherche";
const yearNow = new Date().getFullYear();
const helpText = (
  <div>
    <p>{`Les filtres multiples sur un même champ (par exemple : millésime) se traduiront par une liaison "OU" entre les différents items sélectionnés.
    Lorsque des items de nature différente sont sélectionnés, une liaison "ET" sera établie entre eux. Par exemple, la sélection de "${
      yearNow - 1
    }" et de "${yearNow}" pour le millésime et de "7010Z - Activités des sièges sociaux" pour l'APE de l'unité se traduira par un filtre
       où le millésime sera égal à ("${
         yearNow - 1
       }" OU "${yearNow}") ET l'APE de l'unité sera égale à "7010Z - Activités des sièges sociaux".`}</p>
    <p>{`Le champ "Id. UL / Ent. / Env." porte sur le Siren de la bénéficiaire, le Siren de la cédante et l'identifiant de l'enveloppe sur les écrans de consultation des opérations.
    Pour la consultation des unités légales, la recherche portera sur l'identifiant de l'unité légale et l'identifiant d'enveloppe.
    Sur les écrans vue en entreprises, la recherche portera sur l'entreprise au lieu de l'unité légale.`}</p>
  </div>
);

const Filtres = (props) => {
  const [selected, setSelected] = useState(null);
  const initialisation = useRef(true);

  const { allFiltres, titre, filtresActifs, setFiltres } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const [filterState, setFilterState] = useState(null);



  // Lors de l'initialisation, on utilise la valeur indiqué dans l'url
  useEffect(() => {
    if (initialisation.current &&  Object.keys(allFiltres).length !== 0) {
      
      let filters ={};
      for (const [key, value] of searchParams.entries()) {
          if (key !== "page" && key !== "size" && key !== "sort" && key !== "vue") {
            if (Array.isArray(filters[key])) {
              filters[key].push(value);
            } else if (filters[key]) {
              filters[key] = [filters[key], value];
            } else {
              filters[key] = value;
            }
        }
      }
      setFiltres(filters);
      setFilterState(filtresActifs);
      initialisation.current = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFiltres, setFiltres, searchParams]);


  // ajout du nouveau filtre à la recherche
  useEffect(() => {
    if (filtresActifs && JSON.stringify(filtresActifs) !== JSON.stringify(filterState)) {
      setFilterState(filtresActifs);
      setSearchParams((currentSearchParams) => {
        const newSearchParams = new URLSearchParams(currentSearchParams);
        for (const key of newSearchParams.keys()) {
          if (key !== "page" && key !== "size" && key !== "sort" && key !== "vue") {
            newSearchParams.delete(key);
          }
        }
        Object.keys(filtresActifs).forEach((k, i) => {
          filtresActifs[k].forEach((value) => {
            newSearchParams.append(k, value);
          });
        });
        return newSearchParams;
      });
    }
  }, [filterState, filtresActifs, setSearchParams]);

  // Chargement du filtre
  const handleSelect = (i) => {
    if (i === selected) {
      setSelected(null);
    } else {
      setSelected(i);
    }
  };

  let filtres = [];
  Object.keys(allFiltres).forEach((k, i) => {
    filtres.push(
      <Filtre
        key={i}
        param={k}
        {...allFiltres[k]}
        onClick={() => handleSelect(i)}
        selected={i === selected}
      />
    );
  });

  return (
    <div id="filtres" className="block-gauche">
      <h3>
        <span>{titre || title}</span>
        <Tooltip title={helpText} PopperProps={{ className: "infoBulle" }}>
          <HelpOutlineIcon />
        </Tooltip>
      </h3>
      <Divider />
      <div>
        {filtres}
      </div>
    </div>
  );
};

export default Filtres;
