import * as types from "./constantes";
import { getKeycloak, getSource } from "../api";

const storageId = "user-Info";

export const loadUserInfo = () => async (dispatch, getState) => {
  dispatch({ type: types.USER_LOADING });
  if (localStorage.hasOwnProperty(storageId)) {
    dispatch({
      type: types.USER_LOADED,
      payload: JSON.parse(localStorage.getItem(storageId))
    });
  } else {
    const keycloak = await getKeycloak();
    keycloak.loadUserInfo().then(userInfo => {
      let tableauRoles = (keycloak.realmAccess) ?  keycloak.realmAccess.roles : [];
      localStorage.setItem(
        storageId,
        JSON.stringify({
          name: userInfo.name,
          timbre: userInfo.timbre,
          roles: tableauRoles
        })
      );
      console.log(tableauRoles);
      dispatch({
        type: types.USER_LOADED,
        payload: { ...userInfo, roles: tableauRoles }
      });
    });
  }
};
export const loadSourceUser = () => (dispatch, getState) => {
  const { source, user } = getState().storeUser;
  if (user && !source) {
    return getSource(user.timbre).then(response => {
      dispatch({ type: types.SOURCE_LOADED, payload: response });
    });
  }
  return null;
};
