import React, { useEffect } from "react";

import CommentairesListe from "components/Commentaires/CommentairesListe";
import FiltresCommentaires from "components/Commentaires/FiltresCommentaires";
import InfoFiltres from "components/Listes/ListeHeader/InfoFiltres";
import { getArianeMillesime, getArianeEnveloppe } from "components/utils";
import { useParams } from "react-router-dom";

const getArticle = (code) => {
  switch (code) {
    case "TP":
      return "le ";
    case "AB":
    case "AP":
      return "l'";
    default:
      return "la ";
  }
};
const CommentairesOperation = (props) => {
  const {
    loadOperation,
    loadCommentairesOperation,
    operation,
    setAriane,
    commentaires,
    setAttache,
    vueEntreprise
  } = props;

  
  const { idOperation, annee, idEnv } = useParams();

  // Chargement initial
  useEffect(() => {
    loadOperation(idOperation);
    loadCommentairesOperation(idOperation);
  }, [idOperation, loadOperation, loadCommentairesOperation]);

  // mise à jour du fils d'Ariane
  useEffect(() => {
    if (operation) {
      const typeOp = operation.codeTypeOperation;
      setAriane([
        getArianeMillesime(annee, vueEntreprise),
        getArianeEnveloppe(annee, idEnv, vueEntreprise),
        {
          libelle: `Commentaires sur ${getArticle(
            typeOp.code
          )}${typeOp.libelle.toLowerCase()} (${operation.uniteCedante.siren} > ${
            operation.uniteBeneficiaire.siren
          })`,
        },
      ]);
    }
  }, [annee, idEnv, operation, setAriane, vueEntreprise]);

  if (operation) {
    setAttache({ operation });
    const attaches = [{ operation, niveau: 1 }];
    if (
      operation.idEnveloppeEntreprise &&
      operation.idEnveloppeEntreprise !== operation.idEnveloppe
    ) {
      attaches.push({
        enveloppeEntreprise: operation.enveloppeEntreprise,
        niveau: 2,
      });
    }
    attaches.push({ enveloppe: operation.enveloppe, niveau: 2 });
    attaches.push(
      { uniteCedante: operation.uniteCedante, niveau: 3 },
      { uniteBeneficiaire: operation.uniteBeneficiaire, niveau: 3 }
    );
    if (commentaires) {
      return (
        <div className="block-main">
          <FiltresCommentaires attaches={attaches} />
          <div className="block-content">
            <InfoFiltres />
            <CommentairesListe />
          </div>
        </div>
      );
    }
  }
  return <></>;
};

export default CommentairesOperation;
