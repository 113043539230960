import { connect } from "react-redux";
import OngletListOperations from "./OngletListOperations";
import {
  setAllSorts,
  setPageSize,
  setPage,
  setSorts,
  setFiltres,
  loadSourceUser
} from "redux/actions";

const mapStateToProps = state => {
  return {
    operations: state.storeOperations.operations,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {
    setAllSorts,
    setPageSize,
    setPage,
    setSorts,
    setFiltres,
    loadSourceUser
  }
)(OngletListOperations);
