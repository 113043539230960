import { connect } from "react-redux";
import DetailOperation from "./DetailOperation";

const mapStateToProps = state => {
  return {
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {}
)(DetailOperation);
