import { connect } from "react-redux";
import FiltresCommentairesAvances from "./FiltresCommentairesAvances";
import {
  loadOrigineInfoCommentaires,
  loadTypesCommentaires,
  loadSources,
  setAllFiltres,
  setFiltres,
  setAllSorts,
  setSorts
} from "redux/actions";

const mapStateToProps = state => {
  return {
    types: state.storeCommentaires.types,
    originesInfo: state.storeCommentaires.originesInfo,
    sources: state.storeSources.sources
  };
};

export default connect(
  mapStateToProps,
  {
    loadOrigineInfoCommentaires,
    loadTypesCommentaires,
    loadSources,
    setAllFiltres,
    setFiltres,
    setAllSorts,
    setSorts
  }
)(FiltresCommentairesAvances);
