import { getConfig } from "utils";

import Keycloak from "keycloak-js";

const keycloak = new Keycloak(`${window.location.origin}/keycloak.json`);
const storageId = "user-Info";

const keycloakDefaultConf = {
  onLoad: "check-sso",
  silentCheckSsoRedirectUri: window.location.origin + "/silent-sso.html",
  responseMode: "query",
  checkLoginIframe: false,
  refreshToken: 100000
};

export const login = async (keycloakParam) => {
  fetch((await getConfig()).URL_SERVICE_DECOUVERTE).then(response =>
    response.json().then(async json => {
      console.log("login");
      return keycloakParam.login(
        json.interne ? { idpHint: (await getConfig()).NOM_IDP_HINT } : {}
      );
    })
  );
};

export const initKeycloak = async () => {
  await new Promise(function(resolve, reject) {
    keycloak.init(keycloakDefaultConf).then(async authenticated => {
      if (authenticated) {
        console.log("authenticated");
      } else {
        await login(keycloak);
      }
      resolve(keycloak);
    });
  });
  return keycloak;
};

export const getKeycloak = async () => {
  await new Promise(function(resolve, reject) {
    if (keycloak.isTokenExpired()) {
      console.log("expired");
      initKeycloak();
    }
    keycloak
      .updateToken(3600)
      .then(async () => {
        resolve(keycloak);
      })
      .catch(() => {
        reject("Failed to refresh token");
      });
  });
  return keycloak;
};

export const logoutKeycloak = () => {
  if (!keycloak.isTokenExpired()) {
    const options = {};
    options.redirectUri= window.location.origin + "/logout";
    keycloak.logout(options);
    localStorage.removeItem(storageId);
  }
};


export const request = async options => {
  const headers = new Headers({
    "Content-Type": "application/json",
    Accept: "application/json"
  });

  headers.append("Authorization", "Bearer " + (await getKeycloak()).token);

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch((await getConfig()).URL_CITRUS_WS + options.url, options).then(
    response => {
      return response.text().then(function(text) {
        const texte = text ? JSON.parse(text) : {};
        if (!response.ok) {
          return Promise.reject(texte);
        }
        return texte;
      });
    }
  );
};

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

export const requestFile = async options => {
  const headers = new Headers({
    "Content-Type": "application/json",
    Accept: "application/json"
  });

  headers.append("Authorization", "Bearer " + (await getKeycloak()).token);

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);

  return fetch((await getConfig()).URL_CITRUS_WS + options.url, options).then(
    async response => {
      const text = await response.text();
      if (response.ok && text) {
        const fiche = text ? JSON.parse(text) : {};
        if (fiche.contenu) {
          const url = window.URL.createObjectURL(b64toBlob(fiche.contenu, "application/pdf"));
          let a = document.createElement('a');
          a.href = url;
          a.download = fiche.nomFiche;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again   
        }
      }

    }
  );
};

