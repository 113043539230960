import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import moment from "moment";

import Field from "../Field";
import { InputTypeOp, InputMontant, InputDate } from "components/utils";
import { formatsDate } from "utils/";

const labels = {
  dateEffetComptable: { label: "Date d'effet comptable" },
  dateRealisationJuridique: { label: "Date de réalisation juridique" },
  typeOp: { label: "Opération" },
  source: { label: "Source" },
  montantNet: {
    label: "Montant net des immobilisations corporelles transférées en K€",
  },
  anneeCampagne: {
    label: "Millésime",
  },
};

const OperationField = (props) => {
  const { operation, loadSourceUser, onSubmit, classes, source } = props;

  const [operationState, setOperationState] = useState(operation);

  // Une seule fois : on load les sources
  useEffect(() => {
    loadSourceUser();
  }, [loadSourceUser]);


  // On ajoute la source de l'utilisateur à l'opération
  useEffect(() => {
    if (operation && source && !operation.source) {
      setOperationState({ ...operation, source: source })
    }
  }, [operation, source]);


  const isAnneeValide = (annee) => {
    const now = new Date().getFullYear();
    return annee > now - 3 && annee < now + 1;
  };

  const onSubmitEvent = () => {
    if (isAnneeValide(operationState.anneeCampagne)) {
      onSubmit(operationState);
    }
  };

  const onChange = (key, value) => {
    const operationActuelle = operationState;
    operationActuelle[key] = value;
    if (
      (key === "dateEffetComptable" && value) ||
      (key === "dateRealisationJuridique" &&
        value &&
        operationActuelle.dateEffetComptable === "")
    ) {
      operationActuelle.anneeCampagne = parseInt(value.substring(6), 10);
    }
    setOperationState({ ...operationActuelle });
  };

  const handleChangeAnnee = (e) => {
    const annee = e.target.value;
    if (!isNaN(annee) && annee.length < 5) {
      onChange("anneeCampagne", annee);
    }
  };

  const getInputProps = (key) => {
    return {
      key,
      ...labels[key],
      modification: true,
      fullWidth: true,
      onSubmit: (v) => onChange(key, v),
      value: operationState[key],
      classes: classes,
    };
  };

  const isAnneeV = isAnneeValide(operationState.anneeCampagne);
  const anneeError =
    operationState.anneeCampagne.toString().length === 4 && !isAnneeV;
  const fields = [
    <InputDate
      {...getInputProps("dateEffetComptable")}
      autoFocus
      value={
        operationState["dateEffetComptable"]
          ? moment(
              operationState["dateEffetComptable"],
              formatsDate.formatDateWS
            )
          : null
      }
    />,
    <InputDate
      {...getInputProps("dateRealisationJuridique")}
      value={
        operationState["dateRealisationJuridique"]
          ? moment(
              operationState["dateRealisationJuridique"],
              formatsDate.formatDateWS
            )
          : null
      }
    />,
    <InputTypeOp
      {...getInputProps("typeOp")}
      onChange={(v) => onChange("typeOp", v)}
      value={operationState.typeOp ? operationState.typeOp.code : ""}
      style={{ display: "inline-flex" }}
    />,
    <Field
      {...getInputProps("anneeCampagne")}
      required
      error={anneeError}
      helperText={
        anneeError
          ? `Le millésime ${operationState.anneeCampagne} ne fait pas partie de ceux modifiables dans l'application.`
          : null
      }
      onChange={handleChangeAnnee}
      disabled={Boolean(
        (operationState["dateEffetComptable"] &&
        operationState["dateEffetComptable"] !== "") ||
          (operationState["dateRealisationJuridique"] &&
          operationState["dateRealisationJuridique"] !== "")
      )}
    />,
    <InputMontant {...getInputProps("montantNet")} />,
  ];
  return (
    <Grid container spacing={5}>
      {fields.map((f, index) => (
        <Grid key={index} item xs={6} className="operationField">
          {f}
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          disabled={!isAnneeV}
          style={{ display: "block", margin: "10px" }}
          onClick={onSubmitEvent}
        >
          Suivant
        </Button>
      </Grid>
    </Grid>
  );
};

export default OperationField;
