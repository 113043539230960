import * as types from "redux/actions/constantes";

const initial = {
  loading: false,
  fiches: [],
  numLastRequete: null,
  motifs: [],
  total: 0,
  exporting: false,
  fichesExport: []
};

const fiches = (state = initial, action = { type: "" }) => {
  switch (action.type) {
    case types.FICHES_LOADING: {
      return {
        ...state,
        loading: true,
        fiches: [],
        numLastRequete: action.numRequete
      };
    }
    case types.FICHES_FOUND: {
      if (state.numLastRequete > action.numRequete) {
        return state;
      }
      return {
        ...state,
        loading: false,
        fiches: [...action.payload.content],
        total: action.payload.totalElements
      };
    }
    case types.FICHES_EXPORTING: {
      return {
        ...state,
        exporting: true,
        fichesExport: []
      };
    }
    case types.FICHES_EXPORTED: {
      return {
        ...state,
        exporting: false,
        fichesExport: [...action.payload.content]
      };
    }
    default:
      return state;
  }
};
export default fiches