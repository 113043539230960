import * as types from "redux/actions/constantes";

const initial = {
  loading: false,
  typesOp: []
};

const typesOperation = (state = initial, action = { type: "" }) => {
  switch (action.type) {
    case types.TYPES_OP_LOADING: {
      return { loading: true, typesOp: [] };
    }
    case types.TYPES_OP_LOADED: {
      return { loading: false, typesOp: action.payload.TypeOperation };
    }
    default:
      return state;
  }
};
export default typesOperation