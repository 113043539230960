import React, { useState } from "react";
import { Input } from "@mui/material";
import { FixedSizeList } from "react-window";

import ListItemFiltre from "./ListItemFiltre";

import "./listFiltres.scss";

const ListFiltres = (props) => {
  const {
    param,
    addFiltre,
    removeFiltre,
    liste,
    filtres,
    exemple,
  } = props;

  const [searchState, setSearchState] = useState(null);

  const handleItemSelect = (value) => {
    addFiltre({ param, value });
  };

  const handleItemUnselect = (value) => {
    removeFiltre({ param, value });
  };

  const handleFiltreChange = (e) => {
    setSearchState(e.target.value);
  };

  const filtre = (value, search) => {
    const v = value.toUpperCase();
    const s = search.toUpperCase();
    return isNaN(s) ? v.includes(s) : v.startsWith(s);
  };

  const filtresAAppliquer = filtres ? filtres[param] : null;
  const filtered = liste.filter(
    (f) =>
      !searchState ||
      (f.libelle &&
        (filtre(f.libelle, searchState) ||
          (f.value && filtre(f.value, searchState)))) ||
      (!f.libelle && filtre(f, searchState))
  );
  let input = null;
  if (liste.length > 20) {
    input = (
      <Input
        key="input"
        onChange={handleFiltreChange}
        placeholder={`ex : ${exemple} `}
        margin="none"
        fullWidth
        type="search"
      />
    );
  }
  const list = ({ index, style }) => {
    const filtreEnCours = filtered[index];
    const value = filtreEnCours.libelle ? filtreEnCours.value : filtreEnCours.toString();
    return (
      <ListItemFiltre
        style={style}
        key={index}
        filtre={filtreEnCours}
        selected={
          filtresAAppliquer === undefined
            ? value === null
            : (Array.isArray(filtresAAppliquer) &&
                (filtresAAppliquer.includes(value) ||
                  (value !== null && filtresAAppliquer.includes(value.toString())))) ||
                  filtresAAppliquer === value
        }
        handleItemSelect={handleItemSelect}
        handleItemUnselect={handleItemUnselect}
      />
    );
  };
  return (
    <div className="contenuListFiltre">
      {input}
      <FixedSizeList
        height={Math.min(filtered.length * 40, 200)}
        width={"100%"}
        itemSize={40}
        itemCount={filtered.length}
      >
        {list}
      </FixedSizeList>
    </div>
  );
};
export default ListFiltres;
