import { connect } from "react-redux";
import SubHeader from "./SubHeader";
import { loadUserInfo } from "redux/actions";

const mapStateToProps = state => {
  return {
    user: state.storeUser.user,
    connected: state.storeUser.connected,
    ariane: state.storeAriane.ariane
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadUserInfo: () => dispatch(loadUserInfo())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubHeader);
