import { connect } from "react-redux";
import HistoriqueCreations from "./HistoriqueCreations";

const mapStateToProps = state => {
  return {
    creations: state.storeOperations.creations
  };
};

export default connect(
  mapStateToProps,
  {
  }
)(HistoriqueCreations);
