import React from "react";
import Tooltip from "@mui/material/Tooltip";

import { naf } from "components/utils/naf";

const Ape = (props) => {
  const getLibelle = (valeur) => {
    let libelleApe = "";
    naf.every((a) => {
      if (a.value === valeur) {
        libelleApe = a.libelle;
        return false;
      }
      return true;
    });
    return libelleApe;
  };

  const ape = props.value;
  const libelle = getLibelle(ape);
  return (
    <Tooltip title={libelle} PopperProps={{ className: "infoBulle" }}>
      <span>{ape}</span>
    </Tooltip>
  );
};

export default Ape;
