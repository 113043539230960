import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  Paper,
  TableSortLabel,
} from "@mui/material";

import {
  Identifiant,
  Ape,
  Montant,
  Evolution,
} from "components/utils/";

import "./ongletListUnites.scss";

const getEntreprise = (unite) => {
  return unite.entrepriseProfilee ? unite.entrepriseProfilee : unite;
};

const getUnitesFromOperation = (operations, vueEntreprise) => {
  const unites = [];
  if (operations) {
    operations.forEach((op) => {
      if (op.uniteBeneficiaire) {
        unites.push(
          vueEntreprise
            ? getEntreprise(op.uniteBeneficiaire)
            : op.uniteBeneficiaire
        );
      }
      if (op.uniteCedante) {
        unites.push(
          vueEntreprise ? getEntreprise(op.uniteCedante) : op.uniteCedante
        );
      }
    });
  }
  return filtrerDoublon(unites);
};

const filtrerDoublon = (unites) => {
  const sirens = {};
  return unites.filter((u) => {
    if (sirens[u.siren]) {
      return false;
    }
    sirens[u.siren] = true;
    return true;
  });
};

const compare = (a, b, orderBy) => {
  if (b[orderBy] === undefined && a[orderBy] === undefined) {
    return 0;
  }
  if (b[orderBy] === undefined || b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (a[orderBy] === undefined || b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};
const libelles = {
  siren: "Siren",
  entreprise: "Entreprise",
  rs: "Raison sociale",
  ape: "APE",
  effectif: "Effectif ETP",
  ca: "CA en K€",
  evol: "Evolution du CA",
};

const divider = <TableCell className="divider" />;

const OngletListUnites = (props) => {
  const [orderByState, setOrderByState] = useState("siren");
  const [orderState, setOrderState] = useState("desc");

  const {
    resetEsaneData,
    allOperations,
    vueEntreprise,
    loadEsaneData,
    annee,
    datas,
    footer,
  } = props;

  useEffect(() => {
    resetEsaneData();
    allOperations &&
      getUnitesFromOperation(allOperations, vueEntreprise).forEach((u) =>
        loadEsaneData(u)
      );
  }, [resetEsaneData, allOperations, loadEsaneData, vueEntreprise]);


  const handleSort = (key) => {
    setOrderByState(key);
    setOrderState(orderState === "desc" ? "asc" : "desc");
  };

  /**
   * Construit les 3 cellules correspondant aux données d'une unité sur une année
   */
  const getCells = (data, n) => {
    return [
      <TableCell key={`${n}ape`}>
        <Ape value={data[`${n}ape`]} />
      </TableCell>,
      <TableCell key={`${n}effectif`}>{data[`${n}effectif`]}</TableCell>,
      <TableCell key={`${n}ca`}>
        <Montant value={data[`${n}ca`]} />
      </TableCell>,
    ];
  };

  /**
   * Construit une ligne complète de données
   */
  const getRowUnite = (data) => {
    data.evol =
      data.ca && data.zca ? ((data.ca - data.zca) / data.zca) * 100 : undefined;
    return (
      <TableRow hover key={data.siren}>
        <TableCell>
          <Identifiant value={data.siren} />
        </TableCell>
        <TableCell>{data.rs}</TableCell>
        {divider}
        {getCells(data, "z")}
        {divider}
        {getCells(data, "")}
        {divider}
        <TableCell>
          <Evolution value={data.evol} />
        </TableCell>
      </TableRow>
    );
  };

  /**
   * Construit une cellule de l'entête
   */
  const getHeadCell = (libelle, key) => (
    <TableCell sortDirection={orderByState === key ? orderState : false} key={key}>
      <TableSortLabel
        active={orderByState === key}
        direction={orderState}
        onClick={() => handleSort(key)}
      >
        {libelle}
      </TableSortLabel>
    </TableCell>
  );

  const myRef = useRef(null);

  let height = "100%";
  if (myRef.current) {
    const top = myRef.current.getBoundingClientRect().top + 50;
    height = `calc(100vh - ${top}px)`;
  }

  /**
   * Construit les cellules de l'entête correspondant aux données d'une unité pour une année
   */

  if (datas && datas.length > 0) {
    libelles.rs = vueEntreprise ? "Dénomination" : "Raison sociale";
    datas.sort(
      orderState === "desc"
        ? (a, b) => compare(a, b, orderByState)
        : (a, b) => -compare(a, b, orderByState)
    );
    return (
      <Paper className="listeUniteEnveloppe"
        ref={myRef}
        style={{height}}
      >
        <Table className="shadow">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} />
              {divider}
              <TableCell colSpan={3}>{annee - 1}</TableCell>
              {divider}
              <TableCell colSpan={3}>{annee}</TableCell>
              {divider}
              <TableCell align="center">Evolution</TableCell>
            </TableRow>
            <TableRow>
              {vueEntreprise
                ? getHeadCell(libelles.entreprise, "entreprise")
                : getHeadCell(libelles.siren, "siren")}
              {getHeadCell(libelles.rs, "rs")}
              {divider}
              {getHeadCell(libelles.ape, "zape")}
              {getHeadCell(libelles.effectif, "zeffectif")}
              {getHeadCell(libelles.ca, "zca")}
              {divider}
              {getHeadCell(libelles.ape, "ape")}
              {getHeadCell(libelles.effectif, "effectif")}
              {getHeadCell(libelles.ca, "ca")}
              {divider}
              {getHeadCell(libelles.evol, "evol")}
            </TableRow>
          </TableHead>
          <TableBody>{datas.map((u) => getRowUnite(u))}</TableBody>
          <TableFooter className="table-footer">
            {footer(datas, divider)}
          </TableFooter>
        </Table>
      </Paper>
    );
  }
  return <></>;
};

export default OngletListUnites;
