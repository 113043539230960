import React from "react";

const Logout = () => {
  return (
    <main className="logout">
      <h1>Vous êtes actuellement déconnecté.</h1>
      <a href="/">
        <h1>Reconnexion</h1>
      </a>
    </main>
  );
};

export default Logout;
