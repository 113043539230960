import React from "react";
import { ListItem } from "@mui/material";

const ListItemFiltre = (props) => {
  const { filtre, style, selected, handleItemUnselect, handleItemSelect } =
    props;

  const handleClick = (value) => {
    selected ? handleItemUnselect(value) : handleItemSelect(value);
  };
  
  return (
    <ListItem
      style={style}
      className={selected ? "selected" : ""}
      selected={Boolean(selected)}
      onClick={(e) => handleClick(filtre.libelle ? filtre.value : filtre)}
    >
      {filtre.libelle ? filtre.libelle : filtre}
    </ListItem>
  );
};

export default ListItemFiltre;
