import { connect } from "react-redux";
import CommentairesUnite from "./CommentairesUnite";
import {
  setAriane,
  loadCommentairesUnite,
  setAttache,
  loadUnite,
  setVueEntrepise
} from "redux/actions";

const mapStateToProps = state => {
  return {
    unite: state.storeUnites.unite,
    commentaires: state.storeCommentaires.commentaires,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {
    setAriane,
    loadCommentairesUnite,
    setAttache,
    loadUnite,
    setVueEntrepise
  }
)(CommentairesUnite);
