import React, { useEffect } from "react";

import CommentairesListe from "components/Commentaires/CommentairesListe";
import FiltresCommentaires from "components/Commentaires/FiltresCommentaires";
import InfoFiltres from "components/Listes/ListeHeader/InfoFiltres";
import { getArianeMillesime } from "components/utils";
import { useParams, useSearchParams } from "react-router-dom";

const CommentairesEnveloppe = (props) => {
  const {
    getEnveloppe,
    vueEntreprise,
    enveloppe,
    loadCommentairesEnveloppe,
    commentaires,
    setAttache,
    setAriane,
    setVueEntrepise,
  } = props;

  const {idEnv, annee } = useParams();
  const [searchParams] = useSearchParams();
  const vue = searchParams.get("vue");

  // Chargement initial
  useEffect(() => {
    getEnveloppe(idEnv);
  }, [getEnveloppe, idEnv]);

  // mise à jour du fils d'Ariane
  useEffect(() => {
    setAriane([
      getArianeMillesime(annee, vueEntreprise),
      {
        libelle: `Commentaires sur l'enveloppe en ${
          vueEntreprise ? "entreprises" : "unités légales"
        } : ${idEnv}`,
      },
    ]);
  }, [annee, idEnv, setAriane, vueEntreprise]);

  // Mise à jour de vueEntreprise
  useEffect(() => {
    const vueSafe = vue || "ul";
    setVueEntrepise(vueSafe === "entreprise");
  }, [vue, setVueEntrepise]);

  // Chargement initial
  useEffect(() => {
    if (enveloppe) {
      loadCommentairesEnveloppe(enveloppe.idEnveloppe, vueEntreprise);
    }
  }, [enveloppe, loadCommentairesEnveloppe, vueEntreprise]);

  if (enveloppe) {
    setAttache({ enveloppe });
    const attaches = [
      vueEntreprise
        ? { enveloppeEntreprise: enveloppe, niveau: 1 }
        : { enveloppe, niveau: 1 },
    ];
    const operations = vueEntreprise
      ? enveloppe.operationsEntreprise
      : enveloppe.operations;
    const attacheOperations = {};
    const attacheUnites = {};

    operations.operation.forEach((o) => {
      attacheOperations[o.idOperation] = o;
      attacheUnites[o.uniteCedante.idUnite] = o.uniteCedante;
      attacheUnites[o.uniteBeneficiaire.idUnite] = o.uniteBeneficiaire;
    });
    Object.values(attacheOperations).forEach((o) => {
      attaches.push({
        operation: o,
        niveau: 2,
      });
    });
    Object.values(attacheUnites)
      .sort((a, b) => a.siren.localeCompare(b.siren))
      .forEach((u) => {
        attaches.push({
          unite: u,
          niveau: 3,
        });
      });
    if (commentaires) {
      return (
        <div className="block-main">
          <FiltresCommentaires attaches={attaches} />
          <div className="block-content">
            <InfoFiltres />
            <CommentairesListe />
          </div>
        </div>
      );
    }
  }
  return <></>;
};

export default CommentairesEnveloppe;
