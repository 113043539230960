import { connect } from "react-redux";
import AnneesUnite from "./AnneesUnite";
import { loadAnnees } from "redux/actions";

const mapStateToProps = state => {
  return {
    operations: state.storeUnites.operations,
    annees: state.storeAnnees.annees,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {
    loadAnnees
  }
)(AnneesUnite);
