import { connect } from "react-redux";
import CommentairesOperation from "./CommentairesOperation";
import {
  setAriane,
  loadOperation,
  loadCommentairesOperation,
  setAttache
} from "redux/actions";

const mapStateToProps = state => {
  return {
    operation: state.storeOperations.operation,
    commentaires: state.storeCommentaires.commentaires
  };
};

export default connect(
  mapStateToProps,
  {
    setAriane,
    loadOperation,
    loadCommentairesOperation,
    setAttache
  }
)(CommentairesOperation);
