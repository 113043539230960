import { connect } from "react-redux";
import NumPage from "./NumPage";
import { setPage } from "redux/actions";

const mapStateToProps = state => {
  return {
    page: state.storeFiltre.page,
    size: state.storeFiltre.size
  };
};

export default connect(
  mapStateToProps,
  {
    setPage
  }
)(NumPage);
