import React, { useRef, useState } from "react";
import { Fab }  from "@mui/material";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";

const ListeScroll = (props) => {
  const { getContent } = props;
  const [showScrollButton, setShowScrollButton] = useState(false);

  const onScroll = (e) => {
    setShowScrollButton(e.target.scrollTop > 0);
  };

  const myRef = useRef(null);
 
  let height = "100%";
   if (myRef.current) {
     const top = myRef.current.getBoundingClientRect().top + 50;
     height = `calc(100vh - ${top}px)`;
   }
   const handleClickScroll = () => {
    const element = document.querySelector('#listeRecherche > .operation');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo(0,0)
    }
  };
  return (
    <div ref={myRef} >

        <div  >
          <div
          style={{height, overflow: "scroll"}}
            id="listeRecherche"
            className="listeRecherche"
            onScroll={onScroll}
          >
            {getContent()}
          </div>
          {showScrollButton && (
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="top"
              className="scrollButton"
              onClick={() =>
                handleClickScroll()
              }
            >
              <ArrowUpwardOutlinedIcon />
            </Fab>
          )}
        </div>
    </div>
  );
};

export default ListeScroll;
