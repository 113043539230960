import { connect } from "react-redux";
import ListeRejets from "./ListeRejets";
import {
  searchRejets,
  setAriane,
  setAllFiltres,
  loadAnnees,
  loadTypesOp,
  loadSources,
  loadMotifs,
  setAllSorts,
  loadSourceUser
} from "redux/actions";

const mapStateToProps = state => {
  return {
    loading: state.storeRejets.loading,
    rejets: state.storeRejets.rejets,
    filtres: state.storeFiltre.filtres,
    page: state.storeFiltre.page,
    size: state.storeFiltre.size,
    sort: state.storeFiltre.sort,
    annees: state.storeAnnees.annees,
    typesOp: state.storeTypesOp.typesOp,
    sources: state.storeSources.sources,
    motifs: state.storeRejets.motifs
  };
};

export default connect(mapStateToProps, {
  searchRejets,
  setAriane,
  setAllFiltres,
  loadAnnees,
  loadTypesOp,
  loadSources,
  loadMotifs,
  setAllSorts,
  loadSourceUser
})(ListeRejets);
