import React, { useEffect } from "react";

import Filtres from "components/Listes/Filtres";
import ListeHeader from "components/Listes/ListeHeader";
import ListeRejetsRecherche from "components/Listes/ListeRejetsRecherche";
import { etatRejetToLibelle } from "utils";
import { completeSources, completeTypesOp, completeAnnees } from "components/utils/";

const filtresExistant = {
  mot: { titre: "Id. UL", exemple: "498418334" },
  annee: {
    titre: "Millésime",
    liste: [],
  },
  typeop: {
    titre: "Type d'opération",
    liste: [],
  },
  etat: {
    titre: "État du rejet",
    liste: [
      { libelle: "Tous", value: null },
      { libelle: etatRejetToLibelle(1), value: "1" },
      { libelle: etatRejetToLibelle(2), value: "2" },
      { libelle: etatRejetToLibelle(3), value: "3" },
    ],
  },
  source: {
    titre: "Source",
    liste: [],
  },
  code: {
    titre: "Motif",
    liste: [],
  },
};
let champsTris = [
  { libelle: "Millésime", value: "annee" },
  { libelle: "Type d'opération", value: "type" },
  { libelle: "Siren cédant", value: "sirenc" },
  { libelle: "Siren bénéficiaire", value: "sirenb" },
  { libelle: "Montant net", value: "montant" },
  { libelle: "Date de réalisation juridique", value: "realisation" },
  { libelle: "Date d'effet comptable", value: "effet" },
  { libelle: "Motif de rejet", value: "code" },
  { libelle: "Etat du rejet", value: "etatRejet" },
  { libelle: "Date traitement du rejet", value: "traitement" },
];

const ListeRejets = (props) => {
  const {
    loadAnnees,
    loadTypesOp,
    loadSources,
    loadMotifs,
    loadSourceUser,
    setAllFiltres,
    searchRejets,
    setAriane,
    filtres,
    page,
    size,
    sort,
    setAllSorts,
    sources,
    typesOp,
    annees,
    motifs,
  } = props;

  useEffect(() => {
    setAriane([{ libelle: "Opérations rejetées" }]);
  }, [setAriane]);

  // On charge une fois les données annexes
  useEffect(() => {
    loadAnnees();
    loadTypesOp();
    loadSources();
    loadMotifs();
    loadSourceUser();
  }, [loadAnnees, loadTypesOp, loadSources, loadMotifs, loadSourceUser]);

  // On charge la liste des rejets
  useEffect(() => {
    if (page) {
      searchRejets(filtres, page, size, [...sort, "didRejet"]);
    }
  }, [searchRejets, filtres, page, size, sort]);

  // Chargement du tri
  useEffect(() => {
    setAllSorts(champsTris);
  }, [setAllSorts]);

  // Chargement des filtres avec les années
  useEffect(() => {
    
    let newFiltres = structuredClone(filtresExistant);
    // completeSources
    newFiltres.source.liste = completeSources(sources);

    // completeTypeOp
    newFiltres.typeop.liste = completeTypesOp(typesOp);
    
    // completeAnnees
    newFiltres.annee.liste = completeAnnees(annees);;
 
    // completeMotifs
    newFiltres.code.liste = [
      { libelle: "Tous", value: null },
      ...motifs
        .map((m) => {
          let lib = m.libelleRejet.replace("%s", "XXX").replace("%d", "N");
          if (lib.length > 55) {
            lib = lib.substring(0, 55) + "...";
          }
          return {
            libelle: lib,
            value: m.codeRejet,
          };
        })
        .sort(),
    ];
    setAllFiltres(newFiltres);

  }, [setAllFiltres, sources, typesOp, annees, motifs]);

  return (
    <div className="block-main">
      <Filtres />
      <div className="block-content">
        <ListeHeader type="rejet" />
        <ListeRejetsRecherche />
      </div>
    </div>
  );
};

export default ListeRejets;
