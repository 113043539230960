import { connect } from "react-redux";
import ListFiltres from "./ListFiltres";
import { addFiltre, removeFiltre } from "redux/actions";

const mapStateToProps = state => {
  return {
    filtres: state.storeFiltre.filtres
  };
};

export default connect(
  mapStateToProps,
  {
    addFiltre,
    removeFiltre
  }
)(ListFiltres);
