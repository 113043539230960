import React, { useEffect } from "react";

import Filtres from "components/Listes/Filtres";
import ListeHeader from "components/Listes/ListeHeader";
import ListeFichesRecherche from "components/Listes/ListeFichesRecherche";
import { naf } from "components/utils/naf";
import { completeAnnees } from "components/utils/";

const filtresExistant = {
  mot: { titre: "Id. UL", exemple: "750880494" },
  rs: { titre: "Raison sociale", exemple: "PEUGEOT PSA" },
  annee: {
    titre: "Millésime",
    liste: [],
  },
  ape: {
    titre: "Code APE Siren",
    liste: naf,
    exemple: "0220Z",
  },
};
let champsTris = [
  { libelle: "Identifiant de la fiche", value: "nomFiche" },
  { libelle: "Millésime", value: "annee" },
  { libelle: "Siren cédant", value: "sirenc" },
  { libelle: "Siren bénéficiaire", value: "sirenb" },
  { libelle: "Code APE cédante", value: "apec" },
  { libelle: "Code APE bénéficiaire", value: "apeb" },
];

const ListeFiches = (props) => {
  const {
    loadAnnees,
    annees,
    setAriane,
    setAllFiltres,
    filtres,
    page,
    size,
    sort,
    setAllSorts,
    searchFiches,
  } = props;

  // Chargement initial
  useEffect(() => {
    loadAnnees();
    setAriane([{ libelle: "Fiches TSE2G" }]);
    setAllSorts(champsTris);
  }, [loadAnnees, setAriane, setAllSorts]);

  // Chargement des filtres avec les années
  useEffect(() => {
    let newFiltres = structuredClone(filtresExistant);
    newFiltres.annee.liste = completeAnnees(annees);;
    setAllFiltres(newFiltres);
  }, [setAllFiltres, annees]);

  // Recherche des fiches
  useEffect(() => {
    searchFiches(filtres, page, size, sort);
  }, [searchFiches, filtres, page, size, sort]);

  return (
    <div className="block-main">
      <Filtres />
      <div className="block-content">
        <ListeHeader type="fiche" />
        <ListeFichesRecherche />
      </div>
    </div>
  );
};

export default ListeFiches;
