import React from "react";

export const values = [
  "00",
  "01",
  "02",
  "03",
  "11",
  "12",
  "21",
  "22",
  "31",
  "32",
  "41",
  "42",
  "51",
  "52",
  "53",
];

export const getLibelle = (tranche) => {
  switch (tranche) {
    case "00":
      return "0 salarié";
    case "01":
      return "1 ou 2 salariés";
    case "02":
      return "3 à 5 salariés";
    case "03":
      return "6 à 9 salariés";
    case "11":
      return "10 à 19 salariés";
    case "12":
      return "20 à 49 salariés";
    case "21":
      return "50 à 99 salariés";
    case "22":
      return "100 à 199 salariés";
    case "31":
      return "200 à 249 salariés";
    case "32":
      return "250 à 499 salariés";
    case "41":
      return "500 à 999 salariés";
    case "42":
      return "1 000 à 1 999 salariés";
    case "51":
      return "2 000 à 4 999 salariés";
    case "52":
      return "5 000 à 9 999 salariés";
    case "53":
      return "10 000 salariés et plus";
    default:
      return "";
  }
};

const TrancheEffectif = (props) => {
  const tranche = props.value;
  return <span>{getLibelle(tranche)}</span>;
};

export default TrancheEffectif;
