import { connect } from "react-redux";
import Rapports from "./Rapports";
import { setAriane } from "redux/actions";

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  {
    setAriane
  }
)(Rapports);
