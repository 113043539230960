import { connect } from "react-redux";
import CommentairesEnveloppe from "./CommentairesEnveloppe";
import {
  setAriane,
  loadCommentairesEnveloppe,
  setAttache,
  getEnveloppe,
  setVueEntrepise
} from "redux/actions";

const mapStateToProps = state => {
  return {
    enveloppe: state.storeEnveloppes.enveloppe,
    commentaires: state.storeCommentaires.commentaires,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {
    setAriane,
    loadCommentairesEnveloppe,
    setAttache,
    getEnveloppe,
    setVueEntrepise
  }
)(CommentairesEnveloppe);
