import { connect } from "react-redux";
import Enveloppe from "./Enveloppe";
import {
  setAriane,
  getEnveloppe,
  filtreOperations,
  setFiltres,
  setVueEntrepise
} from "redux/actions";

const mapStateToProps = state => {
  return {
    enveloppe: state.storeEnveloppes.enveloppe,
    page: state.storeFiltre.page,
    size: state.storeFiltre.size,
    sort: state.storeFiltre.sort,
    operations: state.storeOperations.operationst,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {
    setAriane,
    getEnveloppe,
    filtreOperations,
    setFiltres,
    setVueEntrepise
  }
)(Enveloppe);
