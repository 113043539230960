import { React, useEffect } from "react";
import { Paper, IconButton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeftCircle, LogoutVariant } from "mdi-material-ui";
import { logoutKeycloak } from "redux/api";

import Ariane from "./Ariane";
import "./subheader.scss";

const SubHeader = (props) => {
  const { loadUserInfo } = props;
  useEffect(() => {
    loadUserInfo();
  }, [loadUserInfo]);
  const location = useLocation();
  const navigate = useNavigate();
  const prec = () => {
    const ariane = props.ariane;
    if (ariane.length > 1) {
      const lien = ariane[ariane.length - 2];
      if (lien.link) {
        navigate(lien.link);
      }
    }
  };
  const handleClickLogout = async () => {
    logoutKeycloak();
  }

  if (!props.user) {
    return <Paper />;
  } else {
    return (
      <Paper className="subheader">
        {props.ariane.length > 1 && (
          <IconButton className="prec" aria-label="precedent" onClick={prec}>
            <ArrowLeftCircle />
          </IconButton>
        )}
        <Ariane location={location} />
        <div id="userName" className="userName">
          {props.user.name} / {props.user.timbre}

         <IconButton size="small" aria-label="logout" onClick={handleClickLogout} sx={{padding: 0}}>
           <LogoutVariant />
         </IconButton>
        </div>
      </Paper>
    );
  }
};

export default SubHeader;
