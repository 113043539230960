import * as types from "redux/actions/constantes";

const initial = {
  originesInfo: [],
  types: [],
  commentaires: [],
  attache: {},
  loading: false
};

const commentaires = (state = initial, action = { type: "" }) => {
  switch (action.type) {
    case types.ORIGINES_LOADED: {
      return { ...state, originesInfo: action.payload };
    }
    case types.TYPES_LOADED: {
      return { ...state, types: action.payload };
    }
    case types.COMMENTAIRES_LOADED: {
      return {
        ...state,
        commentaires: action.payload,
        loading: false
      };
    }
    case types.COMMENTAIRES_LOADING: {
      return { ...state, commentaires: [], loading: true };
    }
    case types.COMMENTAIRE_ATTACHE: {
      return { ...state, attache: action.payload };
    }
    case types.COMMENTAIRE_SAVED: {
      const commentaire = action.payload;
      if (commentaire.commentaireParent) {
        const commentairesState = state.commentaires;
        commentairesState.forEach(c => {
          if (c.idCommentaire === commentaire.commentaireParent) {
            c.complements.commentaire = [
              ...c.complements.commentaire,
              commentaire
            ];
          }
        });
        return { ...state, commentaires: [...commentairesState] };
      }
      return { ...state, commentaires: [commentaire, ...state.commentaires] };
    }
    default:
      return state;
  }
};
export default commentaires