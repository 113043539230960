import React, { useEffect, useState } from "react";
import { TextField, MenuItem, Grid, Button } from "@mui/material";
import { withStyles } from "@mui/styles";

import { getStyle } from "components/utils/Input";

const libelles = {
  typeCommentaire: "Type de commentaire",
  origineInfo: "Origine de l'information",
};

const CreationCommentaire = (props) => {
  const {
    commentaire,
    loadOrigineInfoCommentaires,
    loadTypesCommentaires,
    onSubmit,
    classes,
    user,
    style,
    types,
    originesInfo,
    cancel,
    cancelTxt,
    submitTxt,
  } = props;

  const [commentaireState, setCommentaireState] = useState(
    commentaire || { typeCommentaire: "", origineInfo: "" }
  );

  // Chargement initial
  useEffect(() => {
    loadOrigineInfoCommentaires();
    loadTypesCommentaires();
  }, [loadOrigineInfoCommentaires, loadTypesCommentaires]);

  useEffect(() => {
    if (types && types.length > 0 && commentaireState.typeCommentaire === "") {
      const commentaireModif = { ...commentaireState };
      commentaireModif.typeCommentaire = user.roles.includes("Consultation_Citrus")
        ? 1
        : 2;
      setCommentaireState(commentaireModif);
    }
  }, [commentaireState, types, user.roles]);

  const onSubmitAction = () => {
    onSubmit(commentaireState);
  };

  const onChange = (key, e) => {
    const commentaireModif = { ...commentaireState };
    commentaireModif[key] = e;
    setCommentaireState(commentaireModif);
  };

  const getSelectField = (key, liste) => {
    const value = commentaireState ? commentaireState[key] : "";
    return (
      <TextField
        required
        label={libelles[key]}
        select
        value={value}
        fullWidth
        onChange={(e) => onChange(key, e.target.value)}
        inputProps={{
          name: key,
          id: key,
        }}
        InputLabelProps={{
          classes: {
            asterisk: classes.asterisk,
          },
        }}
        margin="normal"
        style={{ display: "flex", ...style }}
      >
        {liste.map((t, i) => {
          return (
            <MenuItem key={i} value={t.code}>
              {t.libelle}
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  const CODE_ORIGIN_AUTRE = 5; // code de "Autre"
  const originAutre =
    commentaireState && commentaireState.origineInfo === CODE_ORIGIN_AUTRE;
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        {getSelectField("typeCommentaire", types)}
      </Grid>
      <Grid item xs={6}>
        {getSelectField("origineInfo", originesInfo)}
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Texte du commentaire"
          required={originAutre}
          value={commentaireState ? commentaireState.contenu : ""}
          multiline
          fullWidth
          onChange={(e) => onChange("contenu", e.target.value)}
          inputProps={{
            name: "commentaire",
            id: "commentaire",
            maxLength: 1000,
          }}
          margin="normal"
          style={{ display: "flex", ...style }}
        />
      </Grid>
      <Grid item xs={12}>
        {cancel && (
          <Button
            variant="contained"
            style={{ float: "right", margin: "10px" }}
            onClick={cancel}
          >
            {cancelTxt || "Annuler"}
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          style={{ display: "block", margin: "10px" }}
          disabled={
            commentaireState.origineInfo === "" ||
            (originAutre && !commentaireState.contenu)
          }
          onClick={onSubmitAction}
        >
          {submitTxt || "Ok"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(getStyle)(CreationCommentaire);
