const CONFIG_PATH = `${window.location.origin}/configuration.json`;

let config;

export const getConfig = () =>
  config
    ? Promise.resolve(config)
    : fetch(CONFIG_PATH).then(reponse => {
        config = reponse.json();
        return config;
      });
