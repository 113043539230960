import React from "react";

import Operation from "components/Operation";

const HistoriqueCreations = (props) => {
  const { creations } = props;

  if (creations && creations.length > 0) {
    return (
      <>
        <h3>Historique des créations</h3>
        {creations.map((operation, index) => (
          <Operation
            key={index}
            titre={index === 0}
            operation={operation}
            action={false}
          />
        ))}
      </>
    );
  }
  return null;
};

export default HistoriqueCreations;
