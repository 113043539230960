import { connect } from "react-redux";
import ListeOperations from "./ListeOperations";
import {
  searchOperations,
  setAriane,
  setVueEntrepise,
  setAllFiltres,
  loadAnnees,
  loadTypesOp,
  loadSources,
  setAllSorts
} from "redux/actions";

const mapStateToProps = state => {
  return {
    loading: state.storeOperations.loading,
    operations: state.storeOperations.operations,
    filtres: state.storeFiltre.filtres,
    page: state.storeFiltre.page,
    size: state.storeFiltre.size,
    sort: state.storeFiltre.sort,
    annees: state.storeAnnees.annees,
    typesOp: state.storeTypesOp.typesOp,
    sources: state.storeSources.sources,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(mapStateToProps, {
  searchOperations,
  setAriane,
  setAllFiltres,
  loadAnnees,
  loadTypesOp,
  loadSources,
  setAllSorts,
  setVueEntrepise
})(ListeOperations);
