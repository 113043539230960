import React from "react";
import { CSVLink } from "react-csv";
import { CircularProgress } from "@mui/material";
import { FileDelimitedOutline, FileDownloadOutline } from "mdi-material-ui";

import { getLibelle as getLibelleTranchesEffectif } from "components/utils/TrancheEffectif";
import { getPublicId } from "utils/enveloppe-utils";

import "./CSVExport.scss";

const headersOperation = [
  { label: "anneeCampagne", key: "anneeCampagne" },
  { label: "sirenCedant", key: "uniteCedante.siren" },
  { label: "raisonSocialeCedant", key: "uniteCedante.raisonSociale" },
  { label: "apeCedant", key: "uniteCedante.ape" },
  { label: "groupe_id_cedant", key: "uniteCedante.groupe.groupeCode" },
  { label: "sirenBeneficiaire", key: "uniteBeneficiaire.siren" },
  {
    label: "raisonSocialeBeneficiaire",
    key: "uniteBeneficiaire.raisonSociale",
  },
  { label: "apeBeneficiaire", key: "uniteBeneficiaire.ape" },
  { label: "groupe_id_beneficiaire", key: "uniteBeneficiaire.groupe.groupeCode" },
  {
    label: "typeOperation",
    key: "codeTypeOperation.libelle",
  },
  { label: "montantNet", key: "montantNet" },
  { label: "enveloppe", key: "enveloppe.publicId", concerne: "ul" },
  { label: "apeEnveloppe", key: "enveloppe.ape", concerne: "ul" },
  {
    label: "enveloppeEntreprise",
    key: "enveloppeEntreprise.publicId",
    concerne: "entreprise",
  },
  {
    label: "apeEnveloppeEntreprise",
    key: "enveloppeEntreprise.ape",
    concerne: "entreprise",
  },
  { label: "dateEffetComptable", key: "dateEffetComptable" },
  { label: "dateRealisationJuridique", key: "dateRealisationJuridique" },
  { label: "dateCreation", key: "dateCreation" },
  { label: "dateModification", key: "dateModification" },
  { label: "source", key: "source.libelle" },
  { label: "etat", key: "etat" },
  { label: "nombreCommentaire", key: "nbCommentaire" },
];
const headersEnveloppe = [
  { label: "anneeCampagne", key: "anneeCampagne" },
  { label: "publicId", key: "publicId" },
  { label: "ape", key: "ape" },
  { label: "typeRestructuration", key: "typeEnveloppe.libelle" },
  { label: "coeffAgregation", key: "coeffAgregation" },
  { label: "codeTrancheEffectif", key: "trancheEffectif" },
  { label: "trancheEffectif", key: "libelleTrancheEffectif" },
  { label: "nombreCommentaire", key: "nbCommentaire" },
];

const headersUnites = [
  { label: "anneeCampagne", key: "anneeCampagne" },
  { label: "siren", key: "siren" },
  { label: "demonination", key: "raisonSociale", concerne: "entreprise" },
  { label: "raisonSociale", key: "raisonSociale", concerne: "ul" },
  { label: "ape", key: "ape" },
  { label: "benefOuCed", key: "benefOuCed" },
  { label: "montantCedes", key: "montantCedes", concerne: "ul" },
  { label: "montantRecus", key: "montantRecus", concerne: "ul" },
  { label: "idEnveloppeUl", key: "idEnveloppe", concerne: "ul" },
  { label: "idEnveloppeEntreprise", key: "idEnveloppeEntreprise" },
  { label: "enveloppeUl", key: "enveloppe", concerne: "ul" },
  { label: "nombreCommentaire", key: "nbCommentaire" },
];

const headersFiches = [
  { label: "Nom", key: "nomFiche" },
  { label: "annee", key: "operation.anneeCampagne" },
  { label: "sirenCedant", key: "operation.uniteCedante.siren" },
  { label: "raisonSocialeCedant", key: "operation.uniteCedante.raisonSociale" },
  { label: "apeCedant", key: "operation.uniteCedante.ape" },
  { label: "sirenBeneficiaire", key: "operation.uniteBeneficiaire.siren" },
  {
    label: "raisonSocialeBeneficiaire",
    key: "operation.uniteBeneficiaire.raisonSociale",
  },
  { label: "apeBeneficiaire", key: "operation.uniteBeneficiaire.ape" },
  { label: "DateEnregistrement", key: "dateCreation" },
];

const CSVExport = (props) => {
  const {
    type,
    filtres,
    sort,
    vueEntreprise,
    enveloppesExport,
    fichesExport,
    unitesExport,
    operationsExport,
    exportEnveloppes,
    exportUnites,
    exportFiches,
    exportOperations,
    exportHistoriqueOperations,
    exportingEnveloppes,
    exportingUnites,
    exportingFiches,
    exportingOperations
  } = props;

  const getData = () => {
    switch (type) {
      case "enveloppe":
        const enveloppes = enveloppesExport;
        enveloppes.forEach((e) => {
          e.libelleTrancheEffectif = getLibelleTranchesEffectif(
            e.trancheEffectif
          );
          e.typeEnveloppe = e.typeEnveloppe ? e.typeEnveloppe : { libelle: "" };
        });
        return enveloppes;
      case "fiche":
        return fichesExport;
      case "unite":
        const unites = unitesExport;
        unites.forEach((u) => {
          u.enveloppe =
            u.idEnveloppe && getPublicId(u.idEnveloppe, u.anneeCampagne);
          u.benefOuCed = "BC";
          if (u.isCedante === 0) {
            u.benefOuCed = "B";
          } else if (u.isBeneficiaire === 0) {
            u.benefOuCed = "C";
          }
        });
        return unites;
      default:
        const operations = operationsExport;
        operations.forEach((o) => {
          o.enveloppe = o.enveloppe !== null ? o.enveloppe : {};
          o.enveloppeEntreprise =
            o.enveloppeEntreprise !== null ? o.enveloppeEntreprise : {};
        });
        return operations;
    }
  };

  const getExportFunction = () => {
    switch (type) {
      case "enveloppe":
        return exportEnveloppes(filtres, sort, vueEntreprise);
      case "unite":
        return exportUnites(filtres, sort, vueEntreprise);
      case "fiche":
        return exportFiches(filtres, sort);
      case "historique":
        return exportHistoriqueOperations();
      default:
        return exportOperations(
          filtres,
          [...sort, "didOperation"],
          vueEntreprise
        );
    }
  };

  const getHeader = () => {
    switch (type) {
      case "enveloppe":
        return headersEnveloppe;
      case "fiche":
        return headersFiches;
      case "unite":
        if (vueEntreprise) {
          return headersUnites.filter((h) => h.concerne !== "ul");
        }
        return headersUnites.filter((h) => h.concerne !== "entreprise");
      default:
        if (vueEntreprise) {
          return headersOperation.filter((h) => h.concerne !== "ul");
        }
        return headersOperation.filter((h) => h.concerne !== "entreprise");
    }
  };

  const getExporting = () => {
    switch (type) {
      case "enveloppe":
        return exportingEnveloppes;
      case "unite":
        return exportingUnites;
      case "fiche":
        return exportingFiches;
      default:
        return exportingOperations;
    }
  };

  const data = getData();
  if (getExporting()) {
    return (
      <div className="exportCSV">
        <CircularProgress size={24} />
      </div>
    );
  } else {
    if (data.length === 0) {
      return (
        <CSVLink
          data={[]}
          className="exportCSV"
          onClick={() => {
            getExportFunction();
            return false;
          }}
        >
          Exporter : <FileDelimitedOutline />
        </CSVLink>
      );
    } else {
      return (
        <CSVLink
          data={data}
          filename={"export-citrus.csv"}
          headers={getHeader()}
          className="exportCSV"
        >
          <b>Télécharger le fichier </b>
          <FileDownloadOutline />
        </CSVLink>
      );
    }
  }
};

export default CSVExport;
