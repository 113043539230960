import React from "react";
import { TextField } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

const Field = (props) => {

    const { classes, valide, value, modification, ...inputProps } = props;
    return (
      <>
        <TextField
          margin="normal"
          InputLabelProps={{ classes }}
          value={value ? value : ""}
          {...inputProps}
          className="field"
        />
        {valide && <DoneIcon style={{ color: "forestgreen" }} />}
      </>
    );
}

export default Field;
