import * as types from "redux/actions/constantes";

const initial = {
  loading: false,
  enveloppe: null,
  enveloppes: [],
  total: null,
  enveloppesExport: [],
  exporting: false,
  numLastRequete: null
};
const enveloppes = (state = initial, action = { type: "" }) => {
  switch (action.type) {
    case types.ENVELOPPE_LOADING: {
      return { ...state, loading: true, numLastRequete: action.numRequete };
    }
    case types.ENVELOPPE_FOUND: {
      return { ...state, loading: false, enveloppe: action.enveloppe };
    }
    case types.ENVELOPPES_LOADING: {
      return {
        ...state,
        loading: true,
        enveloppes: [],
        enveloppesExport: []
      };
    }
    case types.ENVELOPPES_FOUND: {
      if (state.numLastRequete > action.numRequete) {
        return state;
      }
      return {
        ...state,
        loading: false,
        enveloppes: [...action.payload.content],
        total: action.payload.totalElements
      };
    }
    case types.ENVELOPPES_EXPORTING: {
      return {
        ...state,
        exporting: true,
        enveloppesExport: []
      };
    }
    case types.ENVELOPPES_EXPORTED: {
      return {
        ...state,
        exporting: false,
        enveloppesExport: [...action.payload.content]
      };
    }
    default:
      return state;
  }
};
export default enveloppes