import * as types from "redux/actions/constantes";

const initial = {
  ariane: []
};

const ariane = (state = initial, action = { type: "" }) => {
  if (action.type === types.SET_ARIANE) {
    return { ariane: action.ariane };
  }
  return state;
};

export default ariane
