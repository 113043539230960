import * as types from "redux/actions/constantes";

const initial = {
  loading: false,
  annees: []
};

const annees = (state = initial, action = { type: "" }) => {
  switch (action.type) {
    case types.ANNEES_LOADING: {
      return { loading: true, annees: [] };
    }
    case types.ANNEES_LOADED: {
      const anneesAction = action.payload.annee
        .filter(a => a <= new Date().getFullYear())
        .sort(function(a, b) {
          return b - a;
        });
      return { loading: false, annees: anneesAction };
    }
    default:
      return state;
  }
};

export default annees
