import { React, useEffect, useMemo } from "react";
import { ListItem, List } from "@mui/material";
import queryString from "query-string";
import { Link, useSearchParams, useLocation } from "react-router-dom";

import "./numPage.scss";

const libelles = { prec: "Précédent", suiv: "Suivant" };

const calculeNombrePage = (nbTotal, size) => {
  let nbPage = null;
  if (nbTotal && size) {
    nbPage = Math.trunc(nbTotal / size);
    if (nbTotal % size !== 0) {
      nbPage = nbPage + 1;
    }
  }
  return nbPage;
};

const NumPage = (props) => {

  const nbPage = useMemo(() => calculeNombrePage(props.total, props.size), [props.size, props.total]);
  const location = useLocation();
  const { page, setPage } = props;
  
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParams = searchParams.get("page");

  // Modifiation de l'url
  useEffect(() => {

      // Si aucune page est recherchée, on lance la recherche
      // ou si l'on n'est pas sur le bon numéro de page
      if (!pageParams || !page || parseInt(pageParams) !== page || (nbPage && (page > nbPage))) {

        // La page a recherchée est celle de l'url (sauf si null alors 1 ou si dépassement)

        // On vérifie que la page n'a pas dépassée le nombre max de pages
        let pageARechercher = pageParams ? parseInt(pageParams) : 1;
        pageARechercher = nbPage ? Math.min(pageARechercher, nbPage) : pageARechercher;

        // On lance l'action dispatch types.SET_PAGE
        setPage(pageARechercher);
        
      }
  }, [props.total, props.size, page, setPage, nbPage, pageParams, setSearchParams]);

  const handleClick = (pageClick) => {
    props.setPage(pageClick);
  };

  const toLink = (libelle, pageLink) => {
    let search = queryString.parse(location.search);
    return (
      <Link
        to={{
          ...location,
          search: queryString.stringify({ ...search, page: pageLink }),
        }}
        onClick={() => handleClick(pageLink)}
      >
        {libelle}
      </Link>
    );
  };

  let pages = [];
  for (let i = 1; i < nbPage + 1; i++) {
    if (
      i === 1 ||
      (i > page - 3 && i < page + 3) ||
      i === nbPage
    ) {
      pages.push(
        <ListItem key={i} className={i === page ? "active" : "notActive"}>
          {toLink(i, i)}
        </ListItem>
      );
    } else if (i === page - 3 || i === page + 3) {
      pages.push(<ListItem key={i}>...</ListItem>);
    }
  }

  return (
    <div className="divnumpage">
      <List className="numPage">
        {page > 1 && (
          <ListItem>{toLink(libelles.prec, page - 1)}</ListItem>
        )}
        {nbPage > 1 && pages}
        {nbPage > 1 && page < nbPage && (
          <ListItem>{toLink(libelles.suiv, page + 1)}</ListItem>
        )}
      </List>
    </div>
  );
};

export default NumPage;
