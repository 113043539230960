import * as React from 'react';
import moment from "moment";

export const getOperationLastAnnee = operations => {
  return operations.reduce(
    (max, o) => (o.anneeCampagne > max.anneeCampagne ? o : max),
    operations[0]
  );
};

export const formatsDate = {
  formatDateWS: "DD-MM-YYYY",
  formatDateFront: "DD/MM/YYYY"
};

export const changeFormatDate = date => {
  if (date) {
    return moment(date, formatsDate.formatDateWS).format(
      formatsDate.formatDateFront
    );
  }
  return "aucune";
};

export const etatOpToLibelle = etat => {
  switch (etat) {
    case "T":
      return "Expertisée";
    case "R":
      return "Redressée";
    default:
      return "Automatique";
  }
};
export const etatRejetToLibelle = etat => {
  switch (etat) {
    case 1:
      return "En attente";
    case 2:
      return "Corrigé";
    case 3:
      return "Rejeté";
    default:
      return "?";
  }
}

const getMetaText = (metadatas) => {
  let metadataText = [];
  if (metadatas && metadatas.length > 0) {
    metadatas.forEach((meta, key) => {
      if (meta.ancienneValeur) {
        metadataText.push(getOneMetaData(meta, key))}
      }
    );
  }
  if (metadataText.length === 0) {
    metadataText = "";
  }
  return metadataText;
};

const getOneMetaData = (meta, key) => {
   const ancienneValeur = meta.ancienneValeur ? meta.ancienneValeur : "N/A";
   return <React.Fragment key={key}>{"Valeur avant le "}{changeFormatDate(meta.dateModification)}{" : "}{ancienneValeur}<br/></React.Fragment>;
};

/**
 * On revoit le texte de l'info bulle en utilisant le metadata
 * voir https://mui.com/material-ui/react-tooltip/
 */
export const getHistoFromMetadata = (titre, colonne, operation) => {
  let resultat = "";
  if (operation.metadatas) {
    const metadatas = operation.metadatas.metadata.filter(meta => meta.colonne === colonne);
    if (titre) {
      resultat = (<React.Fragment>{ titre }<br/>{ getMetaText(metadatas) }</React.Fragment>);
    } else {
      resultat = getMetaText(metadatas);
    }
  }
  return resultat;
};
