import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Input,
} from "@mui/material";
import { NumericFormat } from 'react-number-format';

import "./pageSize.scss";

const libelle = "résultats à afficher par page";
const propositions = [5, 10, 20, 50, 100];

const PageSize = (props) => {
  const { setPageSize, total } = props;

  const [pageSizeState, setPageSizeState] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const size = searchParams.get("size");


  // On set PageSize en fonction de la recherche
  useEffect(() => {
    let sizeSafe = size ? parseInt(size) : 20;
    if (!pageSizeState || pageSizeState !== sizeSafe) {
      setPageSize(sizeSafe);
      setPageSizeState(sizeSafe);
    }
  }, [setPageSize, pageSizeState, size]);

  // On change l'url lorsque l'on change le nombre de lignes par page
  const handleChange = (event) => {
    setSearchParams((currentSearchParams) => {
      const newSearchParams = new URLSearchParams(currentSearchParams);
      newSearchParams.set("size", event.target.value);
      return newSearchParams;
    });

  };

  const sizes = [];
  let maxProposition = 5;
  propositions.some((p) => {
    maxProposition = p;
    sizes.push(
      <MenuItem key={p} value={p}>
        {p}
      </MenuItem>
    );
    return p >= total;
  });

  let nbTotal = (
    <NumericFormat value={total} displayType={"text"} thousandSeparator={" "} />
  );

  if (sizes.length > 1) {
    const value = Math.min(maxProposition, props.size);
    return (
      <div className="pageSize">
        <FormControl>
          <Select
            value={value}
            onChange={handleChange}
            input={<Input name="page-size" id="page-size-label" />}
            displayEmpty
            name="size"
          >
            {sizes}
          </Select>
        </FormControl>
        <InputLabel htmlFor="page-size-label">
          {libelle} sur {nbTotal} pour votre recherche.
        </InputLabel>
      </div>
    );
  }
  if (total === 1) {
    return (
      <div className="pageSize">
        <InputLabel htmlFor="page-size-label">
          1 résultat pour votre recherche.
        </InputLabel>
      </div>
    );
  }
  if (total > 1) {
    return (
      <div className="pageSize">
        <InputLabel htmlFor="page-size-label">
          {nbTotal} résultats pour votre recherche.
        </InputLabel>
      </div>
    );
  }
  return (
    <div className="pageSize">
      <InputLabel htmlFor="page-size-label">
        0 résultat pour votre recherche.
      </InputLabel>
    </div>
  );
};

export default PageSize;
