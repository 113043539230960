import React from "react";

import Rejet from "components/Rejet";
import ListeScroll from "../ListeScroll";

const ListeRejetsRecherche = (props) => {

  const rejets = props.rejets;
  return (
    <ListeScroll
      getContent={() =>
        rejets?.map((rejet, index) => (
          <Rejet key={rejet.idRejet} titre={index === 0} rejet={rejet} />
        ))
      }
    />
  );
};

export default ListeRejetsRecherche;
