import { connect } from "react-redux";
import ListeUnites from "./ListeUnites";
import {
  searchUnites,
  setAriane,
  setVueEntrepise,
  setAllFiltres,
  loadAnnees,
  setAllSorts
} from "redux/actions";

const mapStateToProps = state => {
  return {
    loading: state.storeOperations.loading,
    filtres: state.storeFiltre.filtres,
    page: state.storeFiltre.page,
    size: state.storeFiltre.size,
    sort: state.storeFiltre.sort,
    annees: state.storeAnnees.annees,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {
    searchUnites,
    setAriane,
    setVueEntrepise,
    setAllFiltres,
    loadAnnees,
    setAllSorts
  }
)(ListeUnites);
