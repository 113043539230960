import { connect } from "react-redux";
import TopHeader from "./TopHeader";

const mapStateToProps = state => {
  return {
    user: state.storeUser.user
  };
};

export default connect(mapStateToProps)(TopHeader);
