import React from "react";

import { CommentaireTexte } from "components/utils";

const libelle = {
  contenu: "Commentaire",
  type: "Type",
  origineInfo: "Origine",
};

const InfoCommentaire = (props) => {
  const { commentaire, types, originesInfo } = props;

  const type = types.filter((t) => t.code === commentaire.typeCommentaire);
  const origine = originesInfo.filter(
    (o) => o.code === commentaire.origineInfo
  );
  if (commentaire && commentaire.contenu) {
    return (
      <dl className="entete">
        <dt>{libelle.type} :</dt>
        <dd>{type.length === 1 ? type[0].libelle : null}</dd>
        <dt>{libelle.origineInfo} :</dt>
        <dd>{origine.length === 1 ? origine[0].libelle : null}</dd>
        <br />
        <dt>{libelle.contenu} :</dt>
        <dd className="commentaireTexte">
          <CommentaireTexte value={commentaire.contenu} />
        </dd>
      </dl>
    );
  }
  return <></>;
};

export default InfoCommentaire;
