import { connect } from "react-redux";
import Header from "./Header";

//Indique si un des stores est en chargement
const isLoading = state => {
  let chargementEnCours = false;
  Object.keys(state).forEach(store => {
    if (state[store].loading) {
      chargementEnCours = true;
    }
  });
  return chargementEnCours;
};

const mapStateToProps = state => {
  return {
    loading: isLoading(state)
  };
};

export default connect(mapStateToProps)(Header);
