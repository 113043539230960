import React, { useEffect } from "react";

import Filtres from "components/Listes/Filtres";
import ListeHeader from "components/Listes/ListeHeader";
import ListeUnitesRecherche from "components/Listes/ListeUnitesRecherche";
import { naf } from "components/utils/naf";
import { completeAnnees } from "components/utils/";
import { useParams } from "react-router-dom";

const filtresExistant = {
  mot: { titre: "Id. UL / Env.", exemple: "498418334" },
  rs: { titre: "Raison sociale", exemple: "PEUGEOT PSA" },
  annee: {
    titre: "Millésime",
    liste: []
  },
  ape: {
    titre: "APE",
    liste: naf,
    exemple: "0220Z"
  },
  benefOuCed: {
    titre: "Bénéficiaire ou cédante",
    liste: [
      { libelle: "Bénéficiaire", value: "B" },
      { libelle: "Cédante", value: "C" }
    ]
  }
};
let champsTris = [
  { libelle: "Siren", value: "siren" },
  { libelle: "APE", value: "ape" },
  { libelle: "Position dans l'opération", value: "benefOuCed" },
  { libelle: "Montant net des apports cédés en k€", value: "montantCedes" },
  { libelle: "Montant net des apports reçus en k€", value: "montantRecus" },
  { libelle: "Enveloppe en unités légales", value: "idEnveloppe" },
 // { libelle: "Enveloppe en entreprises", value: "idEnveloppeEntreprise" },
  { libelle: "Millésime", value: "annee" },
  { libelle: "Nombre de commentaires", value: "nbCommentaire" }
];

const ListeUnites = (props) => {

  const {
    loadAnnees,
    annees,
    setAriane,
    setVueEntrepise,
    setAllFiltres,
    vueEntreprise,
    searchUnites,
    filtres,
    page,
    size,
    sort,
    setAllSorts,
  } = props;

  const {entreprise } = useParams();

  useEffect(() => {
    loadAnnees();
  }, [loadAnnees,]);

  useEffect(() => {
    searchUnites(
      filtres,
      page,
      size,
      sort,
      vueEntreprise
    );
  }, [searchUnites, filtres, page, size, sort, vueEntreprise]);

  useEffect(() => {
    const isVueEntreprise = entreprise === "entreprise";
    setVueEntrepise(isVueEntreprise);

    setAriane([
      {
        libelle: `Liste des ${
          isVueEntreprise ? "entreprises " : "unités légales"
        }`
      }
    ]);
    filtresExistant.rs.titre = isVueEntreprise ? "Dénomination " : "Raison sociale";
    filtresExistant.mot.titre = isVueEntreprise ? "Id. UL / Ent. / Env." : "Id. UL / Env."
    champsTris.splice(0, 1, {
      libelle: isVueEntreprise ? "Entreprise" : "Siren",
      value: "siren"
    });

    setAllSorts(champsTris);
  }, [
    setVueEntrepise,
    filtres,
    entreprise,
    setAllSorts,
    setAriane,
  ]);

  // Chargement des filtres avec les années
  useEffect(() => {
    let newFiltres = structuredClone(filtresExistant);
    newFiltres.annee.liste = completeAnnees(annees);;
    setAllFiltres(newFiltres);
  }, [setAllFiltres, annees]);

    return (
      <div className="block-main">
        <Filtres />
        <div className="block-content">
          <ListeHeader type="unite" />
          <ListeUnitesRecherche />
        </div>
      </div>
    );
}

export default ListeUnites;
