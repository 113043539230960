import * as types from "./constantes";
import {
  getOrigineInfoCommentaires,
  getTypesCommentaires,
  getCommentairesOperation,
  getCommentairesEnveloppe,
  getCommentairesUnite,
  saveCommentaire
} from "redux/api";

export const loadOrigineInfoCommentaires = () => (dispatch, getState) => {
  if (getState().storeCommentaires.originesInfo.length === 0) {
    return getOrigineInfoCommentaires().then(response => {
      dispatch({ type: types.ORIGINES_LOADED, payload: response });
    });
  }
  return null;
};
export const loadTypesCommentaires = () => (dispatch, getState) => {
  if (getState().storeCommentaires.types.length === 0) {
    return getTypesCommentaires().then(response => {
      dispatch({ type: types.TYPES_LOADED, payload: response });
    });
  }
  return null;
};

const loadCommentaire = (getCommentaires, dispatch) => {
  dispatch({ type: types.COMMENTAIRES_LOADING });
  return getCommentaires().then(
    response => {
      dispatch({
        type: types.COMMENTAIRES_LOADED,
        payload: response.commentaire
      });
    },
    () => dispatch({ type: types.COMMENTAIRES_LOADED, payload: [] })
  );
};

export const loadCommentairesOperation = idOperation => (
  dispatch,
  getState
) => {
  return loadCommentaire(() => getCommentairesOperation(idOperation), dispatch);
};
export const loadCommentairesEnveloppe = (idEnveloppe, vue) => (
  dispatch,
  getState
) => {
  return loadCommentaire(
    () => getCommentairesEnveloppe(idEnveloppe, vue),
    dispatch
  );
};

export const loadCommentairesUnite = (idUnite, vue) => (dispatch, getState) => {
  return loadCommentaire(() => getCommentairesUnite(idUnite, vue), dispatch);
};

export const setAttache = attache => (dispatch, getState) => {
  dispatch({
    type: types.COMMENTAIRE_ATTACHE,
    payload: attache
  });
};
export const addCommentaire = (commentaire, attache) => (
  dispatch,
  getState
) => {
  return saveCommentaire(commentaire).then(response => {
    dispatch({
      type: types.COMMENTAIRE_SAVED,
      payload: { ...response, ...attache }
    });
  });
};
