import { connect } from "react-redux";
import ResumeOperation from "./ResumeOperation";
import { getInfoUnite } from "redux/actions";

const mapStateToProps = state => {
  return {
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(mapStateToProps, { getInfoUnite })(ResumeOperation);
