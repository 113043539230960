import React from "react";

import { Identifiant } from "components/utils";
import Operation from "components/Operation";

const EditionOperation = (props) => {
  const operation = props.operation;
  return (
    <div>
      <h3>
        Une opération existe déjà entre{" "}
        {<Identifiant value={operation.uniteCedante.siren} />} et{" "}
        {<Identifiant value={operation.uniteBeneficiaire.siren} />} en{" "}
        {operation.anneeCampagne}
      </h3>

      <Operation titre={true} operation={operation} action={true} />
    </div>
  );
};

export default EditionOperation;
