import * as types from "./constantes";
import {
  searchOperation,
  saveOperation,
  deleteOperation,
  getUpdatableFields,
  saveCommentaire,
  getOperationIdOperation
} from "redux/api";
import moment from "moment";

let numLastRequete = 0;

export const searchOperations = (filtres, page, size, sort, vueEntreprise) => (
  dispatch,
  getState
) => {
  if (filtres != null) {
    let numRequete = numLastRequete;
    numLastRequete++;
    dispatch({ type: types.OPERATIONS_LOADING, numRequete: numRequete });
    return searchOperation(filtres, page, size, sort, vueEntreprise).then(
      response => {
        dispatch({
          type: types.OPERATIONS_FOUND,
          payload: response,
          numRequete: numRequete
        });
      }
    );
  }
  return null;
};
export const exportOperations = (filtres, sort, entreprise) => (
  dispatch,
  getState
) => {
  if (filtres != null) {
    dispatch({ type: types.OPERATIONS_EXPORTING });
    return searchOperation(filtres, 1, 1000, sort, entreprise).then(
      response => {
        dispatch({ type: types.OPERATIONS_EXPORTED, payload: response });
      }
    );
  }
  return null;
}

export const exportHistoriqueOperations = () => (
  dispatch,
  getState
) => {
    dispatch({ type: types.OPERATIONS_EXPORTING });
    const operations = { content :  getState().storeOperations.operations };
    dispatch({ type: types.OPERATIONS_EXPORTED, payload: operations});
};

export const loadOperation = idOperation => (dispatch, getState) => {
  const operations = getState().storeOperations.operations.filter(
    o => o.idOperation === idOperation
  );
  if (operations.length === 1) {
    dispatch({ type: types.OPERATION_FOUND, payload: operations[0] });
  } else {
    return getOperationIdOperation(idOperation).then(response => {
      dispatch({ type: types.OPERATION_FOUND, payload: response });
    });
  }
  return null;
};

const toOperationRequete = (operation, source) => {
  return {
    source,
    codeTypeOperation: operation.codeTypeOperation.code,
    anneeCampagne: operation.anneeCampagne,
    sirenCedante: operation.uniteCedante.siren,
    sirenBeneficiaire: operation.uniteBeneficiaire.siren,
    montantNet: operation.montantNet,
    dateRealisationJuridique: operation.dateRealisationJuridique,
    dateEffetComptable: operation.dateEffetComptable,
    etatOp: "T"
  };
};

const fromOperationRequete = (
  operation,
  infosUniteCedante,
  infosUniteBeneficiaire
) => {
  return {
    source: operation.source,
    codeTypeOperation: operation.codeTypeOperation,
    anneeCampagne: operation.anneeCampagne,
    uniteCedante: {
      siren: operation.sirenCedante,
      anneeCampagne: operation.anneeCampagne,
      ape: infosUniteCedante ? infosUniteCedante.ape : null,
      raisonSociale: infosUniteCedante ? infosUniteCedante.raisonSociale : null
    },
    uniteBeneficiaire: {
      siren: operation.sirenBeneficiaire,
      anneeCampagne: operation.anneeCampagne,
      ape: infosUniteBeneficiaire ? infosUniteBeneficiaire.ape : null,
      raisonSociale: infosUniteBeneficiaire
        ? infosUniteBeneficiaire.raisonSociale
        : null
    },
    montantNet: operation.montantNet,
    dateRealisationJuridique: operation.dateRealisationJuridique,
    dateEffetComptable: operation.dateEffetComptable,
    etatOp: "T",
    dateCreation: operation.dateCreation
  };
};
export const saveNewOp = (
  operation,
  commentaire,
  infosUniteCedante,
  infosUniteBeneficiaire
) => (dispatch, getState) => {
  return saveOperation(operation).then(reponse => {
    dispatch({
      type: types.OPERATIONS_CREATE,
      operation: fromOperationRequete(
        reponse,
        infosUniteCedante,
        infosUniteBeneficiaire
      )
    });
    commentaire.idDepotOperation = reponse.idDepotOperation;
    saveCommentaire(commentaire);
  });
};

export const save = operation => (dispatch, getState) => {
  const operationRequete = toOperationRequete(
    operation,
    getState().storeUser.source
  );
  return saveOperation(operationRequete).then(response => {
    operation.response = response;
    dispatch({
      type: types.OPERATIONS_UPDATE,
      operation: operation
    });
  });
};

export const loadUpdatableFields = idOperation => (dispatch, getState) => {
  return getUpdatableFields(idOperation).then(reponse => {
    dispatch({
      type: types.OPERATION_UPDATABLE,
      idOperation,
      fields: reponse
    });
  });
};

export const deleteOp = idOperation => (dispatch, getState) => {
  return deleteOperation(idOperation, getState().storeUser.source).then(() => {
    dispatch({ type: types.OPERATIONS_DELETE, idOperation });
  });
};

const getSirenEntreprise = unite => {
  return unite.entrepriseProfilee
    ? unite.entrepriseProfilee.siren
    : unite.siren;
};

const formatsDate = {
  formatDateFront: "DD/MM/YYYY"
};

const formatterDate = date => {
  if (date) {
    return moment(date, formatsDate.formatDateFront)
  }
  return null;
};

const getChampFromColonne = (c, operation) => {
  switch (c) {
    case "apec":
      return operation.uniteCedante.ape;
    case "apeb":
      return operation.uniteBeneficiaire.ape;
    case "sirenc":
      return operation.uniteCedante.siren;
    case "sirenb":
      return operation.uniteBeneficiaire.siren;
    case "entreprisec":
      return getSirenEntreprise(operation.uniteCedante);
    case "entrepriseb":
      return getSirenEntreprise(operation.uniteBeneficiaire);
    case "montant":
      return operation.montantNet;
    case "nbCommentaire":
      return operation.nbCommentaire;
    case "realisation":
      return formatterDate(operation.dateRealisationJuridique);
    case "effet":
        return formatterDate(operation.dateEffetComptable);      
    default:
      return operation.idOperation;
  }
};

export const filtreOperations = (operations, page, size, sort) => (
  dispatch,
  getState
) => {
  dispatch({ type: types.OPERATIONS_LOADING });
  const totalElements = operations.length;
  const tris = [];
  sort.forEach(s => {
    let sens = s.charAt(0) === "a" ? 1 : -1;
    let colonne = s.slice(1);
    tris.push({ sens, colonne });
  });
  operations.sort((a, b) => {
    let compare = 0;
    tris.some(t => {
      let ca = getChampFromColonne(t.colonne, a);
      let cb = getChampFromColonne(t.colonne, b);
      if (ca > cb) {
        compare = t.sens;
      }
      else if (cb > ca) {
        compare = -t.sens;
      }
      return compare !== 0;
    });
    return compare;
  });
  if (page > 0 && size > 0) {
    operations = operations.slice((page - 1) * size, page * size);
  }
  dispatch({
    type: types.OPERATIONS_FOUND,
    payload: { content: operations, totalElements }
  });
};
