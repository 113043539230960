import { connect } from "react-redux";
import ActionOperation from "./ActionOperation";
import { save, deleteOp, loadUpdatableFields, expertise } from "redux/actions";

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  save,
  deleteOp,
  loadUpdatableFields,
  expertise
})(ActionOperation);
