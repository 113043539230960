import React, { useState } from "react";
import { TextField, Tooltip } from "@mui/material";
import moment from "moment";

import { formatsDate } from "utils/";

const InputDate = (props) => {
  const {
    isUpdatable,
    onSubmit,
    value,
    modification,
    label,
    helperText,
    required,
    classes,
    libelle,
    fullWidth,
    autoFocus,
    tooltip
  } = props;

  const [error, setError] = useState(false);

  const handleKeyPress = (e) => {
    if (isUpdatable && !isUpdatable()) {
      e.preventDefault();
    }
  };

  const handleChangeValue = (e) => {
    const valueChange = e.target.value;
    if (valueChange) {
      setError(false);
      const date = moment(valueChange, "YYYY-MM-DD");
      if (date.isValid()) {
        if (date.year() > 2100 || date.year() < 1900 || valueChange.length > 10) {
          setError(true);
          return;
        }
        onSubmit(date.format(formatsDate.formatDateWS));
      }
      return;
    }
    if (required) {
      setError(true);
    } else {
      setError(false);
    }
    onSubmit(null);
  };

  if (modification) {
    return (
      <TextField
        id="date"
        type="date"
        label={label}
        InputLabelProps={{
          shrink: true,
          classes: classes,
        }}
        helperText={error ? "La date est invalide" : helperText}
        defaultValue={value ? value.format("YYYY-MM-DD") : ""}
        onChange={handleChangeValue}
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        required={required}
        error={error}
        margin="none"
        inputProps={{
          "aria-label": libelle,
          size: "8",
          onKeyPress: handleKeyPress,
        }}
      />
    );
  } else {
    return (
      <Tooltip title={tooltip} PopperProps={{ className: "infoBulle" }}>
        <span>
          {value?.isValid() && value?.format(formatsDate.formatDateFront)}
        </span>
      </Tooltip>
    );
  }
};

export default InputDate;
