import * as types from "redux/actions/constantes";

const initial = {
  datas: [],
  operations: null,
  loading: false,
  unites: [],
  total: 0,
  exporting: false,
  unitesExport: [],
  unite: null,
  numLastRequete: null
};

const unites = (state = initial, action = { type: "" }) => {
  switch (action.type) {
    case types.ESANE_DATA_LOADING: {
      let newStateDatas =  structuredClone(state.datas);
      newStateDatas.push(action.unite);
      return { ...state, datas: newStateDatas };
    }
    case types.ESANE_DATA_FOUND: {
      const unite = action.unite;
      const datas = [];
      state.datas.forEach(d => {
        if (d.siren === unite.siren) {
          datas.push({ ...d, ...unite });
        } else {
          datas.push(d);
        }
      });
      return { ...state, datas };
    }
    case types.ESANE_DATA_RESET: {
      return { ...state, datas: [] };
    }
    case types.HISTORIQUE_LOADING: {
      return { ...state, loading: true, operations: null };
    }
    case types.HISTORIQUE_FOUND: {
      const operations =
        action.operations?.operation?.filter(o => {
          return !action.vueEntreprise || o.idEnveloppeEntreprise;
        });
      return {
        ...state,
        loading: false,
        operations: operations
      };
    }
    case types.UNITE_LOADING: {
      return {
        ...state,
        loading: true,
        unite: null,
        numLastRequete: action.numRequete
      };
    }
    case types.UNITE_FOUND: {
      return {
        ...state,
        loading: false,
        unite: action.unite
      };
    }
    case types.UNITES_LOADING: {
      return {
        ...state,
        loading: true,
        unites: [],
        unitesExport: []
      };
    }
    case types.UNITES_FOUND: {
      if (state.numLastRequete > action.numRequete) {
        return state;
      }
      return {
        ...state,
        loading: false,
        unites: [...action.payload.content],
        total: action.payload.totalElements
      };
    }
    case types.UNITES_EXPORTING: {
      return {
        ...state,
        exporting: true,
        unitesExport: []
      };
    }
    case types.UNITES_EXPORTED: {
      return {
        ...state,
        exporting: false,
        unitesExport: [...action.payload.content]
      };
    }
    default:
      return state;
  }
};
export default unites