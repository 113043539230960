import React, { useState, useEffect } from "react";
import { TableCell, TableRow } from "@mui/material";

import ModificationOperations from "components/ModificationOperations";
import InfoUnite from "./InfoUnite";
import AnneesUnite from "./AnneesUnite";
import {
  Montant,
  Evolution,
  getArianeMillesime,
  getArianeEnveloppe,
} from "components/utils/";
import { useParams, useSearchParams } from "react-router-dom";

const HistoriqueUL = (props) => {
  const {
    vueEntreprise,
    getHistorique,
    setVueEntrepise,
    setAriane,
    filtreOperations,
    operations,
    page,
    size,
    sort,
  } = props;

  const [allOperations, setAllOperations] = useState(operations);
  const [idUniteState, setIdUniteState] = useState(null);

  // Exemple url http://localhost:3000/2019/E19099559/750880494?vue=ul
  // /:annee/:idEnv/:idUnite
  const {annee, idEnv, idUnite } = useParams();
  const [searchParams] = useSearchParams();
  const vue = searchParams.get("vue");

  // Mise à jour du fils d'Ariane
  useEffect(() => {
    setAriane([
      getArianeMillesime(annee, vueEntreprise),
      getArianeEnveloppe(annee, idEnv, vueEntreprise),
      {
        libelle: `Historique des opérations sur ${
          vueEntreprise ? "l'entreprise" : "le siren"
        } : ${idUnite}`,
      },
    ]);
  }, [vueEntreprise, setAriane, annee, idEnv, idUnite]);

  // Mise à jour de vueEntreprise
  useEffect(() => {
    const vueParams = vue || "ul";
    setVueEntrepise(vueParams === "entreprise");
  }, [setVueEntrepise, vue]);

  // getHistorique
  useEffect(() => {
    getHistorique(idUnite);
    setIdUniteState(idUnite);
  }, [getHistorique, idUnite]);

  // filtreOperations
  useEffect(() => {
    if (operations) {
      const operationsFiltrees = operations.filter((o) => {
        return `${o.anneeCampagne}` === annee;
      });

      filtreOperations(operationsFiltrees, page, size, sort);
      setAllOperations(operationsFiltrees);
    }
  }, [filtreOperations, annee, operations, page, size, sort]);

  const getUniteFromOperation = (operationsParam, idUnite) => {
    let uniteCalc = null;
    operationsParam?.forEach((o) => {
        if (o.uniteBeneficiaire && o.uniteBeneficiaire.siren === idUnite) {
          uniteCalc = o.uniteBeneficiaire;
          return;
        }
        if (o.uniteCedante && o.uniteCedante.siren === idUnite) {
          uniteCalc = o.uniteCedante;
          return;
        }
        if (
          o.uniteBeneficiaire &&
          o.uniteBeneficiaire.entrepriseProfilee &&
          o.uniteBeneficiaire.entrepriseProfilee.siren === idUnite
        ) {
          uniteCalc = o.uniteBeneficiaire.entrepriseProfilee;
          return;
        }
        if (
          o.uniteCedante &&
          o.uniteCedante.entrepriseProfilee &&
          o.uniteCedante.entrepriseProfilee.siren === idUnite
        ) {
          uniteCalc = o.uniteCedante.entrepriseProfilee;
        }
      });
    return uniteCalc;
  };

  const getFooter = (datas, divider) => {
    const sum = { zca: 0, ca: 0, zeffectif: 0, effectif: 0 };
    datas.forEach((data) => {
      Object.keys(sum).forEach((k) => {
        if (data[k]) {
          sum[k] += data[k];
        }
      });
    });

    const evol =
      sum.ca && sum.zca ? ((sum.ca - sum.zca) / sum.zca) * 100 : undefined;
    return (
      <TableRow key="footerUnites">
        <TableCell colSpan={2}>Total</TableCell>
        {divider}
        <TableCell />
        <TableCell>{sum.zeffectif}</TableCell>
        <TableCell>
          <Montant value={sum.zca} />
        </TableCell>
        {divider}
        <TableCell />
        <TableCell>{sum.effectif}</TableCell>
        <TableCell>
          <Montant value={sum.ca} />
        </TableCell>
        {divider}
        <TableCell>
          <Evolution value={evol} />
        </TableCell>
      </TableRow>
    );
  };

  if (!annee || !idEnv) {
    return null;
  }

  const anneeParam = annee;

  const unite = getUniteFromOperation(allOperations, `${idUniteState}`);
  return (
    <ModificationOperations
      entete={
        <>
          <InfoUnite unite={unite} />
          <AnneesUnite annee={anneeParam} idUnite={idUniteState} />
        </>
      }
      annee={anneeParam}
      allOperations={allOperations}
      footer={getFooter}
    />
  );
};

export default HistoriqueUL;
