import { connect } from "react-redux";
import ListeFichesRecherche from "./ListeFichesRecherche";

const mapStateToProps = state => {
  return {
    fiches: state.storeFiches.fiches
  };
};

export default connect(
  mapStateToProps,
  {}
)(ListeFichesRecherche);
