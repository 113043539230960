import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { ResumeOperation, colonnes as colonnesResume } from "./ResumeOperation";
import DetailOperation from "./DetailOperation";
import ActionOperation from "./ActionOperation";
import { isContrib } from "utils/user-utils";
import { enqueueSnackbar } from 'notistack';
import "./operation.scss";

const detailColonne = {
  libelle: "Détails de l'opération",
  taille: 4,
}


const Operation = (props) => {
  const {
    operation,
    action,
    user,
    save,
    correction,
    rejet,
    index,
    titre,
  } = props;

  const [newOperation, setNewOperation] = useState(null);
  const [modification, setModification] = useState(null);
  const [dialogModification, setDialogModification] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [errorState, setErrorState] = useState(false);


  const [colonnes, setColonnes] = useState({
    resume: { taille: 8, colonnes: colonnesResume },
    detail: detailColonne,
  });

  useEffect(() => {
    setNewOperation({ ...operation });
  }, [operation]);


  useEffect(() => {
    if (action && isContrib(user)) {
      setShowActions(true);
      setColonnes({
        resume: { taille: 6, colonnes: colonnesResume },
        detail: detailColonne,
        action: { libelle: "Actions", taille: 2 },
      });
    } else {
      setShowActions(false);
      setColonnes({
        resume: { taille: 8, colonnes: colonnesResume },
        detail: detailColonne,
      });
    }
  }, [action, user]);

  const toogleCheckModification = () => {
    if (errorState) {
      enqueueSnackbar(errorState, {
        variant: "error",
      });
    } else {
      setDialogModification(!dialogModification);
    }
  };

  const handleModification = (idOperation) => {
    if (modification !== idOperation) {
      setModification(idOperation);
    }
  };

  const handleAnnuler = () => {
    setModification(null);
    setNewOperation({ ...operation });
    setDialogModification(false);
  };



  const saveOperation = () => {
    save(newOperation).then(
      (response) => {
        setModification(null);
        setDialogModification(false);
        const resultat = newOperation.response;
        if (resultat.listChampsMajRefuses) {
          enqueueSnackbar(
            "La mise à jour du(des) champs " +
              resultat.listChampsMajRefuses +
              " est refusée car vous êtes de priorité inférieure",
            { variant: "error" }
          );
        }
        if (resultat.correctionsDepot) {
          enqueueSnackbar(
            "Les corrections suivantes ont été apportées lors du dépôt : " +
              resultat.correctionsDepot,
            { variant: "warning" }
          );
        }
        if (!resultat.listChampsMajRefuses && !resultat.correctionsDepot) {
          enqueueSnackbar(
            "Votre demande de modification a été prise en compte",
            { variant: "success" }
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          `Votre demande de modification a échoué : ${error.message}`,
          {
            variant: "error",
          }
        );
      }
      );
  };
  const corrigeRejet = () => {
    const operationRequete = {
      source: newOperation.source.libelle,
      codeTypeOperation: newOperation.codeTypeOperation.code,
      anneeCampagne: newOperation.anneeCampagne,
      sirenCedante: newOperation.uniteCedante
        ? newOperation.uniteCedante.siren
        : newOperation.sirenCedante,
      sirenBeneficiaire: newOperation.uniteBeneficiaire
        ? newOperation.uniteBeneficiaire.siren
        : newOperation.sirenBeneficiaire,
      montantNet: newOperation.montantNet,
      dateRealisationJuridique: newOperation.dateRealisationJuridique,
      dateEffetComptable: newOperation.dateEffetComptable,
      etatOp: newOperation.etatOp,
      refAnnonce: newOperation.refAnnonce,
    };

    correction({
      idRejet: rejet.idRejet,
      operationRequete,
    }).then(
      () => {
        setModification(null);
        setDialogModification(false);
        
        enqueueSnackbar("Votre demande de correction a été envoyée", {
          variant: "success",
        });
      },
      (error) => {
        enqueueSnackbar(
          `Votre demande de correction a échoué : ${error.message}`,
          {
            variant: "error",
          }
        );
      }
    );
  };

  const handleValider = () => {
    if (errorState) {
      enqueueSnackbar(errorState, {
        variant: "error",
      });
    } else {
      rejet ? corrigeRejet() : saveOperation();
    }
  };

  const toogleError = (message) => {
    setErrorState(message);
  };

  const isUpdatable = (key) => {
    if (
      operation[key] !== null &&
      operation.updatableFields &&
      !operation.updatableFields.includes(key)
    ) {
      enqueueSnackbar(
        "Modification non prise en compte : L'information déjà présente provient d'une source de priorité plus élevée.",
        {
          variant: "warning",
          autoHideDuration: 7000,
        }
      );
      return false;
    }
    return true;
  };

  const submitModification = (key, value) => {

    setNewOperation((op1) => ({...op1, [key]: value}));
  };

  const getTitre = (colonnesParam) => {
    const titres = [];
    Object.keys(colonnesParam).forEach((colonne, i) => {
      if (colonnesParam[colonne].colonnes) {
        const sousTitre = [];

        Object.keys(colonnesParam[colonne].colonnes).forEach((c, clef) => {
          const col = colonnesParam[colonne].colonnes[c];
          sousTitre.push(
            <Grid key={clef} item xs={col.taille}>
              {col.libelle}
            </Grid>
          );
        });
        titres.push(
          <Grid key={i} item xs={colonnesParam[colonne].taille}>
            <Grid container> {sousTitre}</Grid>
          </Grid>
        );
      } else {
        titres.push(
          <Grid key={i} item xs={colonnesParam[colonne].taille}>
            {colonnesParam[colonne].libelle}
          </Grid>
        );
      }
    });
    return (
      <Grid className="titre" container>
        {titres}
      </Grid>
    );
  };

  const estEnModification = modification === operation.idOperation;
  return (
    <div tabIndex={0} className="operation" title={index} onKeyDown={event => {
      if (estEnModification && !dialogModification) {
        if (event.code === "Escape") {
          handleAnnuler();
        }
        if (event.code === "Enter") {
          event.preventDefault();
          toogleCheckModification();
        }
    }}}>
      

      {titre && getTitre(colonnes)}
      {newOperation && (
      <Paper>
        <Grid container>
          <Grid item xs={colonnes.resume.taille}>
            <ResumeOperation
              sirenCedant={newOperation.uniteCedante.siren}
              sirenBeneficiaire={newOperation.sirenBeneficiaire}
              operation={newOperation}
              typeOp={newOperation.codeTypeOp}
              modification={estEnModification}
              submitModification={submitModification}
              isUpdatable={isUpdatable}
              rejet={rejet}
            />
          </Grid>
          <Grid item xs={colonnes.detail.taille}>
            <DetailOperation
              operation={newOperation}
              modification={estEnModification}
              submitModification={submitModification}
              check={toogleCheckModification}
              annuler={handleAnnuler}
              toogleError={toogleError}
              isUpdatable={isUpdatable}
              rejet={rejet}
            />
          </Grid>
          {showActions && (
            <Grid item xs={colonnes.action.taille}>
              <ActionOperation
                operation={newOperation}
                modification={estEnModification}
                modifier={() => handleModification(newOperation.idOperation)}
                submitModification={submitModification}
                valider={handleValider}
                annuler={handleAnnuler}
                isUpdatable={isUpdatable}
                rejet={rejet}
              />
              <Dialog
                open={dialogModification}
                onClose={toogleCheckModification}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Etes-vous sûr de vouloir valider cette modification ?
                </DialogTitle>
                <DialogContent
                  className="operation"
                  id="alert-dialog-description"
                >
                  <ResumeOperation operation={newOperation} />
                  <DetailOperation operation={newOperation} />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={toogleCheckModification}
                    color="primary"
                    autoFocus
                  >
                    Non
                  </Button>
                  <Button onClick={handleValider} color="primary">
                    Oui
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Grid>
      </Paper>
      )}
    </div>
  );
};

export default Operation;
