import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import createTheme from "materialUiTheme";

import Header from "components/Header";
import SansDroits from "components/SansDroits";
import Rejets from "components/Rejets";
import Rapports from "components/Rapports";
import Creation from "components/Creation";
import HistoriqueUl from "components/HistoriqueUl";
import Enveloppe from "components/Enveloppe";
import CommentairesOperation from "components/Commentaires/CommentairesOperation";
import CommentairesEnveloppe from "components/Commentaires/CommentairesEnveloppe";
import CommentairesUnite from "components/Commentaires/CommentairesUnite";
import ListeOperations from "components/Listes/ListeOperations";
import ListeEnveloppes from "components/Listes/ListeEnveloppes";
import ListeUnites from "components/Listes/ListeUnites";
import ListeFiches from "components/Listes/ListeFiches";

import { isAdmin, isContrib, isConsult } from "utils/user-utils";

import "./content.scss";

const theme = createTheme();

const Content = (props) => {

  const { loadUserInfo, user } = props;

    // ajout du nouveau filtre à la recherche
    useEffect(() => {
      loadUserInfo();
    }, [
      loadUserInfo,
    ]);

    if (user && !isConsult(user)) {
      return <SansDroits />;
    }
    if (!user) {
      return <></>;
    }
    return (
      <main className="App-content">
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            preventDuplicate
          >
            <Header />
            <Routes>
              {isAdmin(user) && [
                <Route key="rapports" path="/rapports" element={<Rapports />} />,
                <Route key="rejets" path="/liste/rejets" element={<Rejets />} />,
              ]}
              {isContrib(user) && (
                <Route path="/creation" element={<Creation />} />
              )}
              <Route path="/liste/fiches" element={<ListeFiches />} />
              <Route path="/liste/operation/:entreprise"  element={<ListeOperations />} />
              <Route path="/liste/enveloppe/:entreprise" element={<ListeEnveloppes />} />
              <Route path="/liste/unite/:entreprise"  element={<ListeUnites />} />
              <Route path="/commentaires/:annee/:idEnv/unite/:idUnite" element={<CommentairesUnite />} />
              <Route path="/commentaires/:annee/:idEnv/:idOperation" element={<CommentairesOperation />} />
              <Route path="/commentaires/:annee/:idEnv/" element={<CommentairesEnveloppe />} />
              <Route path="/:annee/:idEnv/:idUnite"  element={<HistoriqueUl />} />
              <Route path="/:annee/:idEnv" element={<Enveloppe />} />
              <Route path="*" element={<Navigate replace to="/liste/operation/ul" />}/>
            </Routes>
          </SnackbarProvider>
        </ThemeProvider>
      </main>
    );
}

export default Content;
