import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

import SubHeader from "./SubHeader";
import TopHeader from "./TopHeader";
import { useLocation } from "react-router-dom";

const Header = (props) => {
  const location = useLocation();
  const loading = props.loading;

  return (
    <header>
      <TopHeader />
      <SubHeader location={location} />
      {loading && <LinearProgress />}
    </header>
  );
};

export default Header;
