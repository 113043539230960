import * as types from "./constantes";
import {
  selectionUnite,
  selectionEnveloppe,
  selectionEntreprise
} from "redux/api";

export const selectionExpress = id => (dispatch, getState) => {
  dispatch({ type: types.SELECTION_LOADING });
  let search = () => selectionUnite(id);
  if (id.startsWith("E")) {
    search = () => selectionEnveloppe(id);
  }
  if (id.startsWith("P")) {
    search = () => selectionEntreprise(id);
  }
  return search().then(
    response => {
      dispatch({ type: types.SELECTION_LOADED, payload: response, id });
    },
    () => dispatch({ type: types.SELECTION_LOADED, payload: null, id })
  );
};

export const unselect = () => (dispatch, getState) => {
  dispatch({ type: types.SELECTION_UNSELECT });
};
