import React, { useState, useEffect } from "react";

import Operation from "components/Operation";

import "./rejet.scss";

const toUnite = (props, siren, anneeCampagne) => {
  if (props.sirensValides[siren]) {
    return props.getInfoUnite(siren, anneeCampagne);
  }
  return { anneeCampagne, siren };
};

const getTypeOp = (props, codeTypeOp) => {
  let codeTypeOperation = { code: codeTypeOp, libelle: codeTypeOp };
  props.typesOp.forEach(t => {
    if (t.code === codeTypeOp) {
      codeTypeOperation = t;
    }
  });
  return codeTypeOperation;
};

const toOperationToRejet = (props) => {
  const uniteBeneficiaire = toUnite(
    props,
    props.rejet.operationRequete.sirenBeneficiaire,
    props.rejet.operationRequete.anneCampagne
  );
  const uniteCedante = toUnite(
    props,
    props.rejet.operationRequete.sirenCedante,
    props.rejet.operationRequete.anneCampagne
  );
  const codeTypeOperation = getTypeOp(
    props,
    props.rejet.operationRequete.codeTypeOperation
  );
  return {
    uniteBeneficiaire,
    uniteCedante,
    ...props.rejet.operationRequete,
    codeTypeOperation,
    source: { libelle: props.rejet.operationRequete.source }
  };
};

const Rejet = (props) => {

  const [operation, setOperation] = useState(toOperationToRejet(props));
  const [sirenBeneficiaire, setSirenBeneficiaire] = useState(null);
  const [sirenCedante, setSirenCedante] = useState(null);
  const { typesOp, loadTypesOp, rejet, valideSiren, titre } = props;

  // Chargement des types d'opérations
  useEffect(() => {
    if (!typesOp || typesOp.length === 0) {
      loadTypesOp();
    }
  }, [typesOp, loadTypesOp]);

  // Validation des sirens 
  useEffect(() => {
    if (
      rejet.operationRequete.sirenBeneficiaire !== sirenBeneficiaire ||
      rejet.operationRequete.sirenCedante !== sirenCedante
    ) {
      valideSiren(rejet.operationRequete.sirenBeneficiaire);
      valideSiren(rejet.operationRequete.sirenCedante);
      setSirenBeneficiaire(rejet.operationRequete.sirenBeneficiaire);
      setSirenCedante(rejet.operationRequete.sirenCedante);
    }
  }, [rejet.operationRequete.sirenBeneficiaire, rejet.operationRequete.sirenCedante, sirenBeneficiaire, sirenCedante, valideSiren]);

  // On renseigne l'Opération à partir du rejet
  useEffect(() => {
    setOperation(toOperationToRejet(props));
  }, [props]);

    return (
      <Operation
        operation={operation}
        titre={titre}
        rejet={rejet}
        action={true}
      />
    );
}

export default Rejet;
