import * as types from "./constantes";
import { getAnnees } from "redux/api";

export const loadAnnees = () => (dispatch, getState) => {
  if (getState().storeAnnees.annees.length === 0) {
    dispatch({ type: types.ANNEES_LOADING });
    return getAnnees().then(response => {
      dispatch({ type: types.ANNEES_LOADED, payload: response });
    });
  }
  return null;
};
