export { default as InputMontant } from "./InputMontant";
export { default as InputDate } from "./InputDate";
export { default as InputTypeOp } from "./InputTypeOp";

export const getStyle = theme => ({
  asterisk: {
    color: "red",
    fontWeight: "bold"
  }
});
