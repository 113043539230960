import { connect } from "react-redux";
import FiltresCommentaires from "./FiltresCommentaires";
import { setAttache } from "redux/actions";

const mapStateToProps = state => {
  return {
    commentaires: state.storeCommentaires.commentaires,
    attache: state.storeCommentaires.attache,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(mapStateToProps, {
  setAttache
})(FiltresCommentaires);
