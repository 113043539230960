import { createTheme } from "@mui/material/styles";

import styles from "./_variables.scss";
import { grey } from "@mui/material/colors";

const materialUiTheme = () =>
  createTheme({
    typography: {
      useNextVariants: true,
      fontSize: 14
    },
    components: {
      // Name of the component
      MuiChip: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            backgroundColor: grey[300],
          },
        },
      },
    },
    palette: {
      primary: {
        light: "#7986cb",
        main: "#3f51b5",
        dark: "#303f9f",
      }
    },
    ...styles
  });

export default materialUiTheme