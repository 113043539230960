import * as types from "./constantes";
import {
  searchOperationRejets,
  getMotifsRejets,
  expertiseRejet,
  correctionRejet
} from "redux/api";

let numLastRequete = 0;

export const searchRejets = (filtres, page, size, sort) => (
  dispatch,
  getState
) => {
  if (filtres != null) {
    let numRequete = numLastRequete;
    numLastRequete++;
    dispatch({ type: types.REJETS_LOADING, numRequete: numRequete });
    return searchOperationRejets(filtres, page, size, sort).then(response => {
      dispatch({
        type: types.REJETS_FOUND,
        payload: response,
        numRequete: numRequete
      });
    });
  }
  return null;
};
export const loadMotifs = () => (dispatch, getState) => {
  if (getState().storeRejets.motifs.length === 0) {
    dispatch({ type: types.REJETS_MOTIFS_LOADING });
    return getMotifsRejets().then(response => {
      dispatch({ type: types.REJETS_MOTIFS_LOADED, payload: response });
    });
  }
  return null;
};
export const expertise = idRejet => (dispatch, getState) => {
  return expertiseRejet(idRejet).then(response => {
    dispatch({ type: types.REJET_EXPERTISE, payload: response, idRejet });
  });
};
export const correction = rejet => (dispatch, getState) => {
  return correctionRejet(rejet).then(response => {
    dispatch({ type: types.REJET_CORRECTION, payload: response, rejet: rejet });
  });
};
