import { React, useEffect } from 'react';

import Filtres from "components/Listes/Filtres";
import ListeHeader from "components/Listes/ListeHeader";
import ListeEnveloppesRecherche from "components/Listes/ListeEnveloppesRecherche";
import { naf } from "components/utils/naf";
import {
  getLibelle as getLibelleTranchesEffectif,
  values as tranchesEffectif
} from "components/utils/TrancheEffectif";
import { useParams } from 'react-router-dom';
import { completeAnnees } from "components/utils/";

const filtresExistant = {
  annee: {
    titre: "Millésime",
    liste: []
  },
  ape: {
    titre: "APE",
    liste: naf,
    exemple: "0220Z"
  },
  typeEnv: {
    titre: "Type de restructuration",
    liste: []
  },
  trancheEffectif: {
    titre: "Tranche d'effectif de l'enveloppe",
    liste: []
  }
};
const champsTris = [
  { libelle: "Enveloppe en unités légales", value: "idEnveloppe" },
  { libelle: "APE", value: "ape" },
  { libelle: "Coefficient d'agrégation", value: "coeffAgregation" },
  { libelle: "Tranche d'effectif de l'enveloppe", value: "trancheEffectif" },
  { libelle: "Type de restructuration", value: "typeEnv" },
  { libelle: "Millésime", value: "annee" },
  { libelle: "Nombre de commentaires", value: "nbCommentaire" }
];

const ListeEnveloppes = (props) => {

  const { setAllSorts, loadAnnees, loadTypesOp, setVueEntrepise, setAriane, setAllFiltres, searchEnveloppes, vueEntreprise, filtres, page, size, sort, typesOp, annees } = props;
  const {entreprise } = useParams();

  useEffect(() => {
    setAllSorts(champsTris);
    loadAnnees();
    loadTypesOp();
  }, [setAllSorts, loadAnnees, loadTypesOp]);

  // Chargement des filtres avec les années
  useEffect(() => {

      let newFiltres = structuredClone(filtresExistant);
      // completeTypeOp
      newFiltres.typeEnv.liste = [
          { libelle: "Tous", value: null },
          ...typesOp
            .map(t => {
              return { libelle: t.libelle, value: t.code };
            })
            .sort()
        ];
        newFiltres.trancheEffectif.liste = [
          { libelle: "Tous", value: null },
          ...tranchesEffectif.map(t => {
            return { libelle: getLibelleTranchesEffectif(t), value: t };
          })
        ];
  
      // completeAnnees
      newFiltres.annee.liste = completeAnnees(annees);;
  
      setAllFiltres(newFiltres);
  
    }, [annees, setAllFiltres, typesOp]);


  // Mise à jour du fils d'Ariane
  useEffect(() => {
    setAriane([
      {
        libelle: `Enveloppes en ${
          vueEntreprise ? "Entreprises " : "Unités Légales"
        }`
      }
    ]);
  }, [vueEntreprise, setAriane]);
  
  useEffect(() => {
    const isVueEntreprise = entreprise === "entreprise"
    setVueEntrepise(isVueEntreprise);

    const libelle = isVueEntreprise ? "entreprises " : "UL";
    champsTris.splice(0, 1, {
      libelle: `Enveloppe en ${libelle}`,
      value: "idEnveloppe"
    });
    setAllSorts(champsTris);
  }, [setVueEntrepise, entreprise, setAllSorts]);

  useEffect(() => {
    searchEnveloppes(
        filtres,
        page,
        size,
        sort,
        vueEntreprise
      );
  }, [searchEnveloppes, filtres, page, size, sort, vueEntreprise]);

    return (
      <div className="block-main">
        <Filtres />
        <div className="block-content">
          <ListeHeader type="enveloppe" />
          <ListeEnveloppesRecherche />
        </div>
      </div>
    );

}

export default ListeEnveloppes;
