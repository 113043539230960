import { React, useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Input,
  Button,
} from "@mui/material";

import "./tris.scss";

const libelle = "Trier par";

const Tris = (props) => {

  const { setSorts, colonnes, sens, addSort, sort } =  props;

  const [colonne, setColonne] = useState(null);
  const [sensState, setSensState] = useState(null);
  const [sortState, setSortState] = useState(null);
  const initialisation = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const sortParams = searchParams.get("sort");

  // Mise à jour du state en fonction des props
  useEffect(() => {
    setColonne(colonnes[0] ? colonnes[0].value : null);
    setSensState(sens[0] ? sens[0].value : null);
  }, [colonnes, sens]);



  useEffect(() => {
    // On met les tris en fonction de l'url
    if (initialisation.current) {
      if (sortParams !== sort) {
        setSorts(sortParams);
        if (Array.isArray(sortParams)) {
          setSortState(sortParams);
        } else if (sortParams) {
          let sortEnCours = [];
          sortEnCours.push(sortParams);
          setSortState(sortEnCours);
        }
      }
      initialisation.current = false
    } 
    // Mettre à jour l'url avec le nouveau tri
    else if ((JSON.stringify(sort) !== JSON.stringify(sortState)) && (sort.length > 0 || sortState)) {
      
      setSearchParams((currentSearchParams) => {
        const newSearchParams = new URLSearchParams(currentSearchParams);
        newSearchParams.delete("sort");
        sort.forEach((value, key) => {
          newSearchParams.append("sort", value);
        });
        return newSearchParams;
      });
  
       setSortState(sort);
      }

  }, [sortParams, setSearchParams, sort, sortState, setSorts, initialisation]);


  const handleChangeColonne = (e) => {
    setColonne(e.target.value);
  };
  const handleChangeSens = (e) => {
    setSensState(e.target.value);
  };
  const handleClick = () => {
    addSort(sensState + colonne);
  };

  if (!colonnes || colonnes.length === 0 || !colonne || !sensState) {
    return null;
  }

  // On s'assure que la colonne de tri est bien en cohérence avec tous les tris possibles.
  const colonneEnCours = colonnes.some(item => item.value === colonne) ? colonne : colonnes[0].value;

  return (
    <div className="choixTris">
      <InputLabel htmlFor="tris-colonne-label">{libelle} :</InputLabel>
      <FormControl>
        <Select
          value={colonneEnCours}
          onChange={handleChangeColonne}
          input={<Input name="page-size" id="tris-colonne-label" />}
          displayEmpty
          name="selectColonne"
        >
          {colonnes.map((c, index) => (
            <MenuItem key={index} value={c.value}>
              {c.libelle}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <InputLabel htmlFor="tris-sens-label">Dans l'ordre :</InputLabel>
      <FormControl>
        <Select
          value={sensState}
          onChange={handleChangeSens}
          input={<Input name="page-size" id="tris-sens-label" />}
          displayEmpty
          name="selectSize"
        >
          {sens.map((s, index) => (
            <MenuItem key={index} value={s.value}>
              {s.libelle}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={handleClick}>
        Ajouter
      </Button>
    </div>
  );
};

export default Tris;
