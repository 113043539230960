import React from "react";
import RcMenu, { SubMenu, Item as MenuItem } from "rc-menu";
import { Link, useLocation } from "react-router-dom";

import { isAdmin } from "utils/user-utils";

import "rc-menu/assets/index.css";
import "./menu.scss";

const libellesSousMenu = {
  ul: { libelle: "Unités Légales" },
  entreprise: { libelle: "Entreprises" }
};

const libelleMenu = {
  liste: {
    libelle: "Consulter",
    child: {
      operation: {
        libelle: "Operations élémentaires",
        child: libellesSousMenu,
        admin: false
      },
      enveloppe: {
        libelle: "Enveloppes",
        child: { ul: { libelle: "Unités Légales" }},
        admin: false
      },
      unite: {
        libelle: "Unités",
        child: libellesSousMenu,
        admin: false
      },
      fiches: {
        libelle : "Fiches TSE2G",
        admin: false
      },
      rejets: {
        libelle: "Opérations rejetées",
        admin: true
      }
    }
  }
};

const Menu = (props) => {
  
  const location = useLocation();

  const createMenu = (libelles, index, father) => {
    let allMenu = [];
    Object.keys(libelles).forEach((menu, i) => {
      if (!libelles[menu].child) {
        if (!libelles[menu].admin || isAdmin(props.user)) {
          allMenu.push(
            <MenuItem key={`${father}/${menu}`}>
              <Link className="lienmenu" to={`${father}/${menu}`}>
                {" "}
                {libelles[menu].libelle}
              </Link>
            </MenuItem>
          );
        }
      } else {
        allMenu.push(
          <SubMenu title={libelles[menu].libelle} key={`${father}/${menu}`}>
            {createMenu(
              libelles[menu].child,
              `${index}-${i}`,
              `${father}/${menu}`
            )}
          </SubMenu>
        );
      }
    });
    return allMenu;
  };

    return (
      <RcMenu
        mode="horizontal"
        className="menu"
        selectedKeys={[location.pathname]}
      >
        {createMenu(libelleMenu, 0, "")}
      </RcMenu>
    );
}

export default Menu;
