import { connect } from "react-redux";
import InputTypeOp from "./InputTypeOp";
import { loadTypesOp } from "redux/actions";

const mapStateToProps = state => {
  return {
    typesOp: state.storeTypesOp.typesOp
  };
};

export default connect(
  mapStateToProps,
  {
    loadTypesOp
  }
)(InputTypeOp);
