import * as types from "redux/actions/constantes";

const initial = {
  filtres: null,
  allFiltres: {}, //tous les filtres possibles (libellées, liste de valeurs), à remplir par chaque page concernée
  size: 20,
  page: null,
  sort: [],
  allSort: {
    colonnes: [], //toutes les colonnes possibles pour les tris
    sens: [
      { libelle: "Croissant", value: "a" },
      { libelle: "Décroissant", value: "d" }
    ]
  }
};

const addFiltre = (state, filtreAAjouter) => {
  const newStateFiltres =  structuredClone(state.filtres);
  if (filtreAAjouter.value && state.allFiltres[filtreAAjouter.param]) {
    const value = filtreAAjouter.value.toString();
    if (newStateFiltres[filtreAAjouter.param]) {
      newStateFiltres[filtreAAjouter.param].push(value);
    } else {
      newStateFiltres[filtreAAjouter.param] = [value];
    }
  } else {
    delete newStateFiltres[filtreAAjouter.param];
  }
  return newStateFiltres;
};

const removeFiltre = (state, filtreASupprimer) => {
  const newStateFiltres =  structuredClone(state.filtres);
  if (filtreASupprimer.value && state.filtres[filtreASupprimer.param]) {
    newStateFiltres[filtreASupprimer.param] = newStateFiltres[filtreASupprimer.param].filter(
      v => v !== filtreASupprimer.value.toString()
    );
    if (newStateFiltres[filtreASupprimer.param].length === 0) {
      delete newStateFiltres[filtreASupprimer.param];
    }
  }
  return newStateFiltres;
};

const setFiltres = (state, filtresActuels) => {
  const f = filtresActuels;
  filtresActuels &&
    Object.keys(filtresActuels).forEach(k => {
      if (state.allFiltres[k]) {
        if (!Array.isArray(filtresActuels[k])) {
          f[k] = [filtresActuels[k]];
        }
      } else {
        delete f[k];
      }
    });
  return f;
};
const filtre = (state = initial, action = { type: "" }) => {
  switch (action.type) {
    case types.ADD_FILTRE: {
      if (!state.filtres) {
        state.filtres = {};
      }
      const filtresNew = addFiltre(state, action.filtre);
      return { ...state, filtres: { ...filtresNew }, page: 1 };
    }
    case types.REMOVE_FILTRE: {
      const filtresNew = removeFiltre(state, action.filtre);
      return { ...state, filtres: { ...filtresNew }, page: 1 };
    }
    case types.SET_FILTRE: {
      const filtresNew = setFiltres(state, action.filtres);
      return { ...state, filtres: { ...filtresNew } };
    }
    case types.SET_ALL_FILTRE: {
      return { ...state, allFiltres: { ...action.filtres } };
    }
    case types.SET_PAGE_SIZE: {
      return { ...state, size: action.size };
    }
    case types.SET_PAGE: {
      return { ...state, page: action.page };
    }
    case types.ADD_SORT: {
      let sort = state.sort.filter(s => action.sort.slice(1) !== s.slice(1));
      sort.push(action.sort);
      return { ...state, sort };
    }
    case types.REMOVE_SORT: {
      let sort = state.sort.filter(s => action.sort !== s);
      return { ...state, sort };
    }
    case types.SET_SORT: {
      if (Array.isArray(action.sorts)) {
        return { ...state, sort: [...action.sorts] };
      }
      let sort = [];
      action.sorts && sort.push(action.sorts);
      return { ...state, sort };
    }
    case types.SET_ALL_SORT: {
      let allSort = { ...state.allSort, colonnes: [...action.sorts] };
      return { ...state, allSort };
    }
    default:
      return state;
  }
};
export default filtre