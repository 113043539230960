import React, { useEffect } from "react";

import CommentairesListe from "components/Commentaires/CommentairesListe";
import FiltresCommentaires from "components/Commentaires/FiltresCommentaires";
import InfoFiltres from "components/Listes/ListeHeader/InfoFiltres";
import { getArianeMillesime, getArianeEnveloppe } from "components/utils";
import { useParams, useSearchParams } from "react-router-dom";

const CommentairesUnite = (props) => {
  const {
    loadUnite,
    setAriane,
    vueEntreprise,
    setVueEntrepise,
    commentaires,
    unite,
    setAttache,
    loadCommentairesUnite,
  } = props;

  const {idUnite, annee, idEnv } = useParams();
  const [searchParams] = useSearchParams();
  const vue = searchParams.get("vue");


  // Chargement initial
  useEffect(() => {
    loadUnite(idUnite);
  }, [loadUnite, idUnite]);

  useEffect(() => {
    if (unite) {
      // mise à jour du fils d'Ariane
      setAriane([
        getArianeMillesime(annee, vueEntreprise),
        getArianeEnveloppe(annee, idEnv, vueEntreprise),
        {
          libelle: `Commentaires sur ${
            vueEntreprise ? "l'entreprise" : "le siren"
          } ${unite.siren}`,
        },
      ]);
    }
  }, [annee, idEnv, setAriane, unite, vueEntreprise]);

  useEffect(() => {
    const vueSafe = vue || "ul";
    setVueEntrepise(vueSafe === "entreprise");
  }, [vue, setVueEntrepise]);

  useEffect(() => {
    const vueSafe = vue || "ul";
    loadCommentairesUnite(idUnite, vueSafe);
  }, [idUnite, loadCommentairesUnite, vue]);

  const ajouterEnveloppe = (uniteParam, attaches, isEntreprise) => {
    let enveloppe = null;
    if (commentaires) {
      commentaires.forEach((c) => {
        if (
          c.enveloppe &&
          !isEntreprise &&
          c.enveloppe.idEnveloppe === uniteParam.idEnveloppe &&
          !enveloppe
        ) {
          enveloppe = {
            enveloppe: c.enveloppe,
            niveau: 2,
          };
        }
      });
      enveloppe && attaches.push(enveloppe);
    }
  };

  const ajouterEnveloppeEntreprise = (uniteParam, attaches) => {
    let enveloppeEntreprise = null;
    if (commentaires) {
      commentaires.forEach((c) => {
        if (
          c.enveloppe &&
          uniteParam.idEnveloppe !== uniteParam.idEnveloppeEntreprise &&
          c.enveloppe.idEnveloppe === uniteParam.idEnveloppeEntreprise &&
          !enveloppeEntreprise
        ) {
          enveloppeEntreprise = {
            enveloppeEntreprise: c.enveloppe,
            niveau: 2,
          };
        }
      });
      enveloppeEntreprise && attaches.push(enveloppeEntreprise);
    }
  };

  const ajouterEnveloppeOperations = (attaches) => {
    const idOperations = [];
    const operations = [];
    if (commentaires) {
      commentaires.forEach((c) => {
        if (c.enveloppe && c.operation) {
          if (!idOperations.includes(c.operation.idOperation)) {
            operations.push({
              operation: c.operation,
              niveau: 3,
            });
            idOperations.push(c.operation.idOperation);
          }
        }
      });
      attaches.push(...operations);
    }
  };

  if (unite) {
    setAttache({ unite });
    const attaches = [{ unite: unite, niveau: 1 }];
    if (unite.entrepriseProfilee) {
      attaches.push({
        entreprise: unite.entrepriseProfilee,
        niveau: 2,
      });
    }
    ajouterEnveloppe(unite, attaches, vueEntreprise);
    ajouterEnveloppeEntreprise(unite, attaches);
    ajouterEnveloppeOperations(attaches);

    if (commentaires) {
      return (
        <div className="block-main">
          <FiltresCommentaires attaches={attaches} />
          <div className="block-content">
            <InfoFiltres />
            <CommentairesListe />
          </div>
        </div>
      );
    }
  }
  return <></>;
};

export default CommentairesUnite;
