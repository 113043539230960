import * as types from "redux/actions/constantes";

const initial = {
  loading: false,
  sources: []
};

const sources = (state = initial, action = { type: "" }) => {
  switch (action.type) {
    case types.SOURCES_LOADING: {
      return { loading: true, sources: [] };
    }
    case types.SOURCES_LOADED: {
      return { loading: false, sources: action.payload.source };
    }
    default:
      return state;
  }
};
export default sources
