import { connect } from "react-redux";
import ListeHeader from "./ListeHeader";

const mapStateToProps = state => {
  return {
    totalOp: state.storeOperations.total,
    totalEnv: state.storeEnveloppes.total,
    totalUnite: state.storeUnites.total,
    totalRejet: state.storeRejets.total,
    totalFiche: state.storeFiches.total
  };
};

export default connect(mapStateToProps, {})(ListeHeader);
