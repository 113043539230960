import React, { useEffect } from "react";
import { TextField, MenuItem } from "@mui/material";

const InputTypeOp = (props) => {

const {
    loadTypesOp,
    typesOp,
    label,
    required,
    value,
    fullWidth,
    onChange,
    classes,
    style,
  } = props;

  // Une seule fois : on charge les type d'opérations
  useEffect(() => {
    loadTypesOp();
  }, [loadTypesOp]);

  const getTypeOp = (e) => {
    return typesOp.filter((t) => t.code === e.target.value)[0];
  };

  return (
    <TextField
      label={label}
      required={required}
      select
      value={ typesOp && typesOp.length > 0 ?  value : ''}
      fullWidth={fullWidth}
      onChange={(e) => onChange(getTypeOp(e))}
      inputProps={{
        name: "typeOperation",
        id: "typeOperation",
      }}
      InputLabelProps={{ classes: classes }}
      margin="normal"
      style={{ display: "flex", ...style }}
      size="small"
      // variant="standard"
    >
      {typesOp
        .filter((t) => t.code !== "CO" && t.code !== "IM")
        .map((t, i) => {
          return (
            <MenuItem key={i} value={t.code}>
              {t.libelle}
            </MenuItem>
          );
        })}
    </TextField>
  );
};

export default InputTypeOp;
