export const isAdmin = user => {
  return user && user.roles.includes("Admin_Citrus");
};
export const isContrib = user => {
  return isAdmin(user) || (user && user.roles.includes("Contribution_Citrus"));
};
export const isConsult = user => {
  return (
    isContrib(user) || (user && user.roles.includes("Consultation_Citrus"))
  );
};
