
import * as types from "redux/actions/constantes";

const initial = {
  loading: false,
  operations: [],
  total: null,
  creations: [],
  operation: null,
  exporting: false,
  operationsExport: [],
  numLastRequete: null
};

const operations = (state = initial, action = { type: "" }) => {
  switch (action.type) {
    case types.OPERATIONS_LOADING: {
      return {
        ...state,
        loading: true,
        operations: [],
        operationsExport: [],
        numLastRequete: action.numRequete
      };
    }
    case types.OPERATIONS_EXPORTING: {
      return {
        ...state,
        exporting: true,
        operationsExport: []
      };
    }
    case types.OPERATIONS_EXPORTED: {
      return {
        ...state,
        exporting: false,
        operationsExport: [...action.payload.content]
      };
    }
    case types.OPERATIONS_FOUND: {
      if (state.numLastRequete > action.numRequete) {
        return state;
      }
      return {
        ...state,
        loading: false,
        operations: [...action.payload.content],
        total: action.payload.totalElements
      };
    }
    case types.OPERATION_FOUND: {
      return {
        ...state,
        operation: action.payload
      };
    }
    case types.OPERATIONS_CREATE: {
      return {
        ...state,
        operations: [...state.operations, action.operation],
        creations: [...state.creations, action.operation]
      };
    }
    case types.OPERATIONS_UPDATE: {
      return  {...state, operations: state.operations.map((operation) => {
        if (operation.idOperation === action.operation.idOperation) {
          return action.operation
        }
        return operation;
      })};
    }

    case types.OPERATION_UPDATABLE: {
      return  {...state, operations: state.operations.map((operation) => {
        if (operation.idOperation === action.idOperation) {
          return {...operation, updatableFields: action.fields}
        }
        return operation;
      })};
    }

    case types.OPERATIONS_DELETE: {
      const operationsState = state.operations.filter(
        o => o.idOperation !== action.idOperation
      );
      return {
        ...state,
        operations: [...operationsState],
        total: state.total - 1
      };
    }

    case types.OPERATIONS_NB_FOUND: {
      return  {...state, operations: state.operations.map((operation) => {
        if (operation.idOperation === action.idOperation) {
          if (operation.uniteCedante.siren === action.siren) {
            let uniteModif = {...operation.uniteCedante, nbOperationsLiees: action.nbOperationsLiees };
            return {...operation, uniteCedante: uniteModif}
          }
          if (operation.uniteBeneficiaire.siren === action.siren) {
            let uniteModif = {...operation.uniteBeneficiaire, nbOperationsLiees: action.nbOperationsLiees };
            return {...operation, uniteBeneficiaire: uniteModif}
          }
        }
        return operation;
      })};
    }





    default:
      return state;
  }
};

export default operations