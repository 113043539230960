import React from "react";
import Mailto from "react-protected-mailto";
import { Button, SvgIcon } from '@mui/material';
import { ReactComponent as LogoCitrus } from "components/Header/TopHeader/img/citrus.svg";

import "./sansDroits.scss";

const headers = { subject: "[Citrus] Demande de droits" };
const storageId = "user-Info";

const libelles = {
  supp: "Effacer le stockage du résultat et actualiser",
};

const SansDroits = () => {
  const effacerStockageLocal = () => {
    localStorage.removeItem(storageId);
    window.location.reload();
  };

  return (
    <div className="sansDroits">
      <div className="topheader">
        <p className="logoCitrus">
          <SvgIcon>
            <LogoCitrus />
          </SvgIcon>
          <span>CITRUS</span>
        </p>
      </div>
      <div>
        <h2>
          Oups ! Il semblerait que vous n'ayez pas les droits pour accéder à
          l'application Citrus.
        </h2>
        <h3>
          Veuillez nous envoyer un courriel en précisant votre nom, prénom,
          organisme de tutelle et le rôle que vous souhaitez obtenir
          ("consultant" ou plutôt "expert" pour avoir la possibilité de traiter
          des opérations de restructuration) à la boîte fonctionnelle{" "}
          <Mailto email=":DG75-DSE-Citrus" headers={headers} /> (à l’extérieur
          de l’Insee : <Mailto email="citrus@insee.fr" headers={headers} />)
        </h3>
      </div>
      <div>
        <h3>
          {" "}
          Si des droits vous ont été accordés, cliquer sur le bouton ci-dessous
          pour effacer le cache de l'application et actualiser
        </h3>
        <Button
          key="effacer"
          className="effacer"
          variant="contained"
          color="primary"
          onClick={effacerStockageLocal}
        >
          {libelles.supp}
        </Button>
      </div>
    </div>
  );
};
export default SansDroits;
