import * as types from "redux/actions/constantes";
import { changeFormatDate } from "utils/";

const initial = {
  loading: false,
  rejets: [],
  numLastRequete: null,
  motifs: [],
  total: 0
};

const rejets = (state = initial, action = { type: "" }) => {
  switch (action.type) {
    case types.REJETS_LOADING: {
      return {
        ...state,
        loading: true,
        rejets: [],
        numLastRequete: action.numRequete
      };
    }
    case types.REJETS_FOUND: {
      if (state.numLastRequete > action.numRequete) {
        return state;
      }
      return {
        ...state,
        loading: false,
        rejets: [...action.payload.content],
        total: action.payload.totalElements
      };
    }
    case types.REJET_EXPERTISE: {
      return  {...state, rejets: state.rejets.map((rejet) => {
        if (rejet.idRejet === action.idRejet) {
          const newEtatRejet = (rejet.etatRejet === 3) ? 1 : 3;
          return {...rejet, dateTraitement: changeFormatDate(new Date()), etatRejet: newEtatRejet}
        }
        return rejet;
      })};
    }

    case types.REJET_CORRECTION: {
      
      return  {...state, rejets: state.rejets.map((rejet) => {
        if (rejet.idRejet === action.rejet.idRejet) {
          let operationRequeteModif = {...rejet.operationRequete};
          operationRequeteModif.sirenCedante = action.payload.sirenCedante;
          operationRequeteModif.sirenBeneficiaire = action.payload.sirenBeneficiaire;
          operationRequeteModif.anneeCampagne = action.payload.anneeCampagne;
          operationRequeteModif.dateRealisationJuridique = action.payload.dateRealisationJuridique;
          operationRequeteModif.dateEffetComptable = action.payload.dateEffetComptable;
          operationRequeteModif.codeTypeOperation = action.payload.codeTypeOperation.code;
          operationRequeteModif.montantNet = action.payload.montantNet;
          return {...rejet, dateTraitement: changeFormatDate(new Date()), etatRejet: 2, operationRequete: operationRequeteModif}
        }
        return rejet;
      })};

    }
    case types.REJETS_MOTIFS_LOADING: {
      return {
        ...state,
        loading: true,
        motifs: []
      };
    }
    case types.REJETS_MOTIFS_LOADED: {
      return {
        ...state,
        loading: false,
        motifs: action.payload
      };
    }
    default:
      return state;
  }
};
export default rejets