import * as types from "redux/actions/constantes";

const initial = {
  connected: false,
  user: null,
  source: null,
  priorite: null,
  loading: false
};

const user = (state = initial, action = { type: "" }) => {
  switch (action.type) {
    case types.USER_LOADING: {
      return { ...state, loading: true };
    }
    case types.USER_LOADED: {
      return {
        ...state,
        loading: false,
        user: action.payload
      };
    }
    case types.SOURCE_LOADED: {
      return {
        ...state,
        source: action.payload.libelle
      };
    }
    default:
      return state;
  }
};
export default user