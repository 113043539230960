import { React } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";

import ListFiltres from "./ListFiltres";
import InputFiltre from "./InputFiltre";

const Filtre = (props) => {
  const { onClick, titre, liste, selected } = props;


  const handleChange = () => {
    onClick();
  };

  const getIdFromLibelle = (libelle) => {
    return libelle.replace(/[."'\s/\\]/g, "")
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");
  };

  let contenu = <InputFiltre {...props} />;
  if (liste) {
    contenu = <ListFiltres {...props} />;
  }
  return (
    <Accordion className="filtre" onChange={handleChange} expanded={selected}>
      <AccordionSummary className="titreFiltre">
        <span>{titre}</span>
        {selected ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
      </AccordionSummary>

      <AccordionDetails id={getIdFromLibelle(titre)} className="contenuFiltre">
        {contenu}
      </AccordionDetails>
    </Accordion>
  );
};

export default Filtre;
