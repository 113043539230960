import { Typography, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect } from "react";

import "./ariane.scss";

const Ariane = (props) => {

  const { ariane } = props;

  useEffect(() => {
    if (ariane.length > 0) {
      const title = ariane[ariane.length - 1].libelle;
      document.title = `Citrus - ${title}`;
    } else {
      document.title = "Citrus";
    }

  }, [ariane]);

  return (
      <Breadcrumbs aria-label="Breadcrumb">
        {ariane.map((value, index) => {
          return value.link ? (
            <Link color="inherit" to={value.link} key={index}>
              {value.libelle}
            </Link>
          ) : (
            <Typography className="pageTitle" key={index}>
              {value.libelle}
            </Typography>
          );
        })}
      </Breadcrumbs>
    );
  }

export default Ariane;
