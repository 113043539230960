import { connect } from "react-redux";
import ListeEnveloppes from "./ListeEnveloppes";
import {
  searchEnveloppes,
  setAriane,
  loadAnnees,
  loadTypesOp,
  setAllSorts,
  setAllFiltres,
  setVueEntrepise
} from "redux/actions";

const mapStateToProps = state => {
  return {
    annees: state.storeAnnees.annees,
    typesOp: state.storeTypesOp.typesOp,
    filtres: state.storeFiltre.filtres,
    page: state.storeFiltre.page,
    size: state.storeFiltre.size,
    sort: state.storeFiltre.sort,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {
    searchEnveloppes,
    setAriane,
    loadAnnees,
    loadTypesOp,
    setAllFiltres,
    setAllSorts,
    setVueEntrepise
  }
)(ListeEnveloppes);
