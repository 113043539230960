import React from "react";

const separator = " ";

export const format = id =>
  id
    ? (
        id.substring(0, 3) +
        separator +
        id.substring(3, 6) +
        separator +
        id.substring(6, 9)
      ).trim()
    : "";

export const unformat = id => id.replace(/\s/g, "");

const Identifiant = (props) => {
    const id = props.value;
    return <span>{id && format(id)}</span>;
}

export default Identifiant;
