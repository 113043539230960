import api from "redux/api";
import { request, requestFile } from "./api-utils";

export function getAnnees() {
  return request({
    url: api.annees,
    method: "GET"
  });
}
export function getTypesOp() {
  return request({
    url: api.typeOp,
    method: "GET"
  });
}
export function getSources() {
  return request({
    url: api.sources,
    method: "GET"
  });
}
export function getSource(timbre) {
  return request({
    url: `${api.source}?timbre=${timbre}`,
    method: "GET"
  });
}
export function searchOperation(filtre, page, size, sort, vueEntreprise) {
  return request({
    url: `${api.operations.search}?page=${page -
      1}&size=${size}&sort=${sort}&entreprise=${vueEntreprise}`,
    method: "POST",
    body: JSON.stringify(filtre)
  });
}
export function searchOperationRejets(filtre, page, size, sort) {
  return request({
    url: `${api.rejets.search}?page=${page - 1}&size=${size}&sort=${sort}`,
    method: "POST",
    body: JSON.stringify(filtre)
  });
}
export function getMotifsRejets() {
  return request({
    url: api.rejets.motifs,
    method: "GET"
  });
}
export function expertiseRejet(idRejet) {
  return request({
    url: `${api.rejets.expertise}?idRejet=${idRejet}`,
    method: "POST"
  });
}
export function correctionRejet(rejet) {
  return request({
    url: api.rejets.correction,
    method: "POST",
    body: JSON.stringify(rejet)
  });
}
export function searchEnveloppe(filtre, page, size, sort, vueEntreprise) {
  return request({
    url: `${api.enveloppes.search}?page=${page -
      1}&size=${size}&sort=${sort}&entreprise=${vueEntreprise}`,
    method: "POST",
    body: JSON.stringify(filtre)
  });
}

export function searchUnite(filtre, page, size, sort, vueEntreprise) {
  return request({
    url: `${api.unites.search}?page=${page -
      1}&size=${size}&sort=${sort}&entreprise=${vueEntreprise}`,
    method: "POST",
    body: JSON.stringify(filtre)
  });
}
export function selectionUnite(id) {
  return request({
    url: api.operations.operations + "?siren=" + id,
    method: "GET"
  })
}

export async function  selectionUniteWithOperationLiee(id) {
   const response = await selectionUnite(id);
      return  {operation: await Promise.all(response.operation.map(async operation => {
          if (operation.uniteCedante && operation.uniteCedante.siren !== id) { 
            const result =   await getResultatOperations(operation.anneeCampagne, operation.uniteCedante.siren);
            return {...operation, uniteCedante: {...operation.uniteCedante, nbOperationsLiees: result}}
          } else if (operation.uniteCedante && operation.uniteBeneficiaire.siren !== id) {
            const result =  await getResultatOperations(operation.anneeCampagne, operation.uniteBeneficiaire.siren);
            return {...operation, uniteBeneficiaire: {...operation.uniteBeneficiaire, nbOperationsLiees: result}}
          }
  
          return operation;
        }))}

}

export function selectionEntreprise(id) {
  return request({
    url: api.entreprise + api.operations.operations + "?entreprise=" + id,
    method: "GET"
  });
}
export function selectionEnveloppe(id) {
  return request({
    url: api.enveloppes.enveloppe + id,
    method: "GET"
  });
}

export function getOrigineInfoCommentaires() {
  return request({
    url: api.commentaires.origines,
    method: "GET"
  });
}
export function getTypesCommentaires() {
  return request({
    url: api.commentaires.types,
    method: "GET"
  });
}
export function getSireneData(siren) {
  return request({
    url: api.unites.unites + siren,
    method: "GET"
  });
}
export function getUnite(idUnite) {
  return request({
    url: api.unites.unite + idUnite,
    method: "GET"
  });
}

export function getOperation(annee, sirenCedant, sirenBeneficiaire) {
  return request({
    url: `/${annee}${api.operations.operation}?siren=${sirenCedant}&siren=${sirenBeneficiaire}`,
    method: "GET"
  });
}

function getOperations(annee, siren) {
  return request({
    url: `/${annee}${api.operations.operations}?siren=${siren}`,
    method: "GET"
  });
}

async function  getResultatOperations(annee, siren) {
  return await getOperations(annee, siren).then(response => {

    return  response.operation?.length;
  });
}

export function getOperationIdOperation(idOperation) {
  return request({
    url: `${api.operations.operation}/${idOperation}`,
    method: "GET"
  });
}
export function saveOperation(operation) {
  return request({
    url: api.operations.save,
    method: "POST",
    body: JSON.stringify(operation)
  });
}
export function deleteOperation(idOperation, source) {
  return request({
    url: api.operations.delete + idOperation + "?source=" + source,
    method: "DELETE"
  });
}
export function getUpdatableFields(idOperation) {
  return request({
    url: api.operations.updatable + "?idOperation=" + idOperation,
    method: "GET"
  });
}

export function saveCommentaire(commentaire) {
  return request({
    url: api.commentaires.commentaire,
    method: "POST",
    body: JSON.stringify(commentaire)
  });
}
export function getCommentairesOperation(idOperation) {
  return request({
    url: `${api.commentaires.operation}/${idOperation}`,
    method: "GET"
  });
}
export function getCommentairesEnveloppe(idEnveloppe, vue) {
  return request({
    url: `${api.commentaires.enveloppe}/${idEnveloppe}?vue=${vue}`,
    method: "GET"
  });
}
export function getCommentairesUnite(idUnite, vue) {
  return request({
    url: `${api.commentaires.unite}/${idUnite}?vue=${vue}`,
    method: "GET"
  });
}
export function getPdfFiche(nomFichier) {
  return requestFile({
    url: `${api.fiche}?nom=${nomFichier}`,
    method: "GET"
  });
}
export function searchListeFiches(filtre, page, size, sort) {
  return request({
    url: `${api.fiches.search}?page=${page - 1}&size=${size}&sort=${sort}`,
    method: "POST",
    body: JSON.stringify(filtre)
  });
}

