import React from "react";

import { Montant } from "components/utils/";

const libelle = {
  type: "Opération",
  dateRealisationJuridique: "Date de réalisation",
  dateEffetComptable: "Date d'effet comptable",
  montant: "Montant net",
  millesime: "Millésime",
};

const InfoOperation = (props) => {
  const operation = props.operation;
  if (operation.anneeCampagne) {
    return (
      <dl className="entete">
        <dt>{libelle.millesime} :</dt>
        <dd>{operation.anneeCampagne}</dd>
        <dt>{libelle.type} :</dt>
        <dd>{operation.typeOp ? operation.typeOp.libelle : null}</dd>
        <dt>{libelle.dateEffetComptable} :</dt>
        <dd>{operation.dateEffetComptable}</dd>
        <dt>{libelle.dateRealisationJuridique} :</dt>
        <dd>{operation.dateRealisationJuridique}</dd>
        <dt>{libelle.montant} :</dt>
        <dd>
          <Montant value={operation.montantNet} suffix=" K€ " />
        </dd>
      </dl>
    );
  }
  return <></>;
};

export default InfoOperation;
