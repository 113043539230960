import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { SvgIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import { ReactComponent as LogoCitrus } from "./img/citrus.svg";
import packageJson from "../../../../package.json";
import { isContrib } from "utils/user-utils";
import SelectionExpress from "./SelectionExpress";
import Menu from "./Menu";
import Aide from "./Aide";
import { getConfig } from "utils";

import "./topheader.scss";
import logo_insee from "./img/logo-insee-header.png";

const TopHeader = (props) => {
  const { user } = props;

  const location = useLocation();

  const [environnement, setEnvironnement] = useState(null);

  useEffect(() => {
    const getEnvironnement = async () => {
      const environnementActuel = (await getConfig()).ENVIRONNEMENT;
      setEnvironnement(environnementActuel);
    };
    getEnvironnement();
  });

  const getLogo = () => {
    if ("production" !== environnement) {
      return (
        <div className="bloc-gauche">
          <Link to={""} className="logoCitrus">
            <SvgIcon>
              <LogoCitrus />
            </SvgIcon>
            <span>CITRUS</span>
          </Link>
          <div className="environnement" id="environnement">
            {environnement}
          </div>
        </div>
      );
    } else {
      return (
        <Link to={""} className="logoCitrus">
          <SvgIcon>
            <LogoCitrus />
          </SvgIcon>
          <span>CITRUS</span>
        </Link>
      );
    }
  };

  return (
    <div className="topheader">
      {getLogo()}
      {isContrib(user) && (
        <label className="about" id="about">
          {packageJson.version}
        </label>
      )}
      <Menu location={location} />
      {isContrib(user) && (
        <Link to={"/creation"} className="boutonCreation">
          <AddIcon />
          <span>Créer une opération élémentaire</span>
        </Link>
      )}
      <div className="bloc-droite">
        <SelectionExpress location={location} />
        <Aide />
        <img className="logoInsee" alt="" src={logo_insee} />
      </div>
    </div>
  );
};

export default TopHeader;
