import React from "react";
import { NumericFormat } from 'react-number-format';

const separator = " ";

const Montant = (props) => {
  return (
    <NumericFormat
      value={props.value}
      displayType="text"
      decimalScale={2}
      thousandSeparator={separator}
      suffix={props.suffix}
    />
  );
};

export default Montant;
