import React from "react";
import { Grid, Tooltip, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import Comment from "@mui/icons-material/Comment";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import { getPdfFiche } from "../../../redux/api";
import { Identifiant, InputDate } from "components/utils/";
import { changeFormatDate, etatOpToLibelle, formatsDate, getHistoFromMetadata } from "utils/";

const colonnes = {
  details: {
    libelles: {
      enveloppe: "Env. UL",
      codeRejet: "Code rejet",
      libelleRejet: "Motif du rejet",
    },
    taille: 5,
  },
  dates: {
    libelles: {
      effet: "Effet compt.",
      realisation: "Réal. juridique",
      creation: "Création",
      modification: "Modification",
      rejet: "Rejet",
      traitement: "Traitement",
      refAnnonce: "N° annonce",
    },
    taille: 5,
  },
  autre: {
    libelles: {
      source: "Source",
      etat: "Etat",
    },
    taille: 2,
  },
};

const getUrlRefAnnonce = (refAnnonce) => {
  // Exemple refAnnonce = A_20200104_1
  if (refAnnonce) {
    const parties = refAnnonce.split("_");
    if (parties.length > 2) {
      return (
        "https://www.bodacc.fr/annonce/detail-annonce/" +
        parties[0] +
        "/" +
        parties[1] +
        "/" +
        parties[2]
      );
    }
  }
  return "#";
};

const handleGetPdf = (key) => {
  getPdfFiche(key);
};

const DetailOperation = (props) => {
  const {
    operation,
    submitModification,
    isUpdatable,
    rejet,
  } = props;

  // Désactivation Enveloppe en entreprise
  // colonnes.details.libelles.enveloppe = `Env. ${vueEntreprise ? "Ent." : "UL"}`;
  // const enveloppeEnCours = vueEntreprise ? operation.enveloppeEntreprise : operation.enveloppe
  
  const enveloppeEnCours = operation.enveloppe;

  const getSourceEtat = (operationParam) => {
    return (
      <>
        <dt>{`${colonnes.autre.libelles.source} :`}</dt>
        {operationParam.source &&
        operationParam.source.libelle === "BODACC" &&
        operationParam.refAnnonce ? (
          <dd>
            <a
              className="lienmenu"
              href={getUrlRefAnnonce(operationParam.refAnnonce)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {operationParam.source.libelle}
            </a>
          </dd>
        ) : (
          <dd>{operationParam.source ? operationParam.source.libelle : "N/A"}</dd>
        )}
        <br />
        <dt>{`${colonnes.autre.libelles.etat} :`}</dt>
        <dd>{etatOpToLibelle(operationParam.etat)}</dd>
      </>
    );
  };

  /**
   * On renvoie la date de Creation si plus grande que celle de modification
   */
  const getDateCreationOuModification = (operation) => {
    if (!operation.dateModification) {
      return changeFormatDate(operation.dateCreation)
    }

    return operation.dateCreation > operation.dateModification
                    ? changeFormatDate(operation.dateCreation)
                    : changeFormatDate(operation.dateModification);

  };

  return (
    <Grid container className="details">
      <Grid item xs={colonnes.dates.taille}>
        <dl>
          <dt>{`${colonnes.dates.libelles.effet} :`}</dt>
          <dd>
            <InputDate
              value={moment(
                operation.dateEffetComptable,
                formatsDate.formatDateWS
              )}
              libelle={colonnes.details.libelles.effet}
              onSubmit={(value) =>
                submitModification("dateEffetComptable", value)
              }
              {...props}
              isUpdatable={() => isUpdatable("dateEffetComptable")}
              tooltip={getHistoFromMetadata(null, "DATE_EFFET_COMPTABLE_OP", operation)}
            />
          </dd>
          <br />
          <dt>{`${colonnes.dates.libelles.realisation} :`}</dt>
          <dd>
            <InputDate
              value={moment(
                operation.dateRealisationJuridique,
                formatsDate.formatDateWS
              )}
              libelle={colonnes.details.libelles.realisation}
              onSubmit={(value) =>
                submitModification("dateRealisationJuridique", value)
              }
              {...props}
              isUpdatable={() => isUpdatable("dateRealisationJuridique")}
              tooltip={getHistoFromMetadata(null, "DATE_REALISATION_JURIDIQUE_OP", operation)}
            />
          </dd>
          <br />
          {rejet ? (
            <>
              <dt>{`${colonnes.dates.libelles.rejet} :`}</dt>
              <dd>{changeFormatDate(rejet.dateRejet)}</dd>
              <br />
              {rejet.commentaireTraitement ? (
                <Tooltip
                  title={rejet.commentaireTraitement}
                  PopperProps={{ className: "infoBulle" }}
                >
                  <dt>{`${colonnes.dates.libelles.traitement} :`}</dt>
                </Tooltip>
              ) : (
                <dt>{`${colonnes.dates.libelles.traitement} :`}</dt>
              )}
              <dd>{changeFormatDate(rejet.dateTraitement)}</dd> {/* #8 */}
            </>
          ) : (
            <>
              <dt>{`${colonnes.dates.libelles.traitement} :`}</dt>
              <Tooltip
                title={`Création : ${changeFormatDate(
                  operation.dateCreation
                )}, Modification : ${changeFormatDate(
                  operation.dateModification
                )}`}
                PopperProps={{ className: "infoBulle" }}
              >
                <dd>
                  {getDateCreationOuModification(operation)}
                </dd>
              </Tooltip>
            </>
          )}
          <br />
        </dl>
      </Grid>
      <Grid item xs={colonnes.details.taille}>
        <dl>
          {rejet ? (
            <>
              <dt>{`${colonnes.details.libelles.codeRejet} :`}</dt>
              <dd>{rejet.motifRejet.codeRejet}</dd>
              <br />
              <dt>{`${colonnes.details.libelles.libelleRejet} :`}</dt>
              <dd>{rejet.motifRejet.libelleRejet}</dd>
            </>
          ) : (
            <>
              <dt>{`${colonnes.details.libelles.enveloppe} :`}</dt>
              <dd>
                {enveloppeEnCours && (
                  <Tooltip
                    title={`APE : ${enveloppeEnCours.ape}`}
                    PopperProps={{ className: "infoBulle" }}
                  >
                    <Link
                      to={`/${operation.anneeCampagne}/${
                        enveloppeEnCours.publicId
                      }?vue=ul`}
                    >
                      <Identifiant value={enveloppeEnCours.publicId} />
                    </Link>
                  </Tooltip>
                )}
              </dd>
              <br />
              {getSourceEtat(operation)}
            </>
          )}
          <br />
        </dl>
      </Grid>
      <Grid item xs={colonnes.autre.taille}>
        {rejet ? (
          <dl>{getSourceEtat(operation)}</dl>
        ) : (
          enveloppeEnCours && (
            <p>
              <Link
                to={`/commentaires/${operation.anneeCampagne}/${
                  enveloppeEnCours.publicId
                }/${operation.idOperation}?vue=ul`}
              >
                <Comment />
                {`(${operation.nbCommentaire})`}
              </Link>
              <br />
              {operation.nomFiche != null && (
                <IconButton
                  aria-label="Télécharger la fiche de restructuration"
                  color="primary"
                  onClick={() => handleGetPdf(operation.nomFiche)}
                >
                  <AssignmentReturnedIcon />
                </IconButton>
              )}
            </p>
          )
        )}
      </Grid>
    </Grid>
  );
};

export default DetailOperation;
