import React from "react";

import { Identifiant, Ape, TrancheEffectif } from "components/utils/";

const libelle = {
  publicId: "Enveloppe en UL",
  ape: "APE",
  anneeCampagne: "Millésime",
  typeEnveloppe: "Type d'opération",
  coeffAgregation: "Coefficient d'agrégation",
  trancheEffectif: "Tranche d'effectif de l'enveloppe"
};

const InfoEnveloppe = (props) => {
  const enveloppe = props.enveloppe;
  if (enveloppe) {
    return (
      <dl className="entete">
        <dt>{libelle.publicId} :</dt>
        <dd>
          <Identifiant value={enveloppe.publicId} />
        </dd>
        <dt>{libelle.ape} :</dt>
        <dd>
          <Ape value={enveloppe.ape} />
        </dd>
        <dt>{libelle.anneeCampagne} :</dt>
        <dd>{enveloppe.anneeCampagne}</dd>
        <dt>{libelle.typeEnveloppe} :</dt>
        <dd>
          {enveloppe.typeEnveloppe ? enveloppe.typeEnveloppe.libelle : "N/A"}
        </dd>
        <dt>{libelle.coeffAgregation} :</dt>
        <dd>{enveloppe.coeffAgregation}</dd>
        <dt>{libelle.trancheEffectif} :</dt>
        <dd>
          <TrancheEffectif value={enveloppe.trancheEffectif} />
        </dd>
      </dl>
    );
  }
  return <></>;
}

export default InfoEnveloppe;
