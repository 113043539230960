import * as types from "./constantes";
import { selectionEnveloppe, searchEnveloppe } from "redux/api";

export const getEnveloppe = idEnveloppe => (dispatch, getState) => {
  dispatch({ type: types.ENVELOPPE_LOADING });
  const selection = getState().storeSelection.selected;
  if (
    selection &&
    selection.enveloppe &&
    selection.enveloppe.idEnveloppe === idEnveloppe
  ) {
    dispatch({
      type: types.ENVELOPPE_FOUND,
      enveloppe: getState().storeSelection.selected.enveloppe
    });
  }
  return selectionEnveloppe(idEnveloppe).then(response => {
    dispatch({ type: types.ENVELOPPE_FOUND, enveloppe: response });
  });
};

export const exportEnveloppes = (filtres, sort, entreprise) => (
  dispatch,
  getState
) => {
  if (filtres != null) {
    dispatch({ type: types.ENVELOPPES_EXPORTING });
    return searchEnveloppe(filtres, 1, 1000, sort, entreprise).then(
      response => {
        dispatch({ type: types.ENVELOPPES_EXPORTED, payload: response });
      }
    );
  }
  return null;
};

let numLastRequete = 0;

export const searchEnveloppes = (filtres, page, size, sort, vueEntreprise) => (
  dispatch,
  getState
) => {
  if (filtres != null) {
    let numRequete = numLastRequete;
    numLastRequete++;
    dispatch({ type: types.ENVELOPPES_LOADING, numRequete });
    return searchEnveloppe(filtres, page, size, sort, vueEntreprise).then(
      response => {
        dispatch({
          type: types.ENVELOPPES_FOUND,
          payload: response,
          numRequete
        });
      }
    );
  }
  return null;
};
