import React from "react";

const CommentaireTexte = (props) => {
  const texte = props.value;
  if (texte) {
    return texte.split("\n").map((ligne, index) => (
      <span key={index}>
        {ligne}
        <br />
      </span>
    ));
  }
  return null;
};

export default CommentaireTexte;
