import * as types from "redux/actions/constantes";

const initial = {
  selected: null,
  loading: false,
  id: null
};

const selection = (state = initial, action = { type: "" }) => {
  switch (action.type) {
    case types.SELECTION_LOADING: {
      return { ...state, loading: true };
    }
    case types.SELECTION_LOADED: {
      return {
        ...state,
        loading: false,
        selected: action.payload,
        id: action.id
      };
    }
    case types.SELECTION_UNSELECT: {
      return { ...initial };
    }
    default:
      return state;
  }
};
export default selection