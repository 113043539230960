import React from "react";

import { Ape, Identifiant } from "components/utils/";

const libelle = {
  ape: "APE",
};

const InfoUnite = (props) => {
  const { vueEntreprise, unite } = props;

  libelle.siren = vueEntreprise ? "Entreprise" : "Siren";
  libelle.rs = vueEntreprise ? "Dénomination" : "Raison sociale";

  if (unite) {
    return (
      <dl className="entete">
        <dt>{libelle.siren} :</dt>
        <dd>
          <Identifiant value={unite.siren} />
        </dd>
        <dt>{libelle.rs} :</dt>
        <dd>{unite.raisonSociale}</dd>
        <dt>{libelle.ape} :</dt>
        <dd>
          <Ape value={unite.ape} />
        </dd>
      </dl>
    );
  }
  return <></>;
};

export default InfoUnite;
