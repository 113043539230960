import { connect } from "react-redux";
import ListeFiches from "./ListeFiches";

import {
  searchFiches,
  setAriane,
  setAllFiltres,
  loadAnnees,
  setAllSorts,
} from "redux/actions"

const mapStateToProps = state => {
  return {
    annees: state.storeAnnees.annees,
    loading: state.storeFiches.loading,
    fiches: state.storeFiches.fiches,
    filtres: state.storeFiltre.filtres,
    page: state.storeFiltre.page,
    size: state.storeFiltre.size,
    sort: state.storeFiltre.sort
  };
};

export default connect(mapStateToProps, {
  searchFiches,
  setAriane,
  setAllFiltres,
  loadAnnees,
  setAllSorts
})(ListeFiches);
