import { connect } from "react-redux";
import Filtres from "./Filtres";
import { setFiltres } from "redux/actions";

const mapStateToProps = state => {
  return {
    allFiltres: state.storeFiltre.allFiltres,
    filtresActifs: state.storeFiltre.filtres
  };
};

export default connect(
  mapStateToProps,
  {
    setFiltres
  }
)(Filtres);
