import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { Navigate , useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Clear";

import Identifiant, { format, unformat } from "components/utils/Identifiant";
import { getOperationLastAnnee, getPublicId } from "utils";

import {
  FormControl,
  Paper,
  Popper,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Search from "@mui/icons-material/Search";
import { enqueueSnackbar } from 'notistack';

import "./selection-expess.scss";

const libelle = "Sélection express";
const idStorage = "suggestExpress";
const separatorStorage = "/";

const tip =
  "Saisir uniquement un Siren, un identifiant d'enveloppe en UL ou une entreprise profilée";

const regexInput = /^[PE]?\d{0,9}$/;

const SelectionExpress = (props) => {
  const { selectionExpress, id, unselect, selected } = props;

  const getAllSuggestions = () => {
    if (localStorage.getItem({ idStorage })) {
      return [
        ...new Set(
          localStorage
            .getItem({ idStorage })
            .split(separatorStorage)
            .filter((i) => i.length > 0)
        ),
      ];
    }
    return [];
  };

  const getSuggestions = (value) => {
    const inputValue = unformat(value).trim().toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return getAllSuggestions().filter((suggestion) => {
      const keep =
        count < 5 &&
        (inputLength === 0 ||
          suggestion.slice(0, inputLength).toLowerCase() === inputValue);

      if (keep) {
        count += 1;
      }

      return keep;
    });
  };

  const location = useLocation();

  const [valueState, setValueState] = useState("");
  const [suggestions, setSuggestions] = useState(getSuggestions(""));
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [popperNode, setPopperNode] = useState(null);
  const [warning, setWarning] = useState(null);

  const valideInput = (value) => {
    selectionExpress(value).then(() => {
      setValueState("");
      setSuggestions(getSuggestions(""));
      setShowSuggestions(false);
      setWarning(null);
    });
  };

  const saveAndValideInput = (value) => {
    if (regexInput.test(value) && unformat(value).trim().length > 0) {
      let olds = localStorage.getItem({ idStorage }) || "";
      localStorage.setItem({ idStorage }, `${value}${separatorStorage}${olds}`);
      valideInput(value);
    }
    return false;
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      saveAndValideInput(valueState);
    }
  };

  const onFocus = (event) => {
    setPopperNode(event.target);
    setShowSuggestions(true);
  };

  const onChange = (event, option) => {
    const value = unformat(option.newValue).toUpperCase();
    if (regexInput.test(value)) {
      setValueState(value);
      setShowSuggestions(true);
    }
    if (id) {
      unselect();
    }
  };



  const deleteSuggestion = (e, value) => {
    e.stopPropagation();
    localStorage.setItem(
      { idStorage },
      localStorage
        .getItem({ idStorage })
        .split(separatorStorage)
        .filter((s) => s !== value)
        .join(separatorStorage)
    );
    setSuggestions(getSuggestions(valueState));
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(valueState));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions(getAllSuggestions());
  };

  // Mise à jour du fils d'Ariane
  useEffect(() => {
    if (id && !selected && warning !== id) {
      enqueueSnackbar(`Aucune donnée trouvée pour l'identifiant ${id}`, {
        variant: "warning",
      });
      setWarning(id);
    }
    unselect();
  }, [id, selected, unselect, warning]);

  let redirection = null;
  if (selected) {
    if (selected.operation) {
      const operation = getOperationLastAnnee(selected.operation);
      if (id.startsWith("P")) {
        redirection = {
          pathname: `/${operation.anneeCampagne}/${getPublicId(
            operation.idEnveloppeEntreprise,
            operation.anneeCampagne
          )}/${id}`,
          search: "?vue=entreprise",
        };
      } else {
        redirection = {
          pathname: `/${operation.anneeCampagne}/${getPublicId(
            operation.idEnveloppe,
            operation.anneeCampagne
          )}/${id}`,
        };
      }
    }
    if (selected.idEnveloppe) {
      redirection = {
        pathname: `/${selected.anneeCampagne}/${selected.publicId}`,
      };
    }
  }
  return (
    <FormControl
      className="selection-express"
      aria-labelledby="label-selection-express"
    >
      {redirection && location.pathname !== redirection.pathname && (
        <Navigate replace to={redirection} />
      )}
      <Autosuggest
        id="input-selection-express"
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionSelected={(event, { suggestion }) => {
          valideInput(suggestion);
        }}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={(s) => s}
        renderSuggestion={(s) =>
          showSuggestions && (
            <MenuItem component="div">
              <Identifiant value={s} />
              <IconButton type="button" onClick={(e) => deleteSuggestion(e, s)}>
                <DeleteIcon type="button" />
              </IconButton>
            </MenuItem>
          )
        }
        inputProps={{
          value: format(valueState),
          onChange: onChange,
          onFocus: onFocus,
          onKeyPress: onKeyPress,
          placeholder: libelle,
          id: "input-selection-express",
        }}
        shouldRenderSuggestions={() => true}
        renderSuggestionsContainer={(options) => {
          return (
            <Popper
              anchorEl={popperNode}
              open={Boolean(options.children)}
              className="suggestions"
            >
              <Paper
                square
                {...options.containerProps}
                style={{
                  width: popperNode ? popperNode.clientWidth : null,
                }}
              >
                {options.children}
              </Paper>
            </Popper>
          );
        }}
        renderInputComponent={(inputProps) => (
          <>
            <div className="inputSelectionExpress">
              <Tooltip title={tip} PopperProps={{ className: "infoBulle" }}>
                <input {...inputProps} />
              </Tooltip>
              <IconButton
                aria-label="Rechercher"
                onClick={() => saveAndValideInput(valueState)}
              >
                <Search className="icon" />
              </IconButton>
            </div>
          </>
        )}
      />
    </FormControl>
  );
};

export default SelectionExpress;
