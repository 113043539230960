import { connect } from "react-redux";

import Content from "./Content";
import { loadUserInfo } from "redux/actions";

const mapStateToProps = state => {
  return {
    user: state.storeUser.user
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadUserInfo: () => dispatch(loadUserInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
