import { connect } from "react-redux";
import HistoriqueUl from "./HistoriqueUl";
import {
  setAriane,
  getHistorique,
  filtreOperations,
  setVueEntrepise
} from "redux/actions";

const mapStateToProps = state => {
  return {
    operations: state.storeUnites.operations,
    page: state.storeFiltre.page,
    size: state.storeFiltre.size,
    sort: state.storeFiltre.sort,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {
    setAriane,
    getHistorique,
    filtreOperations,
    setVueEntrepise
  }
)(HistoriqueUl);
