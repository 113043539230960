import React from "react";
import { Link } from "react-router-dom";
import Comment from "@mui/icons-material/Comment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { Identifiant, Ape, Montant } from "components/utils/";
import { getPublicId } from "utils/enveloppe-utils";
import ListeScroll from "../ListeScroll";

import "./listeUniteRecherche.scss";

const libelles = {
  siren: "Siren",
  rs: "Raison sociale",
  ape: "APE",
  benefOuCed: "Position dans les opérations",
  montantCedes: "Montant net des apports cédés en k€",
  montantRecu: "Montant net des apports reçus en k€",
  enveloppe: "Enveloppe en unités légales",
  enveloppeEntreprise: "Enveloppe en entreprises",
  anneeCampagne: "Millésime",
  nbCommentaire: "Commentaires",
};

const ListeUnitesRecherche = (props) => {
  const getLinkHistoriqueUnite = (unite, idEnveloppe, vue) => {
    if (idEnveloppe) {
      return (
        <Link
          to={`/${unite.anneeCampagne}/${idEnveloppe}/${unite.siren}?vue=${vue}`}
        >
          <Identifiant value={unite.siren} />
        </Link>
      );
    }
    return <Identifiant value={unite.siren} />;
  };

  const getRowUnite = (unite, vue) => {
    let benefOuCed = "Cédante et bénéficiaire";
    if (!unite.isBeneficiaire) {
      benefOuCed = "Cédante";
    } else if (!unite.isCedante) {
      benefOuCed = "Bénéficiaire";
    }
    const idEnveloppe =
      unite.idEnveloppe && getPublicId(unite.idEnveloppe, unite.anneeCampagne);

    const row = [
      <TableCell key="siren">
        {getLinkHistoriqueUnite(
          unite,
          vue === "entreprise" ? null : idEnveloppe,
          vue
        )}
      </TableCell>,
      <TableCell key="rs">{unite.raisonSociale}</TableCell>,
      <TableCell key="ape">
        <Ape value={unite.ape} />
      </TableCell>,
      <TableCell key="benefOuCed">{benefOuCed}</TableCell>,
    ];
    if (vue === "ul") {
      row.push([
        <TableCell key="montantCedes">
          <Montant value={unite.montantCedes} suffix=" K€" />
        </TableCell>,
        <TableCell key="montantRecu">
          <Montant value={unite.montantRecus} suffix=" K€" />
        </TableCell>,
        <TableCell key="enveloppe">
          <Link to={`/${unite.anneeCampagne}/${idEnveloppe}?vue=${vue}`}>
            <Identifiant value={idEnveloppe} />
          </Link>
        </TableCell>,
      ]);
    } else {
      row.push([
        <TableCell key="enveloppeEntreprise">
          <Identifiant value="" />
        </TableCell>,
      ]);
    }
    row.push([
      <TableCell key="anneeCampagne">{unite.anneeCampagne}</TableCell>,
      <TableCell key="nbCommentaire">
        <Link
          to={`/commentaires/${unite.anneeCampagne}/${idEnveloppe}/unite/${unite.idUnite}?vue=${vue}`}
        >
          <Comment />
          {`(${unite.nbCommentaire})`}
        </Link>
      </TableCell>,
    ]);
    return row;
  };

  const { vueEntreprise, unites } = props;

  const getContentListeScroll = () => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{libelles.siren}</TableCell>
            <TableCell>{libelles.rs}</TableCell>
            <TableCell>{libelles.ape}</TableCell>
            <TableCell>{libelles.benefOuCed}</TableCell>
            {!vueEntreprise && [
              <TableCell key="1">{libelles.montantCedes}</TableCell>,
              <TableCell key="2">{libelles.montantRecu}</TableCell>,
              <TableCell key="3">{libelles.enveloppe}</TableCell>,
            ]}
            {vueEntreprise && (
              <TableCell>{libelles.enveloppeEntreprise}</TableCell>
            )}
            <TableCell>{libelles.anneeCampagne}</TableCell>
            <TableCell>{libelles.commentaire}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {unites?.map((unite) => {
              return (
                <TableRow className="ligneUnite" key={unite.idUnite}>
                  {getRowUnite(unite, vueEntreprise ? "entreprise" : "ul")}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    );
  };

  libelles.siren = vueEntreprise ? "Entreprise" : "Siren";
  libelles.rs = vueEntreprise ? "Dénomination" : "Raison sociale";
  
  
  return <ListeScroll getContent={getContentListeScroll} />;
};

export default ListeUnitesRecherche;
