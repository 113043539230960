import React from "react";
import Chip from "@mui/material/Chip";

import Tris from "components/Listes/ListeHeader/Tris";

import "./infoFiltres.scss";

const libelles = ["Filtres appliqués :", "Tris appliqués :"];

const InfoFiltres = (props) => {
  const {
    removeFiltre,
    removeSort,
    allFiltres,
    filtres,
    sort,
    colonnes,
    sens,
  } = props;

  const handleDelete = (filtre) => {
    removeFiltre(filtre);
  };

  const handleDeleteSort = (sortParam) => {
    removeSort(sortParam);
  };

  const getValueLibelle = (filtre, value) => {
    if (allFiltres[filtre]?.liste) {
      const v = allFiltres[filtre].liste.filter(
        (f) => f.value && f.value.toString() === value
      )[0];
      if (v) {
        return v.libelle ? v.libelle : v;
      }
    }
    return value;
  };

  const constructFiltres = () => {
    let filtresAAppliquer = [];
    if (filtres && Object.keys(filtres).length > 0) {
      filtresAAppliquer.push(<span key="titre">{libelles[0]}</span>);
      Object.keys(filtres).forEach((k, i) => {
        const libelle = allFiltres[k] ? allFiltres[k].titre : null;
        if (Array.isArray(filtres[k])) {
          filtres[k].forEach((v, j) => {
            const value = getValueLibelle(k, v);
            filtresAAppliquer.push(
              <Chip
                key={i + "#" + j}
                label={`${libelle} : ${value}`}
                onDelete={() => handleDelete({ param: k, value: v })}
              />
            );
          });
        } else {
          const value = getValueLibelle(k, filtres[k]);
          filtresAAppliquer.push(
            <Chip
              key={k}
              label={`${libelle} : ${value}`}
              onDelete={() =>
                handleDelete({
                  param: k,
                  value: filtres[k],
                })
              }
            />
          );
        }
      });
    }
    return filtresAAppliquer;
  };

  const constructTris = () => {
    let trisCalc = [];
    if (sort && sort.length > 0) {
      trisCalc.push(<span key="titre">{libelles[1]}</span>);
      sort.forEach((s, i) => {
        const colonne = colonnes.filter((c) => c.value === s.slice(1))[0];
        const sensActuel = sens.filter((c) => c.value === s[0])[0];
        if (colonne && sensActuel) {
          trisCalc.push(
            <Chip
              key={i}
              label={`${colonne.libelle} : ${sensActuel.libelle}`}
              onDelete={() => handleDeleteSort(s)}
            />
          );
        }
      });
    }
    return trisCalc;
  };

  let filtresConstruits = constructFiltres();
  let tris = constructTris();
  if (filtresConstruits.length === 0 && colonnes.length === 0) {
    return null;
  }
  return (
    <div className="infoFiltres">
      <Tris />
      <div>
        {filtresConstruits.length > 0 && <div>{filtresConstruits}</div>}
        {tris.length > 0 && <div>{tris}</div>}
      </div>
    </div>
  );
};

export default InfoFiltres;
