

export const getArianeMillesime = (annee, isEntreprise) => {
  return {
    libelle: `Millésime : ${annee}`,
    link: `/liste/operation/${
      isEntreprise ? "entreprise" : "ul"
    }?annee=${annee}`
  };
};
export const getArianeEnveloppe = (annee, idEnv, isEntreprise) => {
  return {
    libelle: `Enveloppe en ${
      isEntreprise ? "entreprises" : "unités légales"
    } : ${idEnv}`,
    link: `/${annee}/${idEnv}${isEntreprise ? "?vue=entreprise" : ""}`
  };
};

export const completeSources = (sources) => {
  return [
    { libelle: "Tous", value: null },
    ...sources
      .map((t) => {
        return {
          libelle: t.libelle.charAt(0) + t.libelle.slice(1).toLowerCase(),
          value: t.libelle,
        };
      })
      .sort(),
  ];
};

export const completeTypesOp = (typesOp) => {
  return [
    { libelle: "Tous", value: null },
    ...typesOp
      .filter((t) => t.code !== "CO")
      .map((t) => {
        return { libelle: t.libelle, value: t.code };
      })
      .sort(),
  ];
};

export const completeAnnees = (annees) => {
  return [{ libelle: "Tous", value: null }, ...annees];
};
