import * as types from "redux/actions/constantes";

const initial = {
  vueEntreprise: false
};

const vueEntreprise = (state = initial, action = { type: "" }) => {
  if (action.type === types.SET_VUE) {
    return { vueEntreprise: action.vue };
  }
  return state;
};
export default vueEntreprise