import { connect } from "react-redux";
import PageSize from "./PageSize";
import { setPageSize } from "redux/actions";

const mapStateToProps = state => {
  return {
    page: state.storeFiltre.page,
    size: state.storeFiltre.size
  };
};

export default connect(
  mapStateToProps,
  {
    setPageSize
  }
)(PageSize);
