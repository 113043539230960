import * as types from "./constantes";
import { getTypesOp } from "redux/api";

export const loadTypesOp = () => (dispatch, getState) => {
  const { typesOp, loading } = getState().storeTypesOp;
  if (typesOp.length === 0 && !loading) {
    dispatch({ type: types.TYPES_OP_LOADING });
    return getTypesOp().then(response => {
      dispatch({ type: types.TYPES_OP_LOADED, payload: response });
    });
  }
  return null;
};
