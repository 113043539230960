import { connect } from "react-redux";
import SirenField from "./SirenField";
import { valideSiren } from "redux/actions";

const mapStateToProps = state => {
  return {
    sirensValides: state.storeSirens.sirensValides,
    sirensInvalides: state.storeSirens.sirensInvalides
  };
};

export default connect(
  mapStateToProps,
  {
    valideSiren
  }
)(SirenField);
