import { connect } from "react-redux";
import CommentairesListe from "./CommentairesListe";
import { addCommentaire } from "redux/actions";

const mapStateToProps = state => {
  return {
    commentaires: state.storeCommentaires.commentaires,
    attache: state.storeCommentaires.attache,
    filtres: state.storeFiltre.filtres
  };
};

export default connect(
  mapStateToProps,
  { addCommentaire }
)(CommentairesListe);
