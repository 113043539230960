import React from "react";
import { Link } from "react-router-dom";
import Comment from "@mui/icons-material/Comment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  Identifiant,
  Ape,
  TrancheEffectif,
} from "components/utils/";
import ListeScroll from "../ListeScroll";

import "./listeEnveloppeRecherche.scss";

const libelles = {
  publicId: "Enveloppe en unités légales",
  ape: "APE",
  typeEnv: "Type de restructuration",
  coeffAgregation: "Coefficient d'agrégation",
  trancheEffectif: "Tranche d'effectif de l'enveloppe",
  anneeCampagne: "Millésime",
  commentaire: "Commentaires",
};

const ListeEnveloppesRecherche = (props) => {

  const { vueEntreprise, enveloppes } = props;

  const getRowEnveloppe = (enveloppe, vueEntrepriseParam) => {
    return [
      <TableCell key="publicId">
        <Link
          to={`/${enveloppe.anneeCampagne}/${enveloppe.publicId}?vue=${
            vueEntrepriseParam ? "entreprise" : "ul"
          }`}
        >
          <Identifiant value={enveloppe.publicId} />
        </Link>
      </TableCell>,
      <TableCell key="ape">
        <Ape value={enveloppe.ape} />
      </TableCell>,
      <TableCell key="typeEnveloppe">
        {enveloppe.typeEnveloppe?.libelle}
      </TableCell>,
      <TableCell key="coeffAgregation">{enveloppe.coeffAgregation}</TableCell>,
      <TableCell key="trancheEffectif">
        <TrancheEffectif value={enveloppe.trancheEffectif} />
      </TableCell>,
      <TableCell key="anneeCampagne">{enveloppe.anneeCampagne}</TableCell>,
      <TableCell key="nbCommentaire">
        <Link
          to={`/commentaires/${enveloppe.anneeCampagne}/${
            enveloppe.publicId
          }?vue=${vueEntrepriseParam ? "entreprise" : "ul"}`}
        >
          <Comment />
          {`(${enveloppe.nbCommentaire})`}
        </Link>
      </TableCell>,
    ];
  };
  
  const getContentListeScroll = () => {
    return <Table>
      <TableHead id="tableHead">
        <TableRow>
          <TableCell>{libelles.publicId}</TableCell>
          <TableCell>{libelles.ape}</TableCell>
          <TableCell>{libelles.typeEnv}</TableCell>
          <TableCell>{libelles.coeffAgregation}</TableCell>
          <TableCell>{libelles.trancheEffectif}</TableCell>
          <TableCell>{libelles.anneeCampagne}</TableCell>
          <TableCell>{libelles.commentaire}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {enveloppes?.map((enveloppe) => {
            return (
              <TableRow
                className="ligneEnveloppe"
                key={enveloppe.idEnveloppe}
              >
                {getRowEnveloppe(enveloppe, vueEntreprise)}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  }
  
  libelles.publicId = `Enveloppe en ${
    vueEntreprise ? "entreprise" : "unités légales"
  }`;

  return (
    <ListeScroll
      getContent={getContentListeScroll}
    />
  );
};

export default ListeEnveloppesRecherche;
