import React from "react";
import Help from "@mui/icons-material/Help";
import RcMenu, { SubMenu, Item as MenuItem } from "rc-menu";

import "./aide.scss";

const documents = [
  // #16
  {
    libelle: "Guide d'utilisation Citrus",
    doc: "GuideUtilisation.pdf",
    indice: 1
  },
  // #14
  {
    libelle: "Historique des versions",
    doc: "HistoriqueVersions.pdf",
    indice: 2
  },
];

const contactezMoi = "Contactez-moi";
const emailCitrus = "citrus@insee.fr";

const Aide = () => {
  const handleClose = (menu) => {
    menu.key = null;
  };

  return (
    <RcMenu mode="horizontal" className="menu">
      <SubMenu
        id="menu-aide"
        title={
          <>
            <Help className="icone" /> Aide
          </>
        }
      >
        {documents.map((d, i) => (
          <MenuItem key={d.indice} onClick={handleClose}>
            <a
              className="lienmenu"
              download
              href={process.env.PUBLIC_URL + "/aide/" + d.doc}
            >
              {d.libelle}
            </a>
          </MenuItem>
        ))}

        <MenuItem key="contact" onClick={handleClose}>
          <a className="lienmenu" href={"mailto:" + emailCitrus}>
            {contactezMoi}
          </a>
        </MenuItem>
      </SubMenu>
    </RcMenu>
  );
};

export default Aide;
