import * as types from "./constantes";

import {
    searchListeFiches,
  } from "redux/api";

let numLastRequete = 0;

export const searchFiches = (filtres, page, size, sort) => (
  dispatch,
  getState
) => {
  if (filtres != null) {
    let numRequete = numLastRequete;
    numLastRequete++;
    dispatch({ type: types.FICHES_LOADING, numRequete: numRequete });
    return searchListeFiches(filtres, page, size, sort).then(response => {
      dispatch({
        type: types.FICHES_FOUND,
        payload: response,
        numRequete: numRequete
      });
    });
  }
  return null;
};

export const exportFiches = (filtres, sort) => (
  dispatch,
  getState
) => {
  if (filtres != null) {
    dispatch({ type: types.FICHES_EXPORTING });
    return searchListeFiches(filtres, 1, 1000, sort).then(response => {
      dispatch({ type: types.FICHES_EXPORTED, payload: response });
    });
  }
  return null;
};


