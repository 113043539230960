import React from "react";

import ListeScroll from "../ListeScroll";
import Identifiant from "components/utils/Identifiant";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from "@mui/material";
import { Link } from "react-router-dom";
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { getPdfFiche } from "../../../redux/api";

import "./listeFichesRecherche.scss";

function handleGetPdf(key) {
  getPdfFiche(key)
}

const libelles = {
  nomFiche: "Identifiant de la fiche",
  telecharger: "",
  annee: "Millésime",
  sirenCedant : "Siren cédant",
  apeCedant : "APE cédant",
  raisonSocialeCedant : "Raison sociale cédant",
  sirenBeneficiaire : "Siren bénéficiaire",
  raisonSocialeBeneficiaire : "Raison sociale bénéficiaire",
  apeBeneficiaire : "APE bénéficiaire",
  dateEnregistrement : "Date d'enregistrement de la fiche"
};

const ListeFichesRecherche = (props) => {
  
  const fiches = props.fiches;

  const getLink = (annee, siren, enveloppe) => {
    if (enveloppe) {
      return (
        <Link to={`/${annee}/${enveloppe.publicId}/${siren}?vue=ul`}>
          <Identifiant value={siren} />
        </Link>
      );
    }
    return <Identifiant value={siren} />;
  }

  const getRowFiche = (fiche) => {
    return [
      <TableCell key="nomFiche"> {fiche.nomFiche} </TableCell>,
      <TableCell key="telecharger"> <IconButton aria-label="Télécharger la fiche de restructuration"
      color="primary" onClick={() => handleGetPdf(fiche.nomFiche)}>
      <AssignmentReturnedIcon />
      </IconButton></TableCell>,
      <TableCell key="annee">{fiche.operation.anneeCampagne}</TableCell>,
      <TableCell key="sirenCedant">{getLink(fiche.operation.anneeCampagne, fiche.operation.uniteCedante.siren, fiche.operation.enveloppe)}</TableCell>,
      <TableCell key="raisonSocialeCedant">{fiche.operation.uniteCedante.raisonSociale}</TableCell>,
      <TableCell key="apeCedant">{fiche.operation.uniteCedante.ape}</TableCell>,
      <TableCell key="sirenBeneficiaire">{getLink(fiche.operation.anneeCampagne, fiche.operation.uniteBeneficiaire.siren, fiche.operation.enveloppe)}</TableCell>,
      <TableCell key="raisonSocialeBeneficiaire">{fiche.operation.uniteBeneficiaire.raisonSociale}</TableCell>,
      <TableCell key="apeBeneficiaire">{fiche.operation.uniteBeneficiaire.ape}</TableCell>,
      <TableCell key="dateEnregistrement">{fiche.dateCreation}</TableCell>,
    ];
  }

  const getContentListeScroll = () => {
    return <Table>
    <TableHead id="tableHead">
      <TableRow>
        <TableCell>{libelles.nomFiche}</TableCell>
        <TableCell>{libelles.telecharger}</TableCell>
        <TableCell>{libelles.annee}</TableCell>
        <TableCell>{libelles.sirenCedant}</TableCell>
        <TableCell>{libelles.raisonSocialeCedant}</TableCell>
        <TableCell>{libelles.apeCedant}</TableCell>
        <TableCell>{libelles.sirenBeneficiaire}</TableCell>
        <TableCell>{libelles.raisonSocialeBeneficiaire}</TableCell>
        <TableCell>{libelles.apeBeneficiaire}</TableCell>
        <TableCell>{libelles.dateEnregistrement}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {fiches?.map(fiche => {
          return (
            <TableRow
              className="ligneFiche"
              key={fiche.idFiche}
            >
              {getRowFiche(fiche)}
            </TableRow>
          );
        })}
    </TableBody>
  </Table>
  }

    return (
      <ListeScroll
        getContent={getContentListeScroll}
      />
    );
}

export default ListeFichesRecherche;
