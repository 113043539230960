import { connect } from "react-redux";
import ListeUnitesRecherche from "./ListeUnitesRecherche";

const mapStateToProps = state => {
  return {
    unites: state.storeUnites.unites,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {}
)(ListeUnitesRecherche);
