import { connect } from "react-redux";
import CreationCommentaire from "./CreationCommentaire";
import {
  loadOrigineInfoCommentaires,
  loadTypesCommentaires
} from "redux/actions";

const mapStateToProps = state => {
  return {
    types: state.storeCommentaires.types,
    originesInfo: state.storeCommentaires.originesInfo,
    user: state.storeUser.user
  };
};

export default connect(
  mapStateToProps,
  {
    loadOrigineInfoCommentaires,
    loadTypesCommentaires
  }
)(CreationCommentaire);
