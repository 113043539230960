import { connect } from "react-redux";
import Operation from "./Operation";
import { save, correction } from "redux/actions";

const mapStateToProps = state => {
  return {
    user: state.storeUser.user
  };
};

export default connect(mapStateToProps, {
  save,
  correction
})(Operation);
