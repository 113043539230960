import React, { useEffect } from "react";
import moment from "moment";
import "moment/locale/fr";
import { Paper } from "@mui/material";
import { Reply } from "@mui/icons-material";

import { CommentaireTexte } from "components/utils";

import "./commentaire.scss";

const libelles = {
  source: "Source",
  type: "Type",
  origine: "Origine de l'information",
  date: "Publié le",
  absent: "Autre",
};

const Commentaire = (props) => {
  const {
    loadOrigineInfoCommentaires,
    loadTypesCommentaires,
    commentaire,
    types,
    originesInfo,
    complement,
  } = props;

  // Chargement initial
  useEffect(() => {
    loadOrigineInfoCommentaires();
    loadTypesCommentaires();
  }, [loadOrigineInfoCommentaires, loadTypesCommentaires]);

  if (commentaire) {
    const type = types.find((t) => t.code === commentaire.typeCommentaire);
    const origine = originesInfo.find(
      (t) => t.code === commentaire.origineInfo
    );
    const date = moment(commentaire.date, "DD-MM-YYYY hh:mm:ss").locale("fr");

    return (
      <Paper
        className="commentaire"
        style={{ marginLeft: complement ? "5%" : "" }}
      >
        <dl className="entete date">
          <dt>{libelles.date} :</dt>
          <dd title={date.format("LLL")}>{date.format("DD/MM/YYYY")}</dd>
        </dl>
        <h3>
          {complement ? <Reply fontSize="large" /> : ""}
          {commentaire.redacteur}
        </h3>
        <dl className="entete">
          <dt>{libelles.source} :</dt>
          <dd>
            {commentaire.source ? commentaire.source.libelle : libelles.absent}
          </dd>
          <dt>{libelles.type} :</dt>
          <dd>{type ? type.libelle : libelles.absent}</dd>
        </dl>
        <p className="contenu">
          <CommentaireTexte value={commentaire.contenu} />
        </p>
        <dl className="entete">
          <dt>{libelles.origine} :</dt>
          <dd>{origine ? origine.libelle : libelles.absent}</dd>
        </dl>
      </Paper>
    );
  }
  return null;
};

export default Commentaire;
