import * as types from "./constantes";

export const addFiltre = filtre => (dispatch, getState) => {
  dispatch({ type: types.ADD_FILTRE, filtre });
};
export const removeFiltre = filtre => (dispatch, getState) => {
  dispatch({ type: types.REMOVE_FILTRE, filtre });
};
export const setFiltres = filtres => (dispatch, getState) => {
  dispatch({ type: types.SET_FILTRE, filtres });
};
export const setAllFiltres = filtres => (dispatch, getState) => {
  dispatch({ type: types.SET_ALL_FILTRE, filtres });
};
export const setPageSize = size => (dispatch, getState) => {
  size = size ? size : 20;
  dispatch({ type: types.SET_PAGE_SIZE, size });
};
export const setPage = page => (dispatch, getState) => {
  page = page ? parseInt(page) : 1;
  dispatch({ type: types.SET_PAGE, page });
};
export const addSort = sort => (dispatch, getState) => {
  dispatch({ type: types.ADD_SORT, sort });
};
export const removeSort = sort => (dispatch, getState) => {
  dispatch({ type: types.REMOVE_SORT, sort });
};
export const setSorts = sorts => (dispatch, getState) => {
  dispatch({ type: types.SET_SORT, sorts });
};
export const setAllSorts = sorts => (dispatch, getState) => {
  dispatch({ type: types.SET_ALL_SORT, sorts });
};
