import { connect } from "react-redux";
import InfoEnveloppe from "./InfoEnveloppe";

const mapStateToProps = state => {
  return {
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {}
)(InfoEnveloppe);
