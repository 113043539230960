import React, { useEffect } from "react";

import Filtres from "components/Listes/Filtres";
import ListeHeader from "components/Listes/ListeHeader";
import ListeOperationsRecherche from "components/Listes/ListeOperationsRecherche";
import { naf } from "components/utils/naf";
import { completeSources, completeTypesOp, completeAnnees } from "components/utils/";
import { useParams } from "react-router-dom";

let champsTrisOp = [
  { libelle: "Millésime", value: "annee" },
  { libelle: "Type d'opération", value: "type" },
  { libelle: "Siren cédant", value: "sirenc" },
  { libelle: "Siren bénéficiaire", value: "sirenb" },
  { libelle: "Enveloppe", value: "enveloppe" },
  { libelle: "Code APE cédante", value: "apec" },
  { libelle: "Code APE bénéficiaire", value: "apeb" },
  { libelle: "Nombre de commentaires", value: "nbCommentaire" },
  { libelle: "Montant net", value: "montant" },
  { libelle: "Date de réalisation juridique", value: "realisation" },
  { libelle: "Date d'effet comptable", value: "effet" },
  { libelle: "Date de création", value: "creation" },
];

const filtresExistant = {
  mot: { titre: "Id. UL / Ent. / Env.", exemple: "552 100 554" },
  rs: { titre: "Raison sociale", exemple: "PEUGEOT PSA" },
  groupe: {
    titre: "Groupe",
    exemple: "G13008685 ou LA POSTE",
  },
  annee: {
    titre: "Millésime",
    liste: [],
  },
  ape: {
    titre: "Code APE Siren",
    liste: naf,
    exemple: "0220Z",
  },
  typeop: {
    titre: "Type d'opération",
    liste: [],
  },
  apeEnv: {
    titre: "APE enveloppe en UL",
    liste: naf,
    exemple: "0220Z",
  },
  etat: {
    titre: "État de l'opération",
    liste: [
      { libelle: "Tous", value: null },
      { libelle: "Automatique", value: "A" },
      { libelle: "Expertisée", value: "T" },
      { libelle: "Redressée", value: "R" },
    ],
  },
  source: {
    titre: "Source",
    liste: [],
  },
};

const ListeOperations = (props) => {
  const {
    loadAnnees,
    loadTypesOp,
    loadSources,
    setAriane,
    setVueEntrepise,
    setAllFiltres,
    vueEntreprise,
    searchOperations,
    filtres,
    page,
    size,
    sort,
    setAllSorts,
    annees,
    typesOp,
    sources,
  } = props;

  // /liste/operation/:entreprise
  const {entreprise } = useParams();

  // On charge une fois les données annexes
  useEffect(() => {
    loadAnnees();
    loadTypesOp();
    loadSources();
  }, [loadAnnees, loadTypesOp, loadSources]);

  // On charge la liste des opérations
  useEffect(() => {
    searchOperations(
      filtres,
      page,
      size,
      [...sort, "didOperation"],
      vueEntreprise
    );
  }, [searchOperations, filtres, page, size, vueEntreprise, sort]);

  // Gestion du cas (vue=entreprise) et chargement du tri
  useEffect(() => {
    const isVueEntreprise = entreprise === "entreprise";
    setVueEntrepise(isVueEntreprise);

    setAriane([
      {
        libelle: `Opérations en ${
          isVueEntreprise ? "Entreprises (⚠ Les enveloppes en entreprises sont suspendues pour une durée indéterminée)" : "Unités Légales"
        }`,
      },
    ]);
    const libelle = isVueEntreprise ? "entreprises " : "UL";
    filtresExistant.apeEnv.titre = `APE enveloppe en ${libelle}`;
    champsTrisOp.splice(4, 1, {
      libelle: `Enveloppe en ${libelle}`,
      value: "enveloppe",
    });
    champsTrisOp = champsTrisOp.filter((c) => !c.value.includes("entreprise"));
    if (isVueEntreprise) {
      champsTrisOp.push({
        libelle: "Entreprise cédante",
        value: "entreprisec",
      });
      champsTrisOp.push({
        libelle: "Entreprise bénéficiaire",
        value: "entrepriseb",
      });
    }
    setAllSorts(champsTrisOp);
  }, [entreprise, setAllSorts, setAriane, setVueEntrepise]);

  // Chargement des filtres avec les années
  useEffect(() => {
    
    let newFiltres = structuredClone(filtresExistant);

    // completeSources
    newFiltres.source.liste = completeSources(sources);

    // completeTypeOp
    newFiltres.typeop.liste = completeTypesOp(typesOp);

    // completeAnnees
    newFiltres.annee.liste = completeAnnees(annees);;

    setAllFiltres(newFiltres);

  }, [setAllFiltres, sources, typesOp, annees]);

  return (
    <div className="block-main">
      <Filtres />
      <div className="block-content">
        <ListeHeader />
        <ListeOperationsRecherche />
      </div>
    </div>
  );
};

export default ListeOperations;
