export const ANNEES_LOADED = "ANNEES_LOADED";
export const ANNEES_LOADING = "ANNEES_LOADING";
export const TYPES_OP_LOADED = "TYPES_OP_LOADED";
export const TYPES_OP_LOADING = "TYPES_OP_LOADING";
export const SOURCES_LOADED = "SOURCES_LOADED";
export const SOURCES_LOADING = "SOURCES_LOADING";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const SOURCE_LOADED = "SOURCE_LOADED";
export const OPERATIONS_LOADING = "OPERATIONS_LOADING";
export const OPERATIONS_FOUND = "OPERATIONS_FOUND";
export const OPERATIONS_NB_FOUND = "OPERATIONS_NB_FOUND";
export const OPERATIONS_DELETE = "OPERATIONS_DELETE";
export const OPERATIONS_UPDATE = "OPERATIONS_UPDATE";
export const OPERATIONS_CREATE = "OPERATIONS_CREATE";
export const OPERATION_UPDATABLE = "OPERATION_UPDATABLE";
export const OPERATIONS_EXPORTING = "OPERATIONS_EXPORTING";
export const OPERATIONS_EXPORTED = "OPERATIONS_EXPORTED";
export const OPERATION_FOUND = "OPERATION_FOUND";
export const SELECTION_LOADING = "SELECTION_LOADING";
export const SELECTION_LOADED = "SELECTION_LOADED";
export const SELECTION_UNSELECT = "SELECTION_UNSELECT";
export const SET_ARIANE = "SET_ARIANE";
export const ADD_FILTRE = "ADD_FILTRE";
export const SET_FILTRE = "SET_FILTRE";
export const SET_ALL_FILTRE = "SET_ALL_FILTRE";
export const REMOVE_FILTRE = "REMOVE_FILTRE";
export const SET_PAGE_SIZE = "SET_PAGE_SIZE";
export const SET_PAGE = "SET_PAGE";
export const REMOVE_SORT = "REMOVE_SORT";
export const ADD_SORT = "ADD_SORT";
export const SET_SORT = "SET_SORT";
export const SET_ALL_SORT = "SET_ALL_SORT";
export const ENVELOPPE_LOADING = "ENVELOPPE_LOADING";
export const ENVELOPPE_FOUND = "ENVELOPPE_FOUND";
export const ENVELOPPES_LOADING = "ENVELOPPES_LOADING";
export const ENVELOPPES_FOUND = "ENVELOPPES_FOUND";
export const ENVELOPPES_EXPORTING = "ENVELOPPES_EXPORTING";
export const ENVELOPPES_EXPORTED = "ENVELOPPES_EXPORTED";
export const ESANE_DATA_LOADING = "ESANE_DATA_LOADING";
export const ESANE_DATA_FOUND = "ESANE_DATA_FOUND";
export const ESANE_DATA_RESET = "ESANE_DATA_RESET";
export const UNITE_FOUND = "UNITE_FOUND";
export const UNITE_LOADING = "UNITE_LOADING";
export const HISTORIQUE_FOUND = "HISTORIQUE_FOUND";
export const HISTORIQUE_LOADING = "HISTORIQUE_LOADING";
export const UNITES_FOUND = "UNITES_FOUND";
export const UNITES_LOADING = "UNITES_LOADING";
export const UNITES_EXPORTING = "UNITES_EXPORTING";
export const UNITES_EXPORTED = "UNITES_EXPORTED";
export const GET_SIREN = "GET_SIREN";
export const ORIGINES_LOADED = "ORIGINES_LOADED";
export const TYPES_LOADED = "TYPES_LOADED";
export const COMMENTAIRES_LOADED = "COMMENTAIRES_LOADED";
export const COMMENTAIRES_LOADING = "COMMENTAIRES_LOADING";
export const COMMENTAIRE_SAVED = "COMMENTAIRE_SAVED";
export const COMMENTAIRE_ATTACHE = "COMMENTAIRE_ATTACHE";
export const SET_VUE = "SET_VUE";
export const REJETS_LOADING = "REJETS_LOADING";
export const REJETS_FOUND = "REJETS_FOUND";
export const REJETS_MOTIFS_LOADING = "REJETS_MOTIF_LOADING";
export const REJETS_MOTIFS_LOADED = "REJETS_MOTIF_LOADED";
export const REJET_EXPERTISE = "REJET_EXPERTISE";
export const REJET_CORRECTION = "REJET_CORRECTION";
export const FICHES_LOADING = "FICHES_LOADING";
export const FICHES_FOUND = "FICHES_FOUND";
export const FICHES_EXPORTING = "FICHES_EXPORTING";
export const FICHES_EXPORTED = "FICHES_EXPORTED";
