import React from "react";
import { ListItem, List } from "@mui/material";
import { Comment } from "@mui/icons-material";

import FiltresCommentairesAvances from "../FiltresCommentairesAvances";
import { format } from "components/utils/Identifiant";

import "./filtresCommentaires.scss";

const getNbCommentaires = (attache) => {
  const object =
    attache.operation ||
    attache.enveloppe ||
    attache.enveloppeEntreprise ||
    attache.uniteCedante ||
    attache.uniteBeneficiaire ||
    attache.unite ||
    attache.entreprise;
  return object.nbCommentaire || 0;
};

const libelleSiren = {
  siren: "Siren",
  cedante: "Siren cédant",
  beneficiaire: "Siren bénéficiaire",
};
const libellesEntreprise = {
  siren: "Entreprise",
  cedante: "Entreprise cédante",
  beneficiaire: "Entreprise bénéficiaire",
};

const getLibelle = (attache, vueEntreprise) => {
  if (attache.operation) {
    const libelle = attache.operation.codeTypeOperation
      ? attache.operation.codeTypeOperation.libelle
      : "Opération";
    const benef = attache.operation.uniteBeneficiaire
      ? attache.operation.uniteBeneficiaire.siren
      : "";
    const ced = attache.operation.uniteCedante
      ? attache.operation.uniteCedante.siren
      : "";
    return `${libelle} (${ced} > ${benef})`;
  }
  const libelles = vueEntreprise ? libellesEntreprise : libelleSiren;
  if (attache.enveloppe) {
    return `Enveloppe en unités légales : ${format(
      attache.enveloppe.publicId
    )}`;
  }
  if (attache.enveloppeEntreprise) {
    return `Enveloppe en entreprise : ${format(
      attache.enveloppeEntreprise.publicId
    )}`;
  }
  if (attache.uniteCedante) {
    return `${libelles.cedante} : ${format(attache.uniteCedante.siren)}`;
  }
  if (attache.uniteBeneficiaire) {
    return `${libelles.beneficiaire} : ${format(
      attache.uniteBeneficiaire.siren
    )}`;
  }
  if (attache.unite) {
    return `${libelles.siren} : ${format(attache.unite.siren)}`;
  }
  if (attache.entreprise) {
    return `Entreprise : ${format(attache.entreprise.siren)}`;
  }
  return "";
};
const equals = (attache1, attache2) => {
  if (attache2.operation) {
    return (
      attache1.operation &&
      attache1.operation.idOperation === attache2.operation.idOperation
    );
  }
  if (attache2.enveloppeEntreprise) {
    return (
      attache1.enveloppeEntreprise &&
      attache1.enveloppeEntreprise.idEnveloppe ===
        attache2.enveloppeEntreprise.idEnveloppe
    );
  }
  if (attache2.enveloppe) {
    return (
      attache1.enveloppe &&
      attache1.enveloppe.idEnveloppe === attache2.enveloppe.idEnveloppe
    );
  }
  if (attache2.uniteCedante) {
    return (
      attache1.uniteCedante &&
      attache1.uniteCedante.siren === attache2.uniteCedante.siren
    );
  }
  if (attache2.uniteBeneficiaire) {
    return (
      attache1.uniteBeneficiaire &&
      attache1.uniteBeneficiaire.siren === attache2.uniteBeneficiaire.siren
    );
  }
  if (attache2.unite) {
    return attache1.unite && attache1.unite.siren === attache2.unite.siren;
  }
  if (attache2.entreprise) {
    return (
      attache1.entreprise &&
      attache1.entreprise.siren === attache2.entreprise.siren
    );
  }
  return false;
};

const FiltresCommentaires = (props) => {
  const { setAttache, vueEntreprise, attaches } = props;

  return (
    <div className="block-gauche filtresCommentaire">
      <h3>Commentaires Liés</h3>
      <List className="contenuFiltre">
        {attaches.map((attache, index) => {
          const selected = equals(attache, attache);
          const className = selected ? "selected" : "";
          const niveau = attache.niveau * 5;
          const nbCommentaires = getNbCommentaires(attache);
          return nbCommentaires > 0 || index === 0 ? (
            <ListItem
              key={index}
              onClick={() => setAttache(attache)}
              className={className}
              style={{ paddingLeft: `${niveau}%` }}
            >
              <span>{getLibelle(attache, vueEntreprise)}</span>
              <span className="nbCommentairesIcone">
                <Comment />({nbCommentaires})
              </span>
            </ListItem>
          ) : null;
        })}
      </List>
      <FiltresCommentairesAvances />
    </div>
  );
};

export default FiltresCommentaires;
