import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { LinearProgress } from "@mui/material";
import Favicon from "react-favicon";
import { initKeycloak } from "redux/api";
import Logout from "components/Logout/Logout";

import store from "redux/store";
import Content from "components/Content";

const App = () => {
  const [connected, setConnected] = useState(null);
  const logoutRequest = window.location.pathname === "/logout";

  useEffect(() => {
    if (!logoutRequest) {
      const connection = async () => {
        setConnected(await initKeycloak());
      };


      document.title = "Citrus";
      connection();
    }
  }, [logoutRequest]);

  if (logoutRequest) {
    return <Logout/>
  }

  if (connected?.authenticated) {
    return (
      <Provider store={store}>
        <Favicon url={window.location.origin + "/favicon.ico"} />
        <BrowserRouter>

         <Content/>
        </BrowserRouter>
      </Provider>
    );
  }
  return <LinearProgress />;
};

export default App;
