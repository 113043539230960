import React, { useEffect } from "react";

import Filtres from "components/Listes/Filtres/filtres.container";

const filtresExistant = {
  origine: { titre: "Origine de l'information", liste: [] },
  source: { titre: "Source", liste: [] },
  type: { titre: "Type de commentaire", liste: [] },
};

const FiltresCommentairesAvances = (props) => {
  const {
    loadOrigineInfoCommentaires,
    loadTypesCommentaires,
    loadSources,
    setAllSorts,
    setSorts,
    setFiltres,
    setAllFiltres,
    sources,
    types,
    originesInfo,
  } = props;

  // On charge une fois les données annexes, on initialise le tri et le filtre à vide
  useEffect(() => {
    loadOrigineInfoCommentaires();
    loadTypesCommentaires();
    loadSources();
    setAllSorts([]);
    setSorts([]);
    setFiltres([]);
  }, [loadOrigineInfoCommentaires, loadSources, loadTypesCommentaires, setAllSorts, setFiltres, setSorts]);

  // Chargement des filtres avec les années
  useEffect(() => {
    let newFiltres = structuredClone(filtresExistant);
    // completeSources
    newFiltres.source.liste = [
      { libelle: "Tous", value: null },
      ...sources
        .filter(
          (t) =>
            t.libelle.toLowerCase() !== "bodacc" &&
            t.libelle.toLowerCase() !== "balo"
        )
        .map((t) => {
          return {
            libelle: t.libelle.charAt(0) + t.libelle.slice(1).toLowerCase(),
            value: t.libelle,
          };
        })
        .sort(),
    ];
    // completeType
    newFiltres.type.liste = [
      { libelle: "Tous", value: null },
      ...types
        .map((t) => {
          return { libelle: t.libelle, value: t.code.toString() };
        })
        .sort(),
    ];
    // completeOrigine
    newFiltres.origine.liste = [
      { libelle: "Toutes", value: null },
      ...originesInfo
        .map((o) => {
          return { libelle: o.libelle, value: o.code.toString() };
        })
        .sort(),
    ];

    setAllFiltres(newFiltres);
  }, [setAllFiltres, sources, types, originesInfo]);

  return <Filtres titre="Filtres avancés" />;
};

export default FiltresCommentairesAvances;
