import React, { useEffect } from "react";

import ListeHeader from "components/Listes/ListeHeader";
import ListeOperationsRecherche from "components/Listes/ListeOperationsRecherche";

let champsTris = [
  { libelle: "Siren cédant", value: "sirenc" },
  { libelle: "Siren bénéficiaire", value: "sirenb" },
  { libelle: "Code APE cédante", value: "apec" },
  { libelle: "Code APE bénéficiaire", value: "apeb" },
  { libelle: "Montant net", value: "montant" },
  { libelle: "Nombre de commentaires", value: "nbCommentaire" },
  { libelle: "Date de réalisation juridique", value: "realisation" },
  { libelle: "Date d'effet comptable", value: "effet" },
];

const OngletListOperations = (props) => {
  const { setFiltres, loadSourceUser, operations, vueEntreprise, setAllSorts } =  props;

  // Une seule fois : on efface les filtres, on load les sources
  useEffect(() => {
    setFiltres(null);
    loadSourceUser();
  }, [setFiltres, loadSourceUser]);

  // Modification des champs tri en fonction de vueEntreprise
  useEffect(() => {
    champsTris = champsTris.filter((c) => !c.value.includes("entreprise"));
    if (vueEntreprise) {
      champsTris.push({ libelle: "Entreprise cédante", value: "entreprisec" });
      champsTris.push({
        libelle: "Entreprise bénéficiaire",
        value: "entrepriseb",
      });
    }
    setAllSorts(champsTris);
  }, [vueEntreprise, setAllSorts]);

  if (operations) {
    return (
      <div className="block-content">
        <ListeHeader type="historique"/>
        <ListeOperationsRecherche action={true}/>
      </div>
    );
  }
  return <></>;
};

export default OngletListOperations;
