import * as types from "./constantes";
import { getSireneData } from "redux/api";

export const valideSiren = siren => (dispatch, getState) => {
  if (!getState().storeSirens.sirensValides[siren]) {
    return getSireneData(siren).then(response => {
      dispatch({ type: types.GET_SIREN, siren, data: response });
    });
  }
  return null;
};
export const getInfoUnite = (siren, annee) => (dispatch, getState) => {
  const periodes = getState().storeSirens.sirensValides[siren];
  if (periodes) {
    let periode = periodes[0];
    if (annee) {
      periodes.some(function(p) {
        periode = p;
        return p.dateDebut < annee.toString();
      });
    }
    return {
      siren,
      raisonSociale: periode.denominationUniteLegale,
      ape: periode.activitePrincipaleUniteLegale
    };
  }
  return null;
};
