import { connect } from "react-redux";
import InfoFiltres from "./InfoFiltres";
import { removeFiltre, removeSort } from "redux/actions";

const mapStateToProps = state => {
  return {
    filtres: state.storeFiltre.filtres,
    allFiltres: state.storeFiltre.allFiltres,
    sort: state.storeFiltre.sort,
    colonnes: state.storeFiltre.allSort.colonnes,
    sens: state.storeFiltre.allSort.sens
  };
};

export default connect(
  mapStateToProps,
  {
    removeFiltre,
    removeSort
  }
)(InfoFiltres);
