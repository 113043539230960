import { connect } from "react-redux";
import Rejet from "./Rejet";
import { loadTypesOp, valideSiren, getInfoUnite } from "redux/actions";

const mapStateToProps = state => {
  return {
    typesOp: state.storeTypesOp.typesOp,
    sirensValides: state.storeSirens.sirensValides
  };
};

export default connect(mapStateToProps, {
  loadTypesOp,
  valideSiren,
  getInfoUnite
})(Rejet);
