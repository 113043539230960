import React, { useState } from "react";
import { InputAdornment, TextField, Tooltip } from "@mui/material";
import { NumericFormat } from 'react-number-format';

const isValide = (value) => {
  return (
    !value || Number.isInteger(value) || !Number.isNaN(Number.parseInt(value))
  );
};

const InputMontant = (props) => {
  const {
    value,
    isUpdatable,
    onSubmit,
    modification,
    label,
    fullWidth,
    helperText,
    required,
    classes,
    libelle,
    tooltip
  } = props;

  const [error, setError] = useState(!isValide(value));

  const handleKeyPress = (e) => {
    if (e.key === "." || (isUpdatable && !isUpdatable())) {
      e.preventDefault();
    }
  };

  const handleChangeValue = (valueParam) => {
    setError(!isValide(valueParam.floatValue));
    return onSubmit(valueParam.floatValue);
  };

  if (modification) {
  return (
      <NumericFormat
      InputProps={{
        endAdornment: <InputAdornment position="end">K€</InputAdornment>
      }}
        value={value !== null ? value : ""}
 
        displayType={modification ? "input" : "text"}
        thousandSeparator={" "}
        customInput={TextField}
        renderText={(v) => <span>{v}</span>}
        onValueChange={handleChangeValue}
        label={label}
        fullWidth={fullWidth}
        helperText={error ? "Le montant doit être un entier" : helperText}
        required={required}
        InputLabelProps={{
          shrink: true,
          classes: classes,
        }}
        inputProps={{
          "aria-label": libelle,
          size: "8",
          onKeyPress: handleKeyPress,
        }}
        
        error={error}
        margin="none"
      />
  ) 
  } else {
    return (
      <Tooltip title={tooltip} PopperProps={{ className: "infoBulle" }}>
        <span>
          {label}{value !== null ? value : ""}{" K€"}
        </span>
      </Tooltip>
    );
  }
};

export default InputMontant;
