import React, { useState } from "react";
import Button from "@mui/material/Button";

import Field from "../Field";
import { format, unformat } from "components/utils/Identifiant";

const textError = "Siren invalide : non présent dans Sirene";
const label = "Siren";

// J'ai supprimé KeyboardEventHandler qui ne marchait pas, Loïc me conseille d'avoir 5 formulaires et dedans des boutons de type submit

const SirenField = (props) => {
  const {
    siren,
    onSubmit,
    valideSiren,
    sirensValides,
    sirensInvalides,
    handleChange,
    button,
    ...inputProps
  } = props;

  const [sirenState, setSirenState] = useState(siren);

  const onKeyPress = (e) => {
    if (isNaN(e.key) || unformat(e.target.value).length === 9) {
      e.preventDefault();
    }
  };

  const onSubmitEvent = () => {
    onSubmit(sirenState);
  };

  const onChange = (e) => {
    const sirenChange = unformat(e.target.value);
    setSirenState(sirenChange);
    if (!isNaN(sirenChange) && sirenChange.length === 9) {
      valideSiren(sirenChange);
    }
    handleChange?.(sirenChange);
  };

  const valide = Object.keys(sirensValides).includes(sirenState);
  const error =
    sirenState && sirenState.length === 9 && sirensInvalides.includes(sirenState);

  const buttonActive = button !== "false";

  return (
    <>
      <Field
        required
        label={label}
        value={format(sirenState)}
        onChange={onChange}
        onKeyPress={onKeyPress}
        {...inputProps}
        valide={valide}
        error={Boolean(error)}
        helperText={Boolean(error) ? textError : ""}
        autoFocus
      />
      {buttonActive && (
        <Button
          variant="contained"
          color="primary"
          disabled={Boolean(!valide)}
          style={{ display: "block", margin: "10px" }}
          onClick={onSubmitEvent}
        >
          Suivant
        </Button>
      )}
    </>
  );
};

export default SirenField;
