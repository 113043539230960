import React, { useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./anneesUnite.scss";

const AnneesUnite = (props) => {
  const { loadAnnees, operations, annees, idUnite, vueEntreprise, annee } =
    props;

  const navigate = useNavigate();

  // On charge une fois les données annexes
  useEffect(() => {
    loadAnnees();
  }, [loadAnnees]);

  const handleClick = (lien) => {
    navigate(lien);
  };

  const liens = {};
  annees?.forEach((a) => (liens[a] = { nb: 0 }));
  operations?.forEach((o) => {
      if (liens[o.anneeCampagne]) {
        liens[o.anneeCampagne] = {
          nb: liens[o.anneeCampagne].nb + 1,
          enveloppe: o.enveloppe.publicId,
        };
      }
  });

  return (
    <Tabs
      value={annee}
      indicatorColor="primary"
      textColor="primary"
      className="anneesUnite"
    >
      {Object.keys(liens).map((anneeMap) => {
        const libelle = `${anneeMap} (${liens[anneeMap].nb})`;
        const isLien = liens[anneeMap].nb > 0;
        const vue = vueEntreprise ? "entreprise" : "ul";
        return (
          <Tab
            key={anneeMap}
            className={isLien ? "lien" : ""}
            label={libelle}
            value={anneeMap}
            onClick={() =>
              isLien
                ? handleClick(
                    `/${anneeMap}/${liens[anneeMap].enveloppe}/${idUnite}?vue=${vue}`
                  )
                : null
            }
          />
        );
      })}
    </Tabs>
  );
};

export default AnneesUnite;
