import * as types from "./constantes";
import { getSources } from "redux/api";

export const loadSources = () => (dispatch, getState) => {
  if (getState().storeSources.sources.length === 0) {
    dispatch({ type: types.SOURCES_LOADING });
    return getSources().then(response => {
      dispatch({ type: types.SOURCES_LOADED, payload: response });
    });
  }
  return null;
};
