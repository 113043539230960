import { connect } from "react-redux";
import OngletListUnites from "./OngletListUnites";
import { loadEsaneData, resetEsaneData } from "redux/actions";

const mapStateToProps = state => {
  return {
    datas: state.storeUnites.datas,
    operations: state.storeOperations.operations,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  { loadEsaneData, resetEsaneData }
)(OngletListUnites);
