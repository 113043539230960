import { connect } from "react-redux";
import ListeEnveloppesRecherche from "./ListeEnveloppesRecherche";

const mapStateToProps = state => {
  return {
    enveloppes: state.storeEnveloppes.enveloppes,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {}
)(ListeEnveloppesRecherche);
