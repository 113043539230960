import { connect } from "react-redux";
import Tris from "./Tris";
import { addSort, setSorts } from "redux/actions";

const mapStateToProps = state => {
  return {
    colonnes: state.storeFiltre.allSort.colonnes,
    sens: state.storeFiltre.allSort.sens,
    sort: state.storeFiltre.sort
  };
};

export default connect(
  mapStateToProps,
  {
    addSort,
    setSorts
  }
)(Tris);
