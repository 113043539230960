import { connect } from "react-redux";
import CSVExport from "./CSVExport";
import {
  exportOperations,
  exportHistoriqueOperations,
  exportEnveloppes,
  exportUnites,
  exportFiches
} from "redux/actions";

const mapStateToProps = state => {
  return {
    operationsExport: state.storeOperations.operationsExport,
    enveloppesExport: state.storeEnveloppes.enveloppesExport,
    unitesExport: state.storeUnites.unitesExport,
    fichesExport: state.storeFiches.fichesExport,
    exportingOperations: state.storeOperations.exporting,
    exportingEnveloppes: state.storeEnveloppes.exporting,
    exportingUnites: state.storeUnites.exporting,
    exportingFiches: state.storeFiches.exporting,
    filtres: state.storeFiltre.filtres,
    sort: state.storeFiltre.sort,
    vueEntreprise: state.storeVueEntreprise.vueEntreprise
  };
};

export default connect(
  mapStateToProps,
  {
    exportOperations,
    exportHistoriqueOperations,
    exportEnveloppes,
    exportUnites,
    exportFiches
  }
)(CSVExport);
