import { connect } from "react-redux";
import Filtre from "./Filtre";
import { addFiltre, removeFiltre, setFiltres } from "redux/actions";

const mapStateToProps = state => {
  return {
    filtres: state.storeFiltre.filtres
  };
};

export default connect(
  mapStateToProps,
  {
    addFiltre,
    removeFiltre,
    setFiltres
  }
)(Filtre);
