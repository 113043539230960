import { connect } from "react-redux";
import ListeOperationsRecherche from "./ListeOperationsRecherche";

const mapStateToProps = state => {
  return {
    operations: state.storeOperations.operations
  };
};

export default connect(
  mapStateToProps,
  {}
)(ListeOperationsRecherche);
