import React, { useState } from "react";

import { Input, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import "./inputFiltre.scss";

const InputFiltre = (props) => {

  const { param, addFiltre, exemple } = props;

  const [searchState, setSearchState] = useState(null);

  const handleFiltreChange = (e) => {
    setSearchState(e.target.value);
  }

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      validInput();
    }
  };

  const validInput = () => {
    let value = searchState;
    if (param === "mot" || param === 'groupe') {
      value = value.replace(/\s/g, "");
    }
    addFiltre({ param, value });
  }

    return (
      <div className="contenuInputFiltre">
        <Input
          key="input"
          onChange={handleFiltreChange}
          onKeyPress={onKeyPress}
          placeholder={exemple && `ex : ${exemple} `}
          margin="none"
          type="search"
        />
        <IconButton
          aria-label="Rechercher"
          size="small"
          onClick={validInput}
        >
          <SearchIcon />
        </IconButton>
      </div>
    );
}

export default InputFiltre;
