import * as types from "redux/actions/constantes";

const initial = {
  sirensValides: {},
  sirensInvalides: []
};

const sirens = (state = initial, action = { type: "" }) => {
  if (action.type === types.GET_SIREN) {
    const data = action.data;
    if (!data.siren) {
      let sirensInvalides = structuredClone(state.sirensInvalides);
      sirensInvalides.push(action.siren);
      return { ...state, sirensInvalides };
    }
    let sirensValides = structuredClone(state.sirensValides);
    data.periodesUniteLegale.sort((a, b) => {
      if (a.dateDebut > b.dateDebut) {
          return -1;
      }
      if (b.dateDebut > a.dateDebut) {
          return 1;
      }
      return 0;
    });
    data.periodesUniteLegale.forEach(element => {
      if ((!element.denominationUniteLegale) && (element.nomUniteLegale)) {
        element.denominationUniteLegale = (element.nomUniteLegale +  " " + data.prenomUsuelUniteLegale).trim();
      }
    });
    sirensValides[data.siren] = data.periodesUniteLegale;

    return { ...state, sirensValides };
  }
  return state;
};
export default sirens