import { connect } from "react-redux";
import InfoCommentaire from "./InfoCommentaire";

const mapStateToProps = state => {
  return {
    types: state.storeCommentaires.types,
    originesInfo: state.storeCommentaires.originesInfo
  };
};

export default connect(
  mapStateToProps,
  {}
)(InfoCommentaire);
