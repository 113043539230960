import { connect } from "react-redux";
import SelectionExpress from "./SelectionExpress";
import { selectionExpress, unselect } from "redux/actions";

const mapStateToProps = state => {
  return {
    selected: state.storeSelection.selected,
    loading: state.storeSelection.loading,
    id: state.storeSelection.id
  };
};

export default connect(
  mapStateToProps,
  {
    selectionExpress,
    unselect
  }
)(SelectionExpress);
