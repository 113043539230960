import React from "react";

import InfoFiltres from "./InfoFiltres";
import PageSize from "./PageSize";
import NumPage from "./NumPage";
import CSVExport from "components/Listes/CSVExport";

const ListeHeader = (props) => {
  const { totalOp, type, totalEnv, totalUnite, totalRejet, totalFiche } =
    props;

  let total = totalOp;
  switch (type) {
    case "enveloppe":
      total = totalEnv;
      break;
    case "unite":
      total = totalUnite;
      break;
    case "rejet":
      total = totalRejet;
      break;
    case "fiche":
      total = totalFiche;
      break;
    default:
      break;
  }
  return (
    <>
      <InfoFiltres />
      <div>
        <PageSize total={total} />
        <CSVExport type={type} />
        <NumPage total={total} />
      </div>
    </>
  );
};

export default ListeHeader;
