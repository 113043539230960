const variable = {
  annees: "/annees",
  typeOp: "/typeOperations",
  sources: "/sources",
  source: "/source",
  fiche: "/fiche",
  fiches: {
    search: "/fiches/search"
  },
  operations: {
    search: "/operations/search",
    operations: "/operations",
    operation: "/operation",
    updatable: "/operation/updatable",
    save: "/operation",
    delete: "/operation/"
  },
  unites: {
    unite: "/unite/",
    unites: "/unites/",
    search: "/unites/search"
  },
  enveloppes: { enveloppe: "/enveloppe/", search: "/enveloppes/search" },
  rejets: {
    search: "/rejets/search",
    motifs: "/rejets/motifs",
    expertise: "/rejet/expertise",
    correction: "/rejet/correction"
  },
  entreprise: "/entreprise",
  commentaires: {
    commentaire: "/commentaire",
    types: "/commentaires/types",
    origines: "/commentaires/origines",
    operation: "/commentaires/operation",
    enveloppe: "/commentaires/enveloppe",
    unite: "/commentaires/unite"
  }
};

export default variable
export * from "./api-request";
export * from "./api-utils";
export * from "./api-esane-request";
