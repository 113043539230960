import { connect } from "react-redux";
import Ariane from "./Ariane";

const mapStateToProps = state => {
  return {
    ariane: state.storeAriane.ariane
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ariane);
